
jQuery(document).ready(function() {

  // Org search results - collapse search parameters
  (function() {
    // TODO: replace with real localization using cgTranslations.getTranslations()
    var langVersion = jQuery('body').attr('data-lang-version');
    var hasResultsWrapper = jQuery('#org-search-results-wrapper').length > 0;
    if (hasResultsWrapper && langVersion.indexOf('en-US') === 0) {
      jQuery('#cgsearchform .form-wrapper').toggle();
      jQuery('#cgsearchform').before('<div class="row" id="show-search-query-row"><div class="content-wrapper small-12 medium-11 large-11 small-centered columns centered"><button class="button primary" id="show-search-query" style="margin-bottom: 0">Search Again</button></div></div>');
      jQuery('#show-search-query').on('click', function() {
        jQuery('#cgsearchform .form-wrapper').slideDown();
        jQuery('#show-search-query-row').slideUp();
      });
    }
  })();

  // Photo Gallery Portlet
  (function() {
    if (jQuery('.cg-modaal-image-gallery-wrapper').length > 0) {
      var firstImageLink = jQuery('.cg-modaal-image-gallery-wrapper a:first-child');
      var firstImageDesc = firstImageLink.attr('data-modaal-desc');
      firstImageLink.find('.image-gallery-image')
          .css({'background-image': 'url("' + firstImageLink.attr('href') + '")'});
      jQuery('.cg-modaal-image-gallery-wrapper').parent().find('.portlet-title, .intro').hide();
      if (firstImageDesc) {
        jQuery('.cg-modaal-image-gallery-wrapper h4').after('<p>' + firstImageDesc + '</p>');
      }
    }
  })();

  // Prevent double click on org search result select links from sending multiple network requests,
  // which could cause duplicate orgs. SUGGESTION-1212
  jQuery('#cgsearchresults').on('click', '.cglink.select-link', function(e) {
    jQuery('<span/>', {
      text: jQuery(e.target).text()
    }).insertAfter(jQuery(e.target));
    jQuery(e.target).remove();
  });

});
