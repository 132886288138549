/*! *************************
  Shifts management/creation
  Requires moment.js
****************************/
jQuery(function () {
  var $ = jQuery;

  /* SHIFT BUILDER functions */
  var Shifts = (function ($) {
    var t = {};
    if ($('input[name=ev_id]').length) {
      var deleteSingleSerial =
        'x_eg_event_id=' +
        $('form[name=eventForm]').find('input[name=ev_id]').val();
    } else {
      var inputs =
        '[name=x_pt_id], [name=x_event_id], [name=x_source_flag], [name=x_style_id], [name=x_language_code]';
      var deleteSingleSerial = $('form[name=sectionform]')
        .find(inputs)
        .serialize();
    }

    if (jQuery('body').attr('data-lang-version')) {
      window.cgTranslations.getTranslations().done(function () {
        t = window.CyberGrants.l10n.constants;
      });
    } else {
      // for internal (gm)
      t.I_SHFTS_CONFIRM_DELETE =
        'You are about to delete all shifts. ' +
        '\nDo you want to delete currently defined shifts?';
      t.I_SHFTS_CONFIRM_REMOVE =
        'You are about to remove shifts from this event. ' +
        '\nThis will delete your current data. ' +
        '\nPlease confirm you want to remove shifts.';
      t.I_SHFTS_DELETE_WARNING = 'You are about to delete a shift. ';
      t.I_SHFTS_DELETE_PERM_CONFIRM =
        'This takes affect immediately and is permanent.' + '\nAre you sure?';
      t.I_SHFTS_LIMIT_MESSAGE =
        'Events are limited to 100 shifts.' +
        '\nIf you need more shifts, we recommend creating separate events.';
      // t.REMAINMSG =   "  \n({input} more shifts can be created)";
      t.I_SHFTS_ROLLOVER_MSG =
        'A single shift cannot span multiple days.' +
        '\nAdjust your last shift to start on the next day before creating more shifts.';
      t.B_SHFTS_ENABLE = 'Enable Shifts';
      t.B_SHFTS_DISABLE = 'Disable Shifts';
      t.B_DELETE = 'Delete';
      t.B_CANCEL = 'Cancel';
      t.E_DELETE_FAILED =
        'An error occurred during deletion. Please refresh and try again.';
      t.I_SHFTS_DISABLE =
        'Add shifts to your event, or click the "Disable Shifts" button ' +
        'to create your event without shifts.';
      t.I_SHFTS_DISABLED_MAX_VOLUN =
        'Add shifts and their available spaces to your event, or click the ' +
        '"Disable Shifts" button to create your event without shifts.';
      t.I_SHFTS_SELECT_DATES =
        'Select start and end dates and times to enable shifts.';
      t.I_SHFTS_HAS_SIGNUP_NO_DISABLE =
        'Shifts cannot be deleted because there are signups.';
      t.I_SHFTS_HAS_SIGNUP_NO_DELETE =
        'This shift cannot be deleted because there are signups.';
      t.I_SHFTS_HAS_SIGNUPS = 'This shift has signups';
    }

    var tBody;
    var deleteShiftsBtn;
    var enableShiftsBtn;
    var maxEl;
    var maxIsRequired;
    var fields = {
      start: {last: '#x_shift_start_time', noRows: '#x_start_time'},
      end: {last: '#x_shift_end_time', noRows: '#x_start_time'}, // start for noRows is a kludge
      dateStart: {last: '#x_shift_date', noRows: '[name=x_start_date]'},
      dateEnd: {last: '#x_shift_date', noRows: '[name=x_end_date]'}, // not used, yet
    };

    var init = function () {
      tBody = $('.shifts__builder--table table tbody#shift-rows');
      deleteShiftsBtn = $('#x_delete_shifts_btn');
      enableShiftsBtn = $('#x_enable_shift');
      maxEl = $('[data-column-name=max_num_volunteers] input');
      maxIsRequired =
        maxEl.attr('aria-required') ||
        $('[data-column-name=max_num_volunteers] .label--required').length > 0;
      _toggleSchedulingOptions();
      _toggleFrequencyOptions();

      $('body')
        .on('change', '[name=x_recurring_flag]', function (e) {
          _toggleSchedulingOptions(e.target.value);
        })
        .on('change', '#x_frequency, #x_schedule_ordinal', function () {
          _toggleFrequencyOptions();
        })
        .on(
          'click',
          '#x_delete_shifts_btn:not(.has-shifts):not([readonly])',
          function () {
            if (_confirmAction(t.I_SHFTS_CONFIRM_DELETE)) {
              var numRows = _getNumRows();
              _deleteAllRows(_deleteAllSuccess); // also calls _toggleDeleteShiftButton()
            }
          },
        )
        .on('click', '#x_enable_shift:not(.disabled)', function () {
          if ($(this).hasClass('alert')) {
            // when 'alert' class present, there are rows in table
            // result of delete can be: action failed, or action succeeded
            if (_confirmAction(t.I_SHFTS_CONFIRM_REMOVE)) {
              _deleteAllRows(
                function () {
                  _deleteAllSuccess();
                  setTimeout(function () {
                    // fix for IE issue (latency???)
                    _toggleShiftBuilder();
                  }, 20);
                },
                function (err) {
                  _deleteAllFailure(err);
                },
              );
            } else {
              return; // abort. don't collapse/hide the shifts table
            }
          } else {
            _toggleShiftBuilder();
          }
        })
        .on('click', '#x_enable_shift.disabled:not(.has-shifts)', function (e) {
          e.preventDefault();
          alert(t.I_SHFTS_SELECT_DATES);
        })
        .on(
          'click',
          '#x_enable_shift.has-shifts, #x_delete_shifts_btn.has-shifts',
          function (e) {
            e.preventDefault();
            alert(t.I_SHFTS_HAS_SIGNUP_NO_DISABLE);
          },
        )
        .on(
          'change',
          '[name=x_start_date], [name=x_end_date], #x_start_time, #x_end_time',
          function () {
            _toggleEnableButton($('[name=x_recurring_flag]').val() === 'N');
            window.formChanged = true;
          },
        )
        .on('change', tBody, function () {
          window.formChanged = true;
        })
        .on('click', '#x_add_shift_btn', function () {
          _addRows();
        })
        .on('change', '[name=x_shift_slots]', function () {
          var fieldEl = $(this);
          var limit = fieldEl.attr('max').length;

          if (fieldEl.val().length > limit) {
            fieldEl.val(fieldEl.val().substr(0, limit));
          }
          _changeMaxNbVolunteers();
        })
        .on(
          'click',
          '#primaryAction:not(.disabled), [name=x_action]:not(.disabled)',
          function () {
            $('form[name=sectionform]')
              .find('tr#shifts-model-row :input')
              .attr('disabled', true);
            maxEl.attr('disabled', false);
            $('form[name=sectionform]').submit();
          },
        )
        .on(
          'click',
          '#primaryAction.disabled, [name=x_action].disabled',
          function (e) {
            e.preventDefault();
            alert(
              maxIsRequired ? t.I_SHFTS_DISABLED_MAX_VOLUN : t.I_SHFTS_DISABLE,
            );
          },
        )
        .on('click', '#cghome', function (e) {
          e.preventDefault();
          check_unsaved($(e.target).attr('href'), document.location);
        })
        .on('click', '.js-shifts__table--delete-row', function (e) {
          e.preventDefault();
          var current = $(e.currentTarget);
          if (current.hasClass('has-shifts')) {
            alert(t.I_SHFTS_HAS_SIGNUP_NO_DELETE);
            return;
          }
          var options = {
            type: 'confirm',
            custom_class: 'js-deleteShiftModal',
            confirm_button_text: t.B_DELETE,
            confirm_cancel_button_text: t.B_CANCEL,
            confirm_title: t.I_SHFTS_DELETE_WARNING,
            confirm_content: '<p>' + t.I_SHFTS_DELETE_PERM_CONFIRM + '</p>',
            confirm_callback: function () {
              if (
                $(this.$elem)
                  .closest('tr')
                  .find('input[name=x_eg_event_shift_id]')
                  .val().length
              ) {
                _deleteSingleShiftAjax(this.$elem);
              } else {
                _deleteSingleShift(this.$elem);
              }
            },
          };

          if (!current.data('modaal-scope')) {
            current.modaal(options);
            setTimeout(function () {
              current.trigger('click');
            }, 20);
          } else {
            current.modaal(options);
          }
        });
    };

    (function initEditEventWarning() {
      let t = null;

      window.cgTranslations.getTranslations().done(function () {
        t = window.CyberGrants.l10n.constants;
      });

      // The warning banner only appears if something changes and if the user is only on the edit screen
      const isOnEditScreen =
        window.location.search.indexOf('x_action=Edit+Opportunity') > -1;
      const isInInternal =
        window.location.pathname.indexOf('view_ega_event.eventaccess') > -1 ||
        window.location.pathname.indexOf('ega_event.eventinfo') > -1;
      const warningAreaMessageZone = jQuery('#message-zone');
      const numEnrollees = warningAreaMessageZone.data('num-volunteers');
      const initialValues = generateValueObject();

      function generateValueObject() {
        return {
          x_time_zone_id: jQuery('[name=x_time_zone_id]').val(),
          x_start_date: jQuery('[name=x_start_date]').val(),
          x_start_time: jQuery('[name=x_start_time]').val(),
          x_end_date: jQuery('[name=x_end_date]').val(),
          x_end_time: jQuery('[name=x_end_time]').val(),
          x_shift_date: [
            ...document.querySelectorAll('#shift-rows [name=x_shift_date]'),
          ].map((field) => field.value),
          x_shift_start_time: [
            ...document.querySelectorAll(
              '#shift-rows [name=x_shift_start_time]',
            ),
          ].map((field) => field.value),
          x_shift_end_time: [
            ...document.querySelectorAll('#shift-rows [name=x_shift_end_time]'),
          ].map((field) => field.value),
          x_shift_slots: [
            ...document.querySelectorAll('#shift-rows [name=x_shift_slots]'),
          ].map((field) => field.value),
        };
      }

      function updateMessageZone(numEnrollees = 0) {
        const messageEvent = new CustomEvent('updateMessageZoneText', {
          detail: [
            numEnrollees
              ? t?.I_WARNING_EVENT_CHANGES?.replace('{Input}', numEnrollees)
              : '',
          ],
        });
        document.dispatchEvent(messageEvent);

        const typeEvent = new CustomEvent('updateMessageZoneType', {
          detail: numEnrollees ? 'warning' : '',
        });
        document.dispatchEvent(typeEvent);

        const titleEvent = new CustomEvent('updateMessageZoneTitle', {
          detail: numEnrollees ? t.I_WARNING : '',
        });
        document.dispatchEvent(titleEvent);

        const iconEvent = new CustomEvent('updateMessageZoneIcon', {
          detail: numEnrollees ? 'ri-error-warning-line' : '',
        });
        document.dispatchEvent(iconEvent);

        const suppressFocus = new CustomEvent(
          'updateMessageZoneFocusSuppression',
          {
            detail: true,
          },
        );
        document.dispatchEvent(suppressFocus);
      }

      function currentValuesMatchInitialValues(event) {
        const currentValues = generateValueObject();

        let result = true;

        // This only works because the data structure and order of things are
        // are always the same.  JSON.stringify is the easy way out.
        if (JSON.stringify(initialValues) !== JSON.stringify(currentValues)) {
          result = false;
        }

        if (result) {
          // current values match initial values
          warningAreaMessageZone.hide();
        } else {
          // something changed from initial values
          // we need to manually hide the MessageZone b/c it doesn't know how.
          if (numEnrollees > 0) {
            warningAreaMessageZone.show();

            updateMessageZone(numEnrollees);
          }
        }
      }
      if (isOnEditScreen || isInInternal) {
        $('body')
          .on(
            'click',
            '#x_enable_shift, #x_delete_shifts_btn, .js-shifts__table--delete-row.button--secondary--small, .js-deleteShiftModal button',
            currentValuesMatchInitialValues,
          )
          .on(
            'change', // for <select>
            '[name=x_time_zone_id], [name=x_start_time], [name=x_end_time], #shift-rows [name=x_shift_start_time], #shift-rows [name=x_shift_end_time]',
            currentValuesMatchInitialValues,
          )
          .on(
            'blur', // for text inputs
            '[name=x_start_date], [name=x_end_date], #shift-rows [name=x_shift_date], #shift-rows [name=x_shift_slots]',
            currentValuesMatchInitialValues,
          );
      }
    })(jQuery);

    var initNoShifts = function () {
      maxEl = $('[data-column-name=max_num_volunteers] input');
      maxIsRequired =
        maxEl.attr('aria-required') ||
        $('[data-column-name=max_num_volunteers] .label--required').length > 0;
      _toggleSchedulingOptions(null, true);
      $('body')
        .on('change', '[name=x_recurring_flag]', function (e) {
          _toggleSchedulingOptions(e.target.value, true);
        })
        .on('change', '#x_frequency, #x_schedule_ordinal', function () {
          _toggleFrequencyOptions();
        });
    };

    var initInternal = function () {
      var shifts = $('#cgShifts');
      maxEl = $('[data-column-name=max_num_volunteers] input');
      maxIsRequired =
        maxEl.attr('aria-required') ||
        $('[data-column-name=max_num_volunteers] .label--required').length > 0;
      // var wrapper = $('#cgEvtRec');

      // wrapper.find('.form-element-title, .sub-section-title').wrap('<label class="label"></label>');
      shifts
        .addClass('form__section')
        .find('.shifts__builder')
        .addClass('form__section')
        .end()
        .find('select')
        .addClass('select--xsmall')
        .end()
        .find('input[type=text], input[type=number]')
        .removeClass('input-text--small')
        .addClass('input-text--xsmall')
        .end()
        .find('.sub-section-title')
        .wrap('<label class="label"></label>')
        .end()
        .find('.js-shifts__table--delete-row')
        .removeClass('button--secondary--small');
    };

    var _toggleShiftBuilder = function (turnOn) {
      var builder = $('.shifts__builder');
      var numRows = _getNumRows();

      if (!builder.is(':visible') || turnOn) {
        builder.slideDown(400, function () {
          _toggleEnableButton(true, t.B_SHFTS_DISABLE);
          _toggleSaveButton();
        });
        _defaultTime($('#x_shift_duration'), '01:00', true); // force it because it has a selected value
        $('#x_add_shift_btn').attr('disabled', numRows === 100);
        _toggleDeleteShiftButton();
        builder.find('tbody#not-shifts').toggle(numRows === 0);
        if (maxIsRequired) {
          maxEl.attr('disabled', true);
          builder
            .find('thead th:not(:empty):not(:has(span))')
            .prepend('<span>*</span>'); // all table headers with text
        } else {
          builder
            .find('thead th:not(:empty):lt(2):not(:has(span))')
            .prepend('<span>*</span>'); // just the date and time headers
        }
        $('th > span').css({fontWeight: 300});
      } else {
        builder.slideUp(400, function () {
          _toggleEnableButton(true, t.B_SHFTS_ENABLE);
          _toggleSaveButton();
        });
        // Just unlock the field. Don't mess with value per 2018-05-24 mtg
        maxEl.attr('disabled', false); //.val(maxEl.attr('value') || null)
      }
    };

    var _toggleSchedulingOptions = function (flag, shiftsDisabled) {
      var recurEl = $('#recurSetting'); // a parent
      var formOnTheEl = $('#formscheduleOnThe'); // child of recurSetting
      var dtTimeEl = $('#recurDateTime'); // a parent
      var rTimeEl = $('#recurTime'); // child of recurDateTime
      var timeZoneEl = $('#x_time_zone_id'); // standard product time zone event
      var timeZoneLabel = $('#label-CGx_time_zone_id'); // standard product time zone label
      var timeZoneFormRow = $('#formRowx_time_zone_id');
      var shiftEl = $('.shifts__builder');
      var numRows = shiftsDisabled ? 0 : _getNumRows();
      var hasSignups =
        !shiftsDisabled && tBody.find('[data-signup-exist]').length > 0;
      var recurringSelect = $('[name=x_recurring_flag]');

      if (!flag) {
        flag = recurringSelect.val(); // for 'back' from preview page
      }

      if (shiftsDisabled) {
        $('#cgShifts').hide(); // START by hiding the whole div
      }
      // always do this when page loads
      $('tr#shifts-model-row :input').attr('disabled', true);

      switch (flag) {
        case 'N': // ONE TIME event
          recurEl.hide();
          dtTimeEl.show();
          rTimeEl.show();
          timeZoneEl.show();
          timeZoneLabel.show();
          timeZoneFormRow.show();
          _defaultTime(rTimeEl.find('#x_start_time'), '08:00');
          _defaultTime(rTimeEl.find('#x_end_time'), '17:00');
          recurringSelect.css('margin-bottom', '1em');
          if (shiftsDisabled) {
            $('#cgShifts').hide();
          } else {
            shiftEl.toggle(numRows > 0);
            $('[name=x_recurring_flag] option:not(:selected)').attr(
              'disabled',
              hasSignups,
            );
            $('tr#no-shifts-defined').toggleClass('hidden', numRows > 0);
            $('tr[data-signup-exist] .js-shifts__table--delete-row')
              .toggleClass('has-shifts disabled', hasSignups)
              .attr('aria-label', t.I_SHFTS_HAS_SIGNUP_NO_DELETE)
              .attr('disabled', true);
            if (shiftEl.is(':visible')) {
              if (maxIsRequired) {
                shiftEl
                  .find('thead th:not(:empty):not(:has(span))')
                  .prepend('<span>*</span>'); // all table headers with text
              } else {
                shiftEl
                  .find('thead th:not(:empty):lt(2):not(:has(span))')
                  .prepend('<span>*</span>'); // just the date and time headers
              }
              _changeMaxNbVolunteers();
            }
            _toggleEnableButton(true);
            _toggleDeleteShiftButton();
            _toggleSaveButton();
          }
          break;
        case 'O': // ONGOING event
          if (!shiftsDisabled && _cancelRecurChange()) return false;
          recurEl.hide();
          dtTimeEl.show();
          rTimeEl.hide();
          timeZoneEl.hide();
          timeZoneLabel.hide();
          timeZoneFormRow.hide();
          _toggleEnableButton(false);
          shiftEl.hide();
          maxEl.attr('disabled', false);
          recurringSelect.css('margin-bottom', '1em');
          break;
        case 'Y': // RECURRING event
          var freq = $('#x_frequency');
          if (!shiftsDisabled && _cancelRecurChange()) return false;
          recurEl.show();
          formOnTheEl.toggle(freq.val() == 12 || freq.val() == 52); // show if monthly is selected
          if (freq.val() != 12) {
            $('#x_schedule_ordinal')
              .find('option[selected]')
              .attr('selected', false);
            $('#x_schedule_ordinal').hide();
          }
          dtTimeEl.show();
          rTimeEl.show();
          timeZoneEl.show();
          timeZoneLabel.show();
          timeZoneFormRow.show();
          _defaultTime(rTimeEl.find('#x_start_time'), '08:00');
          _defaultTime(rTimeEl.find('#x_end_time'), '17:00');
          _toggleEnableButton(false);
          shiftEl.hide();
          maxEl.attr('disabled', false);
          recurringSelect.css('margin-bottom', '1em');
          break;
        default:
          if (!shiftsDisabled && _cancelRecurChange()) return false;
          recurEl.hide();
          dtTimeEl.hide();
          _toggleEnableButton(false);
          shiftEl.hide();
          timeZoneEl.hide();
          timeZoneLabel.hide();
          timeZoneFormRow.hide();
          maxEl.attr('disabled', false);
          recurringSelect.css('margin-bottom', 0);
      }
    };

    var _cancelRecurChange = function () {
      if (_getNumRows() === 0) {
        return false; // go ahead and change recur selection
      } else {
        if (_confirmAction(t.I_SHFTS_CONFIRM_REMOVE)) {
          _deleteAllRows(
            function () {
              _deleteAllSuccess();
              return false;
            },
            function (err) {
              $('select[name=x_recurring_flag').val('N');
              _deleteAllFailure(err);
              return true; // true: don't change the recur selection because delete failed
            },
          );
        } else {
          $('select[name=x_recurring_flag').val('N');
          return true; // don't change recur because user declined to delete shifts
        }
      }
      return false; // assume it's okay to change recur selection
    };

    var _defaultTime = function (el, defaultTime, force) {
      if (!el.val() || force) {
        el.val(defaultTime);
      }
    };

    var _toggleFrequencyOptions = function () {
      var freq = $('#x_frequency').val(); // '365' days, '52' weeks, '12' months

      var onTheEl = $('#scheduleOnThe');
      var formOnTheEl = $('#formscheduleOnThe');
      var schedOrdEl = $('#x_schedule_ordinal');
      var schedDayEl = $('#x_schedule_day');

      switch (freq) {
        case '52':
          onTheEl.show();
          formOnTheEl.show();
          schedOrdEl.hide().find('option[selected]').attr('selected', false);
          schedDayEl.show();
          break;
        case '12':
          onTheEl.show();
          formOnTheEl.show();
          schedOrdEl.show();
          schedOrdEl.val() && schedOrdEl.val() <= 4
            ? schedDayEl.show()
            : schedDayEl
                .hide()
                .find('option[selected]')
                .attr('selected', false);
          break;
        default: // formerly 365 (days) & 8760 (hours)
          onTheEl.hide();
          formOnTheEl.hide();
          schedOrdEl.hide().find('option[selected]').attr('selected', false);
          schedDayEl.hide().find('option[selected]').attr('selected', false);
      }
    };

    var _inputsComplete = function () {
      var sched = $('#recurDateTime');
      return (
        sched.find('[name=x_start_date]').val().length > 0 &&
        sched.find('[name=x_end_date]').val().length > 0 &&
        sched.find('#x_start_time').val().length > 0 &&
        sched.find('#x_end_time').val().length > 0
      );
    };

    var _getNumRows = function () {
      return tBody.find('tr').length;
    };

    var _reindexShiftTableRows = function () {
      var builder = $('.shifts__builder');
      var noRowsEl = builder.find('tbody#not-shifts tr#no-shifts-defined');
      var numRows = _getNumRows();

      if (numRows) {
        $('#shift-rows')
          .children('tr')
          .each(function (idx) {
            // Getting delete button
            var deleteButton = $(this).find('.js-shifts__table--delete-row').attr('aria-label');

            // New aria label text for the delete button
            var appendedLabel = deleteButton.replace(/\d+$/, idx + 1);

            $(this).attr('id', 'row-' + (idx + 1));
            $(this)
              .find('.cg-shift-row-number')
              .html(idx + 1);
              $(this).find('.js-shifts__table--delete-row').attr('aria-label', appendedLabel);
          });
      }
      // not needed because add/delete and section toggle all handle this now?
      // $('#x_delete_shifts_btn').attr('readonly', numRows === 0);
      noRowsEl.toggleClass('hidden', numRows > 0);
      // builder.find('tbody#not-shifts tr#no-shifts-defined').toggleClass('hidden', numRows === 0);
    };

    var _incrementTime = function (start, duration) {
      return moment(start, 'HH:mm').add(duration, 'h').format('HH:mm');
    };

    var _getLast = function (type) {
      return _getNumRows() > 0
        ? tBody.find('tr:last ' + fields[type].last).val()
        : $(fields[type].noRows).val();
    };

    /* Returns TRUE if the end time will roll past midnight */
    var _rolloverCheck = function (mDuration) {
      var lastEnd = _getLast('end');
      var startTime = moment.duration(lastEnd, 'HH:mm').asHours();
      var endTime = moment
        .duration(_incrementTime(lastEnd, mDuration))
        .asHours();

      if (endTime < startTime || endTime === 0) {
        return true;
      }
      return false;
    };

    // make new rows as a clone of the model row
    var _addRows = function () {
      var rowModel = $('.shifts__builder--table table').find(
        'tr#shifts-model-row',
      );
      var count = _getNumRows();
      var hasSignups = tBody.find('[data-signup-exist]').length > 0;
      var startDate = $('[name=x_start_date]').val();
      var nShifts = parseInt($('#x_num_shifts').val(), 10);
      var mDuration = moment.duration($('#x_shift_duration').val()).asHours();

      if (count === 100 || count + nShifts > 100) {
        // var remainTxt = count < 100 ? t.REMAINMSG.replace('{input}', (100 - count)) : '';
        alert(t.I_SHFTS_LIMIT_MESSAGE);
        if (count === 100) {
          // only if no room for more shifts
          $('#x_add_shift_btn').attr('readonly', true);
        }
        return;
      }

      for (var i = 0; i < nShifts; i++) {
        if (_rolloverCheck(mDuration)) {
          alert(t.I_SHFTS_ROLLOVER_MSG);
          break; // stop all subsequent row creation
        }
        var thisRow = rowModel.clone();
        var rowNum = _getNumRows() + 1;

        // Getting delete button
        var deleteButton = thisRow.find('.js-shifts__table--delete-row').attr('aria-label');

        var appendedLabel = deleteButton + ` ${rowNum}`
        // whole-row
        thisRow
          .attr('id', 'row-' + rowNum)
          .removeClass('no-shifts-row')
          .attr('hidden', false)
          .attr('disabled', false);
        // Field values auto-calculate
        thisRow.find('#x_shift_date').val(_getLast('dateStart'));
        thisRow.find('#x_shift_start_time').val(_getLast('end'));
        thisRow
          .find('#x_shift_end_time')
          .val(_incrementTime(_getLast('end'), mDuration));
        thisRow.find('td:first').attr('id', rowNum).text(rowNum); // on number cell
        thisRow.find(':input').attr('disabled', false);
        thisRow.find('.js-shifts__table--delete-row').attr('aria-label', appendedLabel);
        
        thisRow.appendTo(tBody);
      }
      $('tr#no-shifts-defined').addClass('hidden');
      _toggleEnableButton(true);
      _toggleDeleteShiftButton();
      window.formChanged = true;
    };

    var _deleteSingleShiftAjax = function (thisTrashEl) {
      var shiftInput = thisTrashEl
        .closest('tr')
        .find('input[name=x_eg_event_shift_id]')
        .serialize();
      if ($('input[name=ev_id]').length) {
        var completeUrl =
          'ega_event.delete_shift?' + deleteSingleSerial + '&' + shiftInput;
      } else {
        var completeUrl =
          'eg_evt.delete_shift?' + deleteSingleSerial + '&' + shiftInput; //deleteSingleShiftUrl + "&x_eg_event_shift_id=" + shiftId;
      }

      if ($.active === 0) {
        // double-click prevention
        $.ajax({
          type: 'post',
          url: completeUrl,
          success: function (response) {
            var msg = response ? JSON.parse(response) : '';
            if (msg.errors) {
              alert(t.E_DELETE_FAILED + ' ' + msg.errors);
            } else {
              _deleteSingleShift(thisTrashEl);
            }
          },
          error: function () {
            alert(t.E_DELETE_FAILED);
          },
        });
      } else {
        console.log('$.ajax is already deleting this shift');
      }
    };

    var _deleteSingleShift = function (thisTrashEl) {
      thisTrashEl
        .closest('tr')
        .css({
          transition: 'background-color 0s ease-out',
          'background-color': 'rgba(240, 65, 36, 0.2)',
        })
        .children('td')
        .animate({
          'padding-top': 0,
          'padding-bottom': 0,
        })
        .wrapInner('<div />')
        .children()
        .slideUp(function () {
          jQuery(this).closest('tr').remove();
          _reindexShiftTableRows();
          _changeMaxNbVolunteers();
          _toggleDeleteShiftButton();
          _toggleEnableButton(true);
        });
    };

    var _confirmAction = function (msg) {
      if (confirm(msg)) {
        return true;
      }
      return false;
    };

    /* two kinds of delete:
        1) change of event type;
        2) Clear All Rows (to restart adding shifts)
    */
    var _deleteAllRows = function (onSuccess, onFail) {
      var noIds = $('input[name=x_eg_event_shift_id]')
        .get()
        .every(function (input) {
          return $(input).val() === '';
        });

      if (noIds) {
        // bypass ajax call
        if (typeof onSuccess === 'function') {
          onSuccess();
        } else {
          _deleteAllSuccess();
        }
        return true;
      }

      if ($('input[name=ev_id]').length) {
        var completeUrl = 'ega_event.delete_shift?' + deleteSingleSerial;
      } else {
        var completeUrl = 'eg_evt.delete_shift?' + deleteSingleSerial;
      }

      if ($.active === 0) {
        // double-click prevention
        $.post(completeUrl)
          .then(function (response) {
            var msg = response ? JSON.parse(response) : '';
            var filter = $.Deferred();

            if (msg.success) {
              filter.resolve();
            } else {
              filter.reject(msg.errors);
            }

            return filter.promise();
          })
          .done(function () {
            if (typeof onSuccess === 'function') {
              onSuccess();
            } else {
              _deleteAllSuccess();
            }
          })
          .fail(function (error) {
            if (typeof onFail === 'function') {
              onFail(error);
            } else {
              _deleteAllFailure(error);
            }
          });
      } else {
        console.log('$.ajax is already deleting all rows');
      }
    };

    var _deleteAllSuccess = function () {
      $('.shifts__builder--table')
        .find('tbody#shift-rows tr')
        .remove()
        .end() // back to table
        .find('tbody#not-shifts')
        .show()
        .end() // back to table
        .find('tr#no-shifts-defined')
        .removeClass('hidden');

      _toggleEnableButton(true);
      _toggleDeleteShiftButton();
      _changeMaxNbVolunteers();
    };

    var _deleteAllFailure = function (err) {
      alert(t.E_DELETE_FAILED + ' ' + err);
    };

    var _changeMaxNbVolunteers = function () {
      var shiftsVisible = $('.shifts__builder').is(':visible');

      if (maxIsRequired) {
        if (_getNumRows() > 0) {
          var totalSlots = 0;
          var valid = true;
          $(
            '.shifts__builder--table tbody#shift-rows input[name=x_shift_slots]',
          ).each(function () {
            var val = $(this).val();
            if (val) {
              // skip if empty (val="")
              if (parseInt(val, 10) === NaN) {
                // needed if this el goes back to type=text
                alert('Invalid entry for Shift Capacity: ', val);
                valid = false;
                return false;
              }
              totalSlots += parseInt(val, 10);
            }
          });
          if (valid) {
            maxEl
              .val(totalSlots || null) // no 0s allowed. replace with null
              .attr('disabled', true);
          }
        } else {
          // no rows, but max is required
          maxEl.attr('disabled', shiftsVisible).val(null);
        }
      } else {
        // max is not required
        maxEl.attr('disabled', false); //.val(maxEl.attr('value') || null)
      }
      // final check just when running THIS function: change 0 to null
      if (maxEl.val() === 0) maxEl.val(null);
      _toggleSaveButton();
    };

    var _toggleDeleteShiftButton = function () {
      var numRows = _getNumRows();
      var hasSignups = tBody && tBody.find('[data-signup-exist]').length > 0;

      if (numRows === 0) {
        // if no rows, this button is actually disabled
        deleteShiftsBtn
          .attr('disabled', true)
          .attr('readonly', false)
          .addClass('disabled')
          .removeClass('alert has-shifts');
      } else {
        // otherwise, making it readonly with CLASS 'disabled', so we can do alerts
        deleteShiftsBtn
          .toggleClass('has-shifts disabled', hasSignups)
          .attr('readonly', hasSignups)
          .attr('disabled', false)
          .addClass('alert');
      }
    };

    var _toggleEnableButton = function (show, txt) {
      /* The States of the Enable/Disable button:
         .show()
         .attr('readonly', !_inputsComplete() || hasSignups)
         .toggleClass('alert', numRows > 0)
         .toggleClass('disabled', !_inputsComplete() || hasSignups)
         .toggleClass('has-shifts', hasSignups);
        numRows > 0 ? enableBtnEl.val(t.B_SHFTS_DISABLE) : enableBtnEl.val(t.B_SHFTS_ENABLE);
      */
      if (jQuery('body').attr('data-lang-version')) {
        window.cgTranslations.getTranslations().done(function () {
          t = window.CyberGrants.l10n.constants;
          actuallyToggleIt();
        });
      }
      function actuallyToggleIt() {
        if (!show) {
          // test for existence of btn before hiding (for shiftsDisbabled situation)
          // (foreign language not yet supported, that is: SS-50661)
          if (typeof enableShiftsBtn !== 'undefined') {
            enableShiftsBtn.closest('.columns > ul, #cgButtonFooter').hide();
          }
          return;
        }
        enableShiftsBtn.closest('.columns > ul, #cgButtonFooter').show();
        var numRows = _getNumRows();
        var valid = _inputsComplete();
        var hasSignups = tBody && tBody.find('[data-signup-exist]').length > 0;

        enableShiftsBtn
          .attr('readonly', !valid || hasSignups)
          .toggleClass('alert', numRows > 0)
          .toggleClass('disabled', !valid || hasSignups)
          .toggleClass('has-shifts', hasSignups)
          .val(
            txt
              ? txt
              : $('.shifts__builder').is(':visible')
              ? t.B_SHFTS_DISABLE
              : t.B_SHFTS_ENABLE,
          );
      }
    };

    var _toggleSaveButton = function () {
      // If shifts is open, disable/enable save button depending on rows
      // and if any slots are empty while maxRequired is true
      var disable =
        $('.shifts__builder').is(':visible') &&
        (_getNumRows() === 0 ||
          (maxIsRequired &&
            $('[name=x_shift_slots]:not(:disabled)').filter(function () {
              return $(this).val() === '';
            }).length > 0));
      $('[name=x_action], #primaryAction')
        .toggleClass('disabled', disable)
        .attr('readonly', disable);
    };

    return {
      init: init,
      initNoShifts: initNoShifts,
      initInternal: initInternal,
    };
  })(jQuery);

  /* SIGNUP function(s) */
  var Signup = (function ($) {
    var t = {};
    if (jQuery('body').attr('data-lang-version')) {
      window.cgTranslations.getTranslations().done(function () {
        t = window.CyberGrants.l10n.constants;
      });
    } else {
      // for internal (gm)
      t.L_SELECT_A_SHIFT = 'Select a shift to sign up for.';
    }

    var init = function () {
      var shiftsSel = 'input[name=x_eg_event_shift_id]';
      var valid = $(shiftsSel + ':checked:not(:disabled)').length > 0;

      $('#primaryAction')
        .prop('readonly', !valid)
        .toggleClass('disabled', !valid);

      $('body')
        .on('change', shiftsSel, function () {
          valid = $(shiftsSel + ':checked:not(:disabled)').length > 0;
          $('#primaryAction')
            .prop('readonly', !valid)
            .toggleClass('disabled', !valid);
        })
        .on('click', '#primaryAction.disabled', function (e) {
          e.preventDefault();
          alert(t.L_SELECT_A_SHIFT);
        });
    };
    return {
      init: init,
    };
  })(jQuery);

  /* Kick off initializers */
  /* INITIALIZE WHAT IS NEEDED ONLY */

  if ($('#cgEvtRec').length) {
    // SHIFT BUILDER INITIALIZE
    // if ($('body').attr('lang') === 'en' || $('#cgMainContainer').length) {
    // TODO: remove language check when translations are done
    if ($('#cgMainContainer').length) {
      Shifts.initInternal();
    }
    Shifts.init();
    // } else {
    //   Shifts.initNoShifts();
    // }
  }
  // SIGNUP INITIALIZE
  if ($('.shifts__signup--table').length) {
    Signup.init();
  }

  // ROSTER INITIALIZE (stub)

  /* ROSTER stuff that should go into a var Roster = (function($)), like SHIFTS, soon */
  $('body')
    .on(
      'change',
      'form[name=rosterForm] div[data-base-url] select',
      function () {
        // change of FILTERS
        var parent = $(this).closest('.volunteer-roster__controls');
        var formEls = parent.find('select');
        var url =
          CyberGrants.constants.SQL_AGENT +
          parent.data('baseUrl') +
          '&' +
          formEls.serialize();
        // It's all in the url
        check_unsaved(url, document.location);
        // location.href=url;
      },
    )
    .on('click', '#cgreturntoevent', function (event) {
      event.preventDefault();
      check_unsaved($(event.target).attr('href'), document.location);
    });
});

/* STORED FOR POSSIBLE BUT UNLIKELY FUTURE USE
  Adjust date if time rolls over to next day
  Need duration of LAST row added, not of the row currently being created
  (could be different)
  If last end is < last start (or is 00:00), assume the date rolled over
  end = 0:30 start = 23:00 interval of 23:00 -> :30  should be 1.5
  get 0 - :30 asHours (.5) add to 23:00 - 24:00 asHours (1)
*/
/*var _dateAdjust = function() {
  var startField = $('[name=x_start_date]');
  var format = startField.data('dateFormat');
  var dtStart = _getLast('dateStart');
  var timeStart = _getLast('start');

  // if start and end are same, or there are no rows, just return start date
  if (startField.val() === $('[name=x_end_date]').val() || _getNumRows() === 0) {
    return startField.val();
  }
  var startTime = moment.duration(timeStart, 'HH:mm').asHours();
  var endTime = moment.duration(_getLast('end'), 'HH:mm').asHours();

  if (endTime < startTime || endTime === 0) {
    return moment(dtStart).add(1, 'd').format(format);
  } else {
    return dtStart;
  }
}
*/
