/*!
    Visibility.js by CyberGrants */

var fieldCache = {};  // instantiate Object to hold cache field values
var initialRenderStatus = {}; // Object to hold initialRenderComplete status for each sectionId
var changedFields = [];

// Initialize the initialRenderStatus for a sectionId
function initializeRenderStatus(sectionId) {
  if (sectionId && !(sectionId in initialRenderStatus)) {
    initialRenderStatus[sectionId] = false;
  }
}

// Check if the initial render is complete for a sectionId
function isInitialRenderComplete(sectionId) {
  return initialRenderStatus[sectionId];
}

// Set the initial render status for a sectionId
function setInitialRenderComplete(sectionId, status) {
  initialRenderStatus[sectionId] = status;
}

function findField(fieldId, selector, type) {
  // 'type' is selector type, either id: '#' or class: '.'
  var foundField;

  if (selector) {
    foundField = jQuery(type + selector);
  }
  if (!foundField.length > 0) {
    foundField = jQuery(type + 'CG' + fieldId);
  }
  return foundField;
}

// receives: custom field ID, optional selector
// returns field value (either from screen, or saved in database)
function getValue(fieldId, userType, key, selector) {
  // check for existence as a form element
  // var selectorString = '#' + (selector ? selector : 'CG' + fieldId);
  // var jqField = jQuery(selectorString);
  var jqField = findField(fieldId, selector, '#');

  if (jqField.length > 0 &&
    (jqField.is('input') || jqField.is('select') || jqField.is('textarea'))) {
      return jqField.val();

  } else {
    // possibly will address team specific handling as well
    // date was different; possibly impact datepicker handling
    // payment standard fields don't follow this convention (VERIFY)

    // check for class for checkbox/summable/pct/etc values
    // var multiInput = jQuery(selectorString.replace('#', '.'));
    var multiInput = findField(fieldId, selector, '.');
    var fieldKey = 'CG' + fieldId + '|'+ key; // cache with key for modals
    if (multiInput.length == 0) {
      // cannot locate field on page, so either it's a file type
      // (no corresponding input), or the field isn't on the page at all
      // check to see whether we have a cached response for this field
      if (fieldKey in fieldCache) {
        return fieldCache[fieldKey];
      } else {
        var json;

        // make AJAX request
        // consider whether it's possible to issue all parameters together
        // possibly implement a cache on top
        jQuery.ajax({
          url: 'field_view.get_field_value',
          async: false, // we need to wait for this response in order to proceed
          data: {
            x_custom_field_id: fieldId,
            x_ut: userType,
            x_key: key,
            x_ts:  new Date().getTime()
          },
          success: function(resp) {
            json = jQuery.parseJSON(resp);
          }
        });

        // check for multiple responses
        if (json.values) {
          fieldCache[fieldKey] = json.values.split(',');  // cache the system response
          return json.values.split(',');
        } else {
          fieldCache[fieldKey] = json.value;  // cache the system response
          return json.value;
        }
      }
    } else {
      var values = [];
      multiInput.each(function(index, item) {
        var jqItem = jQuery(item);
        var jqVal = jqItem.val();
        // get the form values for this multiple input item
        if (jqItem.prop('selected') || jqItem.prop('checked')
          || (jqItem.is('input') && jqItem.attr('type') !== 'checkbox')) {
          if (jqVal !== '' && jqVal !== null) values.push(jqVal);
        }
      });
      return values;
    }
  }
}

function clearValue(fieldId, value, selector) {
  // check for existence as a form element
  // var jqField = jQuery('#CG' + fieldId);
  // var selectorString = '#' + (selector ? selector : 'CG' + fieldId);
  // var jqField = jQuery(selectorString);
  var jqField = findField(fieldId, selector, '#');

  if (jqField.length > 0 && (
        jqField.is('input') ||
        jqField.is('select') ||
        jqField.is('textarea'))) {
    if (jqField.val() !== null && jqField.val() != value) {
      // for select options, if we're trying to set the value to blank, then deselect all options
      if (value === '' && jqField.is('select')) {
        for (i = 0; i < jqField[0].options.length; i++){
          jqField[0].options[i].selected = false;
        }
        if (jqField.attr('multiple') == null)
          jqField[0].selectedIndex = 0; // Redundant for IE
      } else {
        jqField.val(value);
      }
      // jqField.simulate('change'); // fire onchange in case of cascading dependencies
    }
  } else {
    // check for class for checkbox/summable/pct/etc values
    // selectorString = selectorString.replace('#CG', '.CG');
    // var multiInput = jQuery(selectorString);
    // var multiInput = jQuery('.CG' + fieldId);
    var multiInput = findField(fieldId, selector, '.');

    if (multiInput.length == 0) {
      // check for metric contents
      // multiInput = jQuery('#tblMetric' + fieldId + ' input[type="text"], #tblMetric' + fieldId + ' select, #tblMetric' + fieldId + ' textarea');
      multiInput = jQuery('#tblMetric' + fieldId).find('input[type="text"], select, textarea');
    }

    multiInput.each(function(index, item) {
      var jqItem = jQuery(item);
      var jqVal = jqItem.val();
      // get the form values for this multiple input item
      if ((jqVal !== null && jqVal != value) || jqItem.attr('type') === 'checkbox') {
        // for select options, if we're trying to set the value to blank, then deselect all options
        if (value === '' && jqItem.is('select')) {
          for (i = 0; i < jqItem[0].options.length; i++){
            jqItem[0].options[i].selected = false;
          }
          jqItem[0].selectedIndex = -1; // Redundant for IE
        } else {
          if (jqItem.attr('type') === 'checkbox')
            jqItem.prop('checked', false);
          else
            jqItem.val(value);
        }
      }
    });
  }
}

var initChangeHandlersOnSelector = function (selector, refreshAllFn) {
  // function.name doesn't work in IE11, so parse it out of function.toString
  var funcName = refreshAllFn.toString().match(/^function\s*([^\s(]+)/)[1];
  // depending on the field type, need to look for either a class or an ID
  var selectorString = jQuery('#' + selector).length > 0 ? '#' : '.';
  selectorString += selector;
  // If there was already a refreshAll function attached, remove it before
  // adding another one. Important for workflows with modals.
  if (selectorString && funcName) {
    jQuery('body').off('change', selectorString, funcName);
    jQuery('body').on('change', selectorString, refreshAllFn);
  }
}
// For numbers, expect clean numbers (Number(val.replace(/\$|\,/g, "")))
// For dates, expect formatted dates (Date.parse(val))
// For lists, expect arrays ([..,..])
function evalCondition(val, operator, cond) {
  var $A = function (arr) {
    return Array.isArray(arr) ? Array.prototype.slice.call(arr) : [arr];
  };

  var checkCondition = function(val, operator, cond) {
      switch (operator) {
          case 'less_than':
          case 'less than':
              return val !== "" && val < cond;
          case 'less_than_or_equal_to':
          case 'less than or equal to':
              return val !== "" && val <= cond;
          case 'equal_to':
          case 'equal to':
              return val == cond;
          case 'not_equal_to':
          case 'not equal to':
              return val !== "" && val != cond;
          case 'greater_than':
          case 'greater than':
              return val !== "" && val > cond;
          case 'greater_than_or_equal_to':
          case 'greater than or equal to':
              return val !== "" && val >= cond;
          case 'between':
              // using !== otherwise 0 != "" evaluates to false
              return val !== "" && val >= cond[0] && val <= cond[1];
          case 'is_blank':
          case 'is blank':
              return val == "";
          case 'is_not_blank':
          case 'is not blank':
              return val != "";
          case 'includes':
              return $A(typeof val == 'string' ? val.split(',') : val).some(
                (element) => $A(cond).includes(element)
              );
          case 'does_not_include':
          case 'does not include':
              return !$A(typeof val == 'string' ? val.split(',') : val).some(
                (element) => $A(cond).includes(element)
              );
          default:
              throw new Error('Invalid operator');
      }
  };
  // for the includes/does not includes operators, val might be a standlone string (single value selected), or could be an array object
  // make it an array by doing this: typeof val == "string" ? val.split(","): val

  //(val[0]==undefined ? val : val[0])
  return checkCondition(val, operator, cond);
}

// fieldId = custom field ID, status = on/off
// parent container (whether div/internal or tr/external) should have a class of formRow
function toggleField(fieldId, status, selector) {
  // try the easy lookup for the #formRow first
  // var selectorString = '#formRow' + (selector ? selector : 'CG' + fieldId);
  // var p = jQuery('#formRowCG' + fieldId);
  // var parent = jQuery(selectorString);
  var parent = findField('formRow' + fieldId, 'formRow' + selector, '#');

  if (parent.length === 0) {
    // see if it has CLASS form of '.formRowCG + fieldId' (or passed in selector)
    // for Apply Online/EG, look for matching class on container item
    //   should only be one)
    // p = jQuery('.formRowCG' + fieldId);
    // parent = jQuery(selectorString.replace('#', '.'));
    parent = findField('formRow' + fieldId, 'formRow' + selector, '.');

    if (parent.length === 0) {
      // Nope. See if it's E2; looks like "#CG + fieldId/selector; get first parent .row
      // For E2 there is no formRow class
      // p = jQuery('#CG' + fieldId).parents('.row').first();
      // parent = jQuery(selectorString.replace('#formRowCG', '#CG')).parents('.row').first();
      parent = findField(fieldId, selector, '#').parents('.row').first();
      // Nope. See if it's E2 checkboxes, similar to above
      if (parent.length === 0) {
        // For E2 checkboxes
        // p = jQuery('.CG' + fieldId).first().parents('.row').first();
        // parent = jQuery(selectorString.replace('#formRowCG', '.CG')).parents('.row').first();
        parent = findField(fieldId, selector, '.').parents('.row').first();
      }
    }
  }
  // Still haven't found anything.
  // For subdividers
  if (parent.length === 0) {
    // selector = selector || '';
    // selectorString = selectorString.replace('#formRowCG', '#CG');
    // look for #CG + nums alone, unlike parents(.row).first(), as above
    // if (jQuery('#CG' + fieldId).length > 0 && jQuery('#CG' + fieldId).hasClass("formSubRow"))
    var child = findField(fieldId, selector, '#');
    // if (jQuery(selectorString).length > 0 && jQuery(selectorString).hasClass('formSubRow')) {
    if (child.length > 0 && child.hasClass('formSubRow')) {
      // parent = jQuery('#CG' + fieldId);
      // parent = jQuery(selectorString);
      parent = child;
    }
  }

  // we identified the row, now turn it off
  if (parent) {
    if (status == 'on') {
      parent.show();
    } else {
      parent.hide();

      clearValue(fieldId, '', selector);
      // TODO: stores temporary value and restores them
    }
  }
}

function toggleFieldValues(fieldId, status, selector, values, callingFn, sectionId) {
  // try the easy lookup for the #formRow first
  // var selectorString = '#formRow' + (selector ? selector : 'CG' + fieldId);
  // var p = jQuery('#formRowCG' + fieldId);
  // var parent = jQuery(selectorString);
  initializeRenderStatus(sectionId);
  var parent = findField('formRow' + fieldId, 'formRow' + selector, '#');
  var optionsSelector = '';

  if (parent.length === 0) {
    // see if it has CLASS form of '.formRowCG + fieldId' (or passed in selector)
    // for Apply Online/EG, look for matching class on container item
    //   should only be one)
    // p = jQuery('.formRowCG' + fieldId);
    // parent = jQuery(selectorString.replace('#', '.'));
    parent = findField('formRow' + fieldId, 'formRow' + selector, '.');

    if (parent.length === 0) {
      // Nope. See if it's E2; looks like "#CG + fieldId/selector; get first parent .row
      // For E2 there is no formRow class
      // p = jQuery('#CG' + fieldId).parents('.row').first();
      // parent = jQuery(selectorString.replace('#formRowCG', '#CG')).parents('.row').first();
      parent = findField(fieldId, selector, '#').parents('.row').first();
      // Nope. See if it's E2 checkboxes, similar to above
      if (parent.length === 0) {
        // For E2 checkboxes
        // p = jQuery('.CG' + fieldId).first().parents('.row').first();
        // parent = jQuery(selectorString.replace('#formRowCG', '.CG')).parents('.row').first();
        parent = findField(fieldId, selector, '.').parents('.row').first();
      }
    }
  }
  // Still haven't found anything.
  // For subdividers
  if (parent.length === 0) {
    // selector = selector || '';
    // selectorString = selectorString.replace('#formRowCG', '#CG');
    // look for #CG + nums alone, unlike parents(.row).first(), as above
    // if (jQuery('#CG' + fieldId).length > 0 && jQuery('#CG' + fieldId).hasClass("formSubRow"))
    var child = findField(fieldId, selector, '#');
    // if (jQuery(selectorString).length > 0 && jQuery(selectorString).hasClass('formSubRow')) {
    if (child.length > 0 && child.hasClass('formSubRow')) {
      // parent = jQuery('#CG' + fieldId);
      // parent = jQuery(selectorString);
      parent = child;
    }
  }

  // we identified the row, now toggle the correct options
  if (parent) {
    for (var i = 0; i < values.length; i++) {
      if (i === 0) {
        optionsSelector = 'option[value="' + values[i] + '"]';
      } else {
        optionsSelector += ', option[value="' + values[i] + '"]';
      }
    }

    if (status == 'on') {
      var selected = parent.find('select').val();
      var nullOption = parent.find('option[value=""]');

      parent.find('option').attr('data-cond-hide', callingFn).hide();
      parent.find(optionsSelector).removeAttr('data-cond-hide').show();

      // Deselection of hidden, unselected values
      if (typeof selected === 'string') {
        // Assume single-select
        if (isInitialRenderComplete(sectionId) && values.indexOf(selected) < 0) {
            if (selected) {
              changedFields.push(parent.find('label').text().replace(/\n/g, ''));
            }
            parent.find('select option').prop('selected', false);
            parent.find('option[value="' + selected + '"]').attr('data-cond-hide', callingFn).hide();
        } else {
          parent.find('option[value="' + selected + '"]').removeAttr('data-cond-hide').show();
        }
      } else {
        // If selected is not null, assume multi-select i.e., array of values
        selected && selected.forEach(function(elem) {
          if (values.indexOf(elem) < 0) {
            var isSelected = parent.find('option[value="' + elem + '"]').prop('selected');
            if (isInitialRenderComplete(sectionId)) {
              if (isSelected) {
                var labelText = parent.find('label').text().replace(/\n/g, '');
                if (!changedFields.includes(labelText)) {
                    changedFields.push(labelText);
                }
                parent.find('option[value="' + elem + '"]').prop('selected', false);
                parent.find(optionsSelector).removeAttr('data-cond-hide').show();
                parent.find('option').removeAttr('data-cond-hide', callingFn).show();
              }
            }
            else if (!isInitialRenderComplete(sectionId)) {
              parent.find('option[value="' + elem + '"]').removeAttr('data-cond-hide').show();
            }
          }
        });
      }

      // If null option is present, make sure user can still select it
      if (nullOption.length > 0) {
        nullOption.removeAttr('data-cond-hide').show();
      }

    } else {
      parent.find('option[data-cond-hide="' + callingFn + '"]').removeAttr('data-cond-hide').show();
    }
  }
}

// extracts the list value ID from an array of multi-selects
// that might be formatted as custom_field + "|" + list_value_id
// val.indexOf("|")==-1?val:val.split("|")[1]
// Usage val = ["306621|33509832", "306621|33509833", "306621|33509834"]
// Returns ["33509832", "33509833", "33509834"]
function condFormatMulti(val) {
  if (val == null || val.length == 0) {
    return '';
  } else {
    var newVal = [];
    if (typeof(val) !== 'string') {
      jQuery.each(val, function(index, v) {
        newVal.push(v.indexOf('|') == -1 ? v : v.split('|')[1]);
      });
    } else {
      newVal.push(val.indexOf('|') == -1 ? val : val.split('|')[1]);
    }
    return newVal;
  }
}

function condFormatNumber(val) {
  return (val == null || val.length == 0) ? '' : Number(val.replace(/\$|\,/g, ''));
}

function condFormatDate(val) {
  return (val == null || val.length == 0) ? '' : Date.parse(val);
}

// AOL/EG show responses as Yes/No, not Y/N
function condFormatYesNo(val) {
  return (val == null) ? '' : val.substring(0, 1);
}

function refreshAllPostActions(sectionId) {
  setInitialRenderComplete(sectionId, true);
  if (changedFields.length > 0) {
    alert('The following dependent fields have had previously selected values removed:\n   - ' + changedFields.join('\n   - '));
  }

  changedFields = [];
}
