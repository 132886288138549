﻿var CyberGrants  = CyberGrants || {};
CyberGrants.l10n = (function (self) {
// var self = {};
var _language = null;
self.language = function () {
_language = _language || document.body.getAttribute("lang");
return _language;
};
self.characterMaximum =  function (languageCode, limit) {
var output = "";
switch (languageCode)
{
case 'it': output = "(massimo " + limit + " caratteri)"; break;
case 'nl': output = "(maximaal " + limit + " tekens)"; break;
case 'de': output = "(max. " + limit + " Zeichen)"; break;
case 'fr': output = "(" + limit + " caractères maximum)"; break;
case 'ja': output = "（" + limit + "文字まで）"; break;
case 'pl': output = "(" + limit + " haractercay aximummay)"; break;
case 'es': output = "(máximo de caracteres " + limit + ")"; break;
case 'pt': output = "(" + limit + " máximo de caracteres)"; break;
case 'ru': output = "(не более " + limit + " символов)"; break;
case 'ko': output = "(최대 " + limit + " 글자)"; break;
case 'tr': output = "(maksimum " + limit + " karakter)"; break;
case 'zh': output = "（最多 " + limit + " 个字符）"; break;
case 'sv': output = '(' + limit + ' max antal tecken)'; break;
case 'da': output = '(maksimum ' + limit + ' tegn)'; break;
case 'hi': output = "(अधिकतम " + limit + " अक्षर)"; break;
case 'pl': output = "(maksymalnie " + limit + " znaków)"; break;
case 'ro': output = "(maximum " + limit + " caractere)"; break;
case 'th': output = "(ไม่เกิน " + limit + " อักขระ)"; break;
case 'uk': output = "(" + limit + "максимум символів)"; break;
case 'vi': output = "(tối đa " + limit + " ký tự)"; break;
default: output = "(" + limit + " character maximum)"; break;
}
return output;
},
self.charactersRemaining = function (languageCode, remaining) {
var output = "";
switch (languageCode)
{
case 'de': output = "noch " + remaining + " Zeichen"; break;
case 'es': output = "" + remaining + " caracteres que quedan"; break;
case 'pl': output = "" + remaining + " haracter(scay) emainingray"; break;
case 'fr': output = "" + remaining + " caractères restants"; break;
case 'nl': output = "" + remaining + " tekens resterend."; break;
case 'es': output = "caracteres que quedan " + remaining + ""; break;
case 'it': output = "" + remaining + " caratteri restanti"; break;
case 'ja': output = "あと" + remaining + "文字"; break;
case 'pt': output = "" + remaining + " caracteres restantes"; break;
case 'ru': output = "Осталось символов: " + remaining + ""; break;
case 'ko': output = "" + remaining + " 글자 남았음"; break;
case 'tr': output = "" + remaining + " karakter kaldı"; break;
case 'zh': output = "还剩 " + remaining + " 个字符"; break;
case 'sv': output = '' + remaining + ' tecken kvar'; break;
case 'da': output = '' + remaining + ' tegn tilbage'; break;
case 'hi': output = "" + remaining + " अक्षर शेष"; break;
case 'pl': output = "Liczba pozostałych znaków: " + remaining + ""; break;
case 'ro': output = "" + remaining + " caractere rămase"; break;
case 'th': output = "ยังเหลืออยู่ " + remaining + " อักขระ"; break;
case 'uk': output = "Залишилося символів: " + remaining + ""; break;
case 'vi': output = "còn lại " + remaining + " ký tự"; break;
default: output = "" + remaining + " character(s) remaining"; break;
}
return output;
},
self.charactersOver = function (languageCode, overage) {
var output = "";
switch (languageCode)
{
case 'fr': output = "" + overage + " caractères en trop"; break;
case 'it': output = "" + overage + " caratteri in più"; break;
case 'pl': output = "" + overage + " haracter(scay) overay"; break;
case 'de': output = "" + overage + " Zeichen über dem Limit"; break;
case 'es': output = "caracteres de más " + overage + ""; break;
case 'es': output = "" + overage + " caracteres de más"; break;
case 'ru': output = "Превышение лимита символов: " + overage + ""; break;
case 'nl': output = "" + overage + " resterend(e) teken(s)"; break;
case 'ja': output = "" + overage + "文字超えました"; break;
case 'pt': output = "" + overage + " caracteres a mais"; break;
case 'ko': output = "" + overage + " 글자 초과"; break;
case 'tr': output = "" + overage + " karakter fazla"; break;
case 'zh': output = "超出 " + overage + " 个字符"; break;
case 'sv': output = '' + overage + ' tecken som överstiger'; break;
case 'da': output = '' + overage + ' tegn over'; break;
case 'hi': output = "" + overage + " अक्षर अधिक है/हैं"; break;
case 'pl': output = "Za dużo o " + overage + " znaków"; break;
case 'ro': output = "" + overage + " caractere depășite"; break;
case 'th': output = "เกินอยู่ " + overage + " อักขระ"; break;
case 'uk': output = "Більше на " + overage + " символ(-и, -ів)"; break;
case 'vi': output = "quá " + overage + " ký tự"; break;
default: output = "" + overage + " character(s) over"; break;
}
return output;
},
self.invalidNumber = function (languageCode, input) {
var output = "";
switch (languageCode)
{
case 'pt': output = "\"" + input + "\" não é um número válido."; break;
case 'de': output = "„" + input + "“ ist keine gültige Nummer."; break;
case 'it': output = "\"" + input + "\" non è un numero valido."; break;
case 'pl': output = "\"" + input + "\" isay otnay aay alidvay umbernay"; break;
case 'ja': output = "\"" + input + "\"は有効な数値ではありません。"; break;
case 'es': output = "\"(" + input + ")\" no es una cifra válida."; break;
case 'nl': output = "\"" + input + "\" is geen geldig getal."; break;
case 'ru': output = "\"" + input + "\" не является действительным числом."; break;
case 'es': output = "“" + input + "” no es una cifra válida."; break;
case 'fr': output = "« " + input + " » n'est pas un nombre valide."; break;
case 'ko': output = "\"" + input + "\" 은(는) 유효한 숫자가 아닙니다."; break;
case 'tr': output = "\"" + input + "\" geçerli bir numara değil."; break;
case 'zh': output = "\"" + input + "\" 不是有效的数字。"; break;
case 'sv': output = '"' + input + '" är inte ett giltigt nummer.'; break;
case 'da': output = '"' + input + '" er ikke et gyldigt tal.'; break;
case 'hi': output = "\"" + input + "\" एक मान्य संख्या नहीं है।"; break;
case 'pl': output = "„" + input + "” nie jest prawidłową liczbą."; break;
case 'ro': output = "„" + input + "” nu este un număr valid."; break;
case 'th': output = "\"" + input + "\" ไม่ใช่ตัวเลขที่ถูกต้อง"; break;
case 'uk': output = "«" + input + "» не є дійсним числом."; break;
case 'vi': output = "\"" + input + "\" không phải là một số hợp lệ."; break;
default: output = "\"" + input + "\" is not a valid number. "; break;
}
return output;
},
self.textOverLimit = function (languageCode, used, limit) {
var output = "";
switch (languageCode)
{
case 'pl': output = "Youray exttay isay " + used + " haracterscay! Leasepay imitlay youray inputay otay " + limit + " haracterscay!"; break;
case 'it': output = "Il testo ha " + used + " caratteri! Limitare il testo a " + limit + " caratteri!"; break;
case 'ja': output = "このテキストは" + used + "文字です。 入力文字数を" + limit + "文字に制限してください。"; break;
case 'es': output = "¡Su texto tiene una longitud de " + used + " caracteres! ¡Limite la entrada a " + limit + " caracteres!"; break;
case 'nl': output = "Uw tekst is " + used + " tekens lang! Beperk uw invoer tot " + limit + " tekens!"; break;
case 'de': output = "Ihr Text umfasst " + used + " Zeichen. Beschränken Sie die Eingabe bitte auf " + limit + " Zeichen."; break;
case 'fr': output = "Votre texte comprend " + used + " caractères! Veuillez limiter votre texte à " + limit + " caractères."; break;
case 'ru': output = "Длина введенного вами текста составляет " + used + " символов. Сократите текст до " + limit + "символов."; break;
case 'fr': output = "Votre texte comprend " + used + " caractères ! Veuillez limiter votre texte à " + limit + " caractères !"; break;
case 'pt': output = "Seu texto contém " + used + " caracteres! Limite sua entrada a " + limit + " caracteres!"; break;
case 'ko': output = "텍스트 글자수가 " + used + " 글자입니다! 입력을 " + limit + " 글자로 제한하십시오!"; break;
case 'tr': output = "Metniniz " + used + " karakterden oluşuyor! Lütfen girdinizi " + limit + " karakter ile sınırlayın!"; break;
case 'zh': output = "您的文本有 " + used + " 个字符！请将您的输入限制为 " + limit + " 个字符！"; break;
case 'sv': output = 'Din text innehåller ' + used + ' tecken! Begränsa din inmatning till ' + limit + ' tecken!'; break;
case 'da': output = 'Din tekst er på ' + used + ' tegn! Begræns din tekst til ' + limit + ' tegn!'; break;
case 'hi': output = "आपका पाठ्य " + used + " अक्षरों का है! कृपया अपने इनपुट को " + limit + " अक्षरों तक सीमित रखें!"; break;
case 'pl': output = "Twój tekst to " + used + " znaki/znaków! Wprowadź maksymalnie " + limit + " znaków!"; break;
case 'ro': output = "Textul dvs. are " + used + " caractere! Limitați datele introduse la " + limit + " caractere!"; break;
case 'th': output = "ข้อความของคุณมีอักขระ " + used + " ตัว! โปรดจำกัดการป้อนข้อมูลของคุณไว้ไม่เกิน " + limit + " อักขระ!"; break;
case 'uk': output = "Ваш текст складається з " + used + " символів! Обмежте введені дані до " + limit + " символів!"; break;
case 'vi': output = "Nội dung của bạn có " + used + " ký tự! Vui lòng giới hạn nội dung nhập của bạn ở " + limit + " ký tự!"; break;
default: output = "Your text is " + used + " characters! Please limit your input to " + limit + " characters!"; break;
}
return output;
};
return self;
})(CyberGrants.l10n || {});
