jQuery(document).ready(function () {
  if (jQuery('[data-portlet-container="rewardsPortlet"]').length >= 1) {
    renderPortlets();
  }
  function breaklines(text) {
    // Dear Future Dev,
    // We're trusting that the data that comes over the wire from our forms is
    // safe.  Our Internal Forms should run HTML Sanitization on the data before
    // saving, so we don't need to do it again here.
    return text
      ?.replace(
        /(~~rewardsReturn~~\n~~rewardsReturn~~\n)/gm,
        '</p><p class="rewards__copy">',
      )
      ?.replace(/(~~rewardsReturn~~\n)/gm, '<br />');
  }

  function renderPortlets() {
    jQuery('[data-portlet-container="rewardsPortlet"]').each(function () {
      var elementId = this.id;
      jQuery.ajax({
        url: jQuery(this).data('portletUrl'),
        dataType: 'json',
        type: 'GET',
        success: function (data) {
          var modifiedData = data;
          initPortlet(modifiedData, elementId);
        },
      });
    });
  }
  function initPortlet(modifiedData, elementId) {
    // This is the wrapperDiv that contains all of the jQuery elements.
    // It'll be removed later in the process.
    const wrapper = jQuery('<div>');

    const rewardsPortletDiv = jQuery('<div class="rewards-portlet">');
    const rewardsDiv = jQuery('<div class="rewards">');
    const rewardsColorBar = jQuery('<div class="rewards__color-bar">');
    const colorBarSVG =
      jQuery(`<svg class="rewards__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
        <path fill="#000000" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM6.5 12.031l-2.813-3.344 1.031-1.031 1.781 1.75 5.156-4.5 0.719 0.719-5.875 6.406z"></path>
      </svg>`);

    rewardsColorBar.append(colorBarSVG);
    rewardsDiv.append(rewardsColorBar);

    const rewardsContentDiv = jQuery('<div class="rewards__content">');
    const rewardsTitleHeader = jQuery('<h4>').text(modifiedData.title);
    const rewardsCopyContainer = jQuery(
      '<div class="rewards__copy--container">',
    );
    const rewardsCopy = jQuery('<p class="rewards__copy" >').html(
      breaklines(modifiedData.rewardsCopy),
    );

    rewardsCopyContainer.append(rewardsCopy);
    rewardsContentDiv.append(rewardsTitleHeader, rewardsCopyContainer);

    modifiedData.rewardsButton.forEach((button) => {
      const rewardsButtonContainer = jQuery(
        '<div class="rewards__button-container">',
      );
      const rewardsButtonWrapper = jQuery(
        '<div class="rewards__button-wrapper">',
      );
      const rewardsButton = jQuery('<a class="button rewards__button">').attr(
        'href',
        button.url,
      );
      const rewardsButtonSpan = jQuery(
        '<span class="rewards__button-copy">',
      ).text(modifiedData.ctaButtonText);
      rewardsButton.append(rewardsButtonSpan);

      const rewardsName = jQuery('<div class="rewards__name">').text(
        button.name,
      );
      rewardsButtonWrapper.append(rewardsButton, rewardsName);
      rewardsButtonContainer.append(rewardsButtonWrapper);
      rewardsContentDiv.append(rewardsButtonContainer);
    });

    const rewardsFooter = jQuery('<div class="rewards__footer">');
    const footerCopyContainer = jQuery(
      '<div class="rewards__copy--container">',
    );
    const footerCopy = jQuery('<p class="rewards__copy">').html(
      breaklines(modifiedData.footerRewardsCopy),
    );

    footerCopyContainer.append(footerCopy);
    rewardsFooter.append(footerCopyContainer);

    rewardsContentDiv.append(rewardsFooter);
    rewardsDiv.append(rewardsContentDiv);
    rewardsPortletDiv.append(rewardsDiv);
    wrapper.append(rewardsPortletDiv);

    const portletWrapper = jQuery('#' + elementId);
    jQuery('.loading-spinner', '#' + elementId).remove();

    portletWrapper.append(wrapper.html());

    CGInitViewMoreLess();
  }
});
