/*
  initJsTrees and the togglers all live in layout.js
  For pages that don't have layout.js, js-tree fixes are copied here
*/
jQuery(document).ready(function() {
  initJsTrees();
  jQuery('body').on('click', '.js-toggle__trigger', function(e){
    e.stopPropagation();
    if (jQuery(this).hasClass('js-toggle__dismissable')) {
      enableCloseClick(e.currentTarget);
    }
    toggleSelf(this);
    toggleDismissables(this);
  }).on('click',
    '.js-toggle__accordion-wrapper .js-toggle__trigger', function(e){
      // Close the other open accordion elements
    e.stopPropagation();
    closeOtherToggles(this);
  });
})

function toggleDismissables(element) {
  var me = jQuery(element).closest('.js-toggle__parent');
  var theirParents = jQuery('body')
                     .find('.js-toggle__dismissable')
                     .closest('.js-toggle__parent')
                     .not(me);

  closeAllToggles(theirParents);
}

/*
 * Updated to initialise and re-class all trees identified by <ul class="js-tree">
   so they'll be like the trees with newer markup elsewhere
 */
function initJsTrees() {
  var $ = jQuery;
  $('.js-tree').each(function() {
    $(this).removeClass('js-tree tree').addClass('expandable-tree flatTree');
  });
  $('.js-closed').each(function() {
    var sumTot = $(this).find('span[id^=summaryTotal]').first();

    $(this).contents()
                .not($(this).children())
                .first()
                .wrap('<div class="descriptionText description"></div>')
                .wrap('<span class="js-toggle__trigger js-toggle-state--collapsed"></span>');

    var trigger = $(this).find('.js-toggle__trigger').first();
    // moves the sum total field inside the toggle__trigger element so they both stay visible
    sumTot.appendTo(trigger);

    $(this).addClass('js-toggle__parent tree-parent').attr('aria-expanded', false);
    $(this).find('ul').addClass('js-toggle__target inline-list')
                      .slideUp()
                      .attr('aria-expanded', 'false')
                      .find('li div')
                        .addClass('inline-text-field')
                        .removeClass('form__section--row-full-width');
  });
}

/* Togglers copied from layout.js */
var enableCloseClick = function(element) {
  // get the toggle__parent: 'element' is the .js-toggle__trigger)
  var toggleParent = jQuery(element).closest('.js-toggle__parent');
  // var elementParent = jQuery(element).closest('.js-toggle__parent');
  /*  if (jQuery(element).hasClass('.js-toggle__parent')) {
    elementParent = element;
  }*/
  jQuery('body').one('click', function(event) {
    if (jQuery(event.target).parents().filter(toggleParent).length !== 0) {
      enableCloseClick(element);
    } else if (jQuery(event.target).filter(element).length === 0) {
      closeAllToggles(toggleParent);
    }
  });
};

var closeAllToggles = function(elements) {
  elements.filter('.js-toggle-state--expanded')
      .removeClass('js-toggle-state--expanded')
      .addClass('js-toggle-state--collapsed');
  elements.find('.js-toggle-state--expanded')
      .removeClass('js-toggle-state--expanded')
      .addClass('js-toggle-state--collapsed');
  elements.find('.js-toggle__target')
      .slideUp()
      .attr('aria-expanded', 'false');
};

var closeOtherToggles = function(element) {
  var myParent = jQuery(element).closest('.js-toggle__parent');
  var theirParents = jQuery(element)
    .closest('.js-toggle__accordion-wrapper')
    .find('.js-toggle__parent')
    .not(myParent);
  closeAllToggles(theirParents);
};

var toggleSelf = function(element) {
  var myParent = jQuery(element).closest('.js-toggle__parent');
  var isVisible = myParent.find('.js-toggle__target').is(':visible');
  var toggleStateMarker = myParent.find('.js-toggle-state--expanded, .js-toggle-state--collapsed');

  if (toggleStateMarker.length === 0) {
    toggleStateMarker = myParent;
  }
  toggleStateMarker.toggleClass('js-toggle-state--expanded js-toggle-state--collapsed');
  myParent.find('.js-toggle__target').slideToggle()
          .attr('aria-expanded', function() {
            return isVisible ? 'false' : 'true';
          });
};
