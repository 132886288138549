// import * as moment from './moment';
jQuery(document).ready(function() {
  jQuery('form[data-validator="validateQuickSearch"]')
    .on("submit", function(event) {
      validateQuickSearch(event, jQuery(this))
  });

  jQuery('form[data-validator="validateVCQuickSearch"]')
    .on("submit", function(event) {
      validateQuickSearch(event, jQuery(this))
  });

  jQuery('body').on('change', 'input[data-valid-format], input[data-field-constraint]', function(e) {
    validationJoin(jQuery(e.target));
  })
  .on('focus mouseenter', '.validation-wrapper input', function() {
    jQuery(this).next('.input-error').show();
  })
  .on('blur', '.validation-wrapper input', function() {
    jQuery(this).next('.input-error').hide();
  })
  .on('mouseout', '.validation-wrapper input', function() {
    if (!(document.activeElement == this)) {
      jQuery(this).next('.input-error').hide();
    }
  });

/* If you change these here, those must also be changed in cgadmin.field_constraint_model.pks.
   Delimiter Identification */
  var param_delimiter = '**';
  var line_delimiter = '??';

/* These must match the database (cgadmin.field_constraint.field_constraint_id)
   If they are changed, they must be changed here and in cgadmin.field_constraint_model.pks.
   Constraint Identification */
   var maximum_id = '1';
   var minimum_id = '2';
   var before_today_id = '3';
   var after_today_id = '4';
   var today_or_before_id = '5';
   var today_or_after_id = '6';
   var round_to_decimal_id = '7';


  var validationJoin = function(input) {
    input.unwrap('.validation-wrapper').removeAttr('style');
    input.siblings('.input-error').remove();
    var msg = '';
    var newLine = '<br>';

    var validatePattern = function(input) {
      var vwId = input.attr('name') + '-vw';
      try {
        if (input.val() && !(new RegExp(input.attr('data-valid-format'), 'gi').test(input.val()))) {
          msg = msg + (input.data('validFormatDesc') || 'Invalid input') + newLine;
        }
      } catch(e) {
        alert(e);
      }
    };

    var validateConstraint = function(input) {
      var constraintArr = input.attr('data-field-constraint').split(line_delimiter);
      var descArr = input.attr('data-field-constraint-desc').split(line_delimiter);
      var groupSeparator = input.attr('data-field-group-separator').split(line_delimiter);
      var dateFormat = input.attr('data-date-format');
      var inputDate = moment(input.val(), dateFormat, true);


      for (var i = 0; i < constraintArr.length - 1; i++) {
        var constraintID = constraintArr[i].split(param_delimiter)[0];
        var constraintValue = constraintArr[i].split(param_delimiter)[1];
        var constraintDate = moment(constraintValue, dateFormat, true);
        var vwId = input.attr('name') + '-vw';
        var descVal = descArr[i];

        if (i == constraintArr.length - 2) {
          newLine = '';
        }

        switch(constraintID) {
          
          case maximum_id:
            var sanitizedValue = input.val().replaceAll(groupSeparator, '');
            var sanitizedConstraintValue = constraintValue.replaceAll(groupSeparator, '');
            try {
              if (sanitizedValue != '' && (Number(sanitizedValue) > Number(sanitizedConstraintValue))) {
              msg = msg + ( descVal || 'Invalid input' ) + newLine;   
              }
            } catch(e) {
            alert(e);
            };
          break;
      
          case minimum_id:
            var sanitizedValue = input.val().replaceAll(groupSeparator, '');
            var sanitizedConstraintValue = constraintValue.replaceAll(groupSeparator, '');
            try {
            if (sanitizedValue != '' && (Number(sanitizedValue) < Number(sanitizedConstraintValue))) {
              msg = msg + ( descVal || 'Invalid input' ) + newLine;  
            }
            } catch(e) {
            alert(e);
            };
          break;

          case before_today_id:
            try {
              if (!inputDate.isValid()) {
                msg = msg + ( 'Invalid Input Date Format.' ) + newLine;
                i = constraintArr.length;  
              } else if (!inputDate.isBefore(constraintDate) && input.val() != '') {
                msg = msg + ( descVal || 'Invalid input' ) + newLine;  
              };
            } catch(e) {
              alert(e);
            };
          break;
  
          case after_today_id:
            try {
              if (!inputDate.isValid()) {
                msg = msg + ( 'Invalid Input Date Format.' ) + newLine;
                i = constraintArr.length;  
              } else if (!inputDate.isAfter(constraintDate) && input.val() != '') {
                msg = msg + ( descVal || 'Invalid input' ) + newLine;  
              };
            } catch(e) {
              alert(e);
            };
          break;
  
          case today_or_before_id:
            try {
              if (!inputDate.isValid()) {
                msg = msg + ( 'Invalid Input Date Format.' ) + newLine;
                i = constraintArr.length;  
              } else if (inputDate.isAfter(constraintDate) && input.val() != '') {
                msg = msg + ( descVal || 'Invalid input' ) + newLine;  
              };
            } catch(e) {
              alert(e);
            };
          break;
  
          case today_or_after_id:
            try {
              if (!inputDate.isValid()) {
                msg = msg + ( 'Invalid Input Date Format.' ) + newLine;
                i = constraintArr.length;  
              } else if (inputDate.isBefore(constraintDate) && input.val() != '') {
                msg = msg + ( descVal || 'Invalid input' ) + newLine;  
              };
            } catch(e) {
              alert(e);
            };
          break;

          case round_to_decimal_id:
            // Round to Decimal does not throw error, it just rounds.
            break;
            
           default:
            msg = msg + 'Invalid input. Unknown constraint.';
        }
      }
    };

    validatePattern(input);
    validateConstraint(input);

    if (msg != '') {
      try {
        input
          .css({borderColor:'#E61714'})
          .wrap('<div style="position: relative;" class="validation-wrapper"></div>')
          .closest('.validation-wrapper')
            .append('<div class="input-error">' + msg + '<div class="input-error-pointer"></div></div>')
            .find('.input-error').css(inputErrorStyle)
            .children('.input-error-pointer').css(inputErrorPointerStyle);
      } catch(e) {
        alert(e);
      }
    }
  };

  var inputErrorStyle = {
    display: 'none',
    position: 'absolute',
    bottom: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: '999',
    color: '#FFF',
    fontWeight: 'bold',
    padding: '.25rem .5rem',
    fontSize: '.875rem',
    backgroundColor: '#E61714',
    boxShadow: 'rgba(0, 0, 0, .2) 1px 1px 2px 0px',
    width: '98%'
  };
  var inputErrorPointerStyle = {
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: '15px 15px 0px',
    borderColor: '#E61714 transparent',
    display: 'block',
    width: '0',
    zIndex: '999',
    //bottom: '-30%',
    bottom: '-15px',
    left: '45%'
  };

  // Ensure that a proposal type is selected in the quick search portlets and disable the submit button
  validateQuickSearch = function (event, searchForm) {
    // if no proposal type selected and no required attribute exists (in which case, assume client-side validation),
    // throw up error and prevent form submission
    if (searchForm.find('select[name="x_pt_id"][required!="required"]').val() == "") {
      alert(searchForm.attr("data-required-error"));
      event.preventDefault();
    } else {
      // if there's a submit button, change the label and disable it
      var button = searchForm.find('input[type="submit"]');
      if (button) {
        button.val(searchForm.attr("data-please-wait"));
        button.prop("disabled",true);
      }
    }
  };

  // File upload field - file size validation
  (function() {
    var tooBigText = 'Specified file exceeds maximum allowable file size ({Limit} bytes).';
    var maxFileSize = 0;
    if (window.FileReader && window.File && window.FileList && window.Blob) {
      if (jQuery('body').data('langVersion')) {
        window.cgTranslations.getTranslations().done(function() {
          tooBigText = window.CyberGrants.l10n.constants.E_FILE_TOO_BIG;
          initFileSizeValidation();
        });
      } else {
        initFileSizeValidation();
      }
    } else {
      console.log('file api not supported');
    }
    function initFileSizeValidation() {
      jQuery('body').on('change', 'form[data-max-size] input[type=file]', function() {
        maxFileSize = parseInt(jQuery(this).closest('form').attr('data-max-size'));
        for (var i = 0; i < this.files.length; i ++) {
          var file = this.files[i];
          console.log(file.size, maxFileSize);
          if (file.size > maxFileSize) {
            jQuery(this).val('').trigger('change');
            alert(tooBigText.replace('{Limit}', maxFileSize));
          }
        }
      });
    }
  })();
});
