/*
http://www.alistapart.com

http://v2studio.com/k/code/lib/
*/

function envStgOrDev() {
	if (window.location.host === 'dev.cybergrants.com' ||
			window.location.host === 'stg-sandbox.cybergrants.com') {
		return true;
	} else {
		return false;
	}
}

function warnLibjsUsage() {
	// Removing this for now since we are not actively tracking lib.js usage.
	// if (envStgOrDev()) {
	// 	var err = new Error();
	// 	console.warn('The lib.js library is out-dated and should not be used.', err.stack);
	// }
}

// ARRAY EXTENSIONS

if (!Array.prototype.push) Array.prototype.push = function() {
    for (var i=0; i<arguments.length; i++) this[this.length] = arguments[i];
    return this.length;
}

/* *************************************************************
 * Directly prototyping the Array causes IE crashes in Prototype
 ***************************************************************
 * Array.prototype.find = function(value, start) {
 *   start = start || 0;
 *   for (var i=start; i<this.length; i++)
 *       if (this[i]==value)
 *           return i;
 *   return -1;
 * }
 */

/* *************************************************************
 * Directly prototyping the Array causes IE crashes in Prototype
 ***************************************************************
 * Array.prototype.has = function(value) {
 *    return this.find(value)!==-1;
 * }
 */

// FUNCTIONAL

function map(list, func) {
    warnLibjsUsage();
    var result = [];
    func = func || function(v) {return v};
    for (var i=0; i < list.length; i++) result.push(func(list[i], i, list));
    return result;
}

function filter(list, func) {
		warnLibjsUsage();
    var result = [];
    func = func || function(v) {return v};
    map(list, function(v) { if (func(v)) result.push(v) } );
    return result;
}


// DOM

function getElem(elem) {
		warnLibjsUsage();
    if (document.getElementById) {
        if (typeof elem == "string") {
            elem = document.getElementById(elem);
            if (elem===null) throw 'cannot get element: element does not exist';
        } else if (typeof elem != "object") {
            throw 'cannot get element: invalid datatype';
        }
    } else throw 'cannot get element: unsupported DOM';
    return elem;
}

function hasClass(elem, className) {
	warnLibjsUsage();
	// use the builtin Prototype method
	if ((typeof Prototype=='undefined') ||
		(typeof Element == 'undefined') ||
		(typeof Element.Methods=='undefined')) {
		throw 'Prototype must be loaded';
	}
	return $(elem).hasClassName(className);
}

function getAll(tagName, parent) {
		warnLibjsUsage();
    parent = isdef(parent)? getElem(parent) : document;
    if (undef(tagName)) tagName = '*';
    var r = parent.getElementsByTagName(tagName);
    
    return r.length || tagName != '*'?  map(r) :
        reduce(filterElementNodes(parent.childNodes), [], function(l,c){
            return l.merge([c], getAll(tagName, c))
        })
}

function getElementsByClass(searchClass,tag,node) {
	warnLibjsUsage();
	// The node list returned in IE has not been prototyped yet RT#59171
	var results = null,
		selector = null;

	if (node === undefined) node = document;
	if (tag === undefined) {
		results = node.getElementsByClassName(searchClass);
	} else {
		// Prototypejs's selector does not work on classes with pipes SS-32648
		// escape pipe (except where valid) for jQuery's selector
		selector = tag + '.' + searchClass.replace(/\|(?!=)/, '\\|');
		results = jQuery(selector);
	}

	return results;
}

// get parent of the element with the indicated tagName
function getParent(elem, tagName) {
	warnLibjsUsage();
	elem = getElem(elem);

	if (elem) {
		while(elem.parentNode) {
			elem = elem.parentNode;
			if (elem.nodeName && elem.nodeName.toUpperCase() == tagName.toUpperCase()) {
				return elem;
			}
		}
	}
	return null;
}

// WL: utility function to avoid browser inconsistencies
function getElementsByName(name, tagName, parentNode) {
	warnLibjsUsage();
	parentNode = !isUndefined(parentNode)? getElem(parentNode) : document;
	if (isUndefined(tagName)) tagName = '*';

	var elem = parentNode.getElementsByTagName(tagName);
	var arr = new Array();
	var nextIndex = 0
	for(var i = 0; i < elem.length; i++) {
		att = elem[i].getAttribute("name");
		if(att == name) {
			arr[nextIndex] = elem[i];
			nextIndex++;
		}
     }
     return arr;
}

// Revision of Scott Andrew's original addEvent
// http://www.quirksmode.org/blog/archives/2005/10/_and_the_winner_1.html
function addEvent( obj, type, fn )
{
	warnLibjsUsage();
	if (obj.addEventListener)
		obj.addEventListener( type, fn, false );
	else if (obj.attachEvent)
	{
		obj["e"+type+fn] = fn;
		obj[type+fn] = function() { obj["e"+type+fn](new W3CDOM_Event(obj)); }
//		obj[type+fn] = function() { obj["e"+type+fn]( window.event ); }
		obj.attachEvent( "on"+type, obj[type+fn] );
	}
}

function addEvents( obj, type, fn ) {
	warnLibjsUsage();
	for (var i = 0; i < obj.length; i++) {
		addEvent(obj[i], type, fn);
	}
}

function removeEvent( obj, type, fn )
{
	warnLibjsUsage();
	if (obj.removeEventListener)
		obj.removeEventListener( type, fn, false );
	else if (obj.detachEvent)
	{
		obj.detachEvent( "on"+type, obj[type+fn] );
		obj[type+fn] = null;
		obj["e"+type+fn] = null;
	}
}

// WL: add classes together
function addClass(element,value) {
	warnLibjsUsage();
	if (!element.className) {
		element.className = value;
	} else {
		var newClassName = element.className;
		newClassName += " ";
		newClassName += value;
		element.className = newClassName;
	}
}

function removeClass(element,value) {
	warnLibjsUsage();
	if (element.className) {
		var newClassName = " " + element.className + " ";	// WL: work this out later
		newClassName = newClassName.replace(value, "");
		// trim off spaces
		while (newClassName.charAt(0) == ' ')
			newClassName = newClassName.substring(1);
		while (newClassName.charAt(newClassName.length - 1) == ' ')
			newClassName = newClassName.substring(0, newClassName.length - 1);

		element.className = newClassName;
	}
}

function insertAfter(parent, node, referenceNode) {
	warnLibjsUsage();
	parent.insertBefore(node, referenceNode.nextSibling);
}


// DOM EVENTS

function listen(event, elem, func) {
		warnLibjsUsage();
    elem = getElem(elem);
    if (elem.addEventListener)  // W3C DOM
        elem.addEventListener(event,func,false);
    else if (elem.attachEvent)  // IE DOM
        elem.attachEvent('on'+event, function(){ func(new W3CDOM_Event(elem)) } );		// WL: know that I changed this to window.event, but not sure what didn't work originally
//        elem.attachEvent('on'+event, function(){ func(window.event)});	
        // for IE we use a wrapper function that passes in a simplified faux Event object.
    else throw 'cannot add event listener';
}


// code to find the source of the event
function getEventSource(e) {
	warnLibjsUsage();
	if (typeof e == 'undefined') {
		var e = window.event;
	}
	var source;
	if (typeof e.target != 'undefined') {
		source = e.target;
	} else if (typeof e.srcElement != 'undefined') {
		source = e.srcElement;
	} else {
		return;
	}

	return source;
}

function mlisten(event, elem_list, func) {
		warnLibjsUsage();
//    map(elem_list, function(elem) { listen(event, elem, func) } );
    map(elem_list, function(elem) { addEvent(elem, event, func) } );
}

function W3CDOM_Event(currentTarget) {
		warnLibjsUsage();
    this.currentTarget   = currentTarget;
    this.preventDefault  = function() { window.event.returnValue  = false }
// WL: added these to better support IE
    this.stopPropagation = function() { window.event.cancelBubble = true }
    this.target  = window.event.srcElement;
    var self = this;

    return this;
}


// handle browser inconsistences with XML HTTP object
function getHttpObject()
{ 
	warnLibjsUsage();
	var objXMLHttp = null;
	if (window.XMLHttpRequest) {
		objXMLHttp = new XMLHttpRequest();
	}
	else if (window.ActiveXObject) {
		// use latest version available
		try {
			objXMLHttp=new ActiveXObject("Msxml2.XMLHTTP");
		} catch(e) {
			objXMLHttp=new ActiveXObject("Microsoft.XMLHTTP");
		}
	}
	return objXMLHttp;
}


// MISC CLEANING-AFTER-MICROSOFT STUFF

function isUndefined(v) {
		warnLibjsUsage();
    var undef;
    return v===undef;
}

// format numbers with group separators
function formatNumber(n, groupSeparator, isCurrency, currencySymbol, decimalSeparator) {
	warnLibjsUsage();
	var isNegative = false;

	if (decimalSeparator == null) {
		decimalSeparator = '.';
	}

	// check for negative
	if (n < 0) {
		isNegative = true;
		n = n * -1;
	}

	var formattedNumber;
	var numberString = n.toString();
	var decimalPortion = '';
	if (numberString.indexOf(decimalSeparator) >= 0)
		decimalPortion = numberString.substring(numberString.indexOf(decimalSeparator));

	var numOctets = ((Math.floor(n)).toString().length - 1)/3;
	numOctets = Math.floor(numOctets);

	for (i = numOctets; i >= 0; i--) {
		if (i == numOctets) {
			formattedNumber = Math.floor(n/Math.pow(1000,i));
		} else {
			var octet = Math.floor(n/Math.pow(1000,i)).toString();
			if (octet.length == 1) {
				octet = "00" + octet;
			} else if (octet.length == 2) {
				octet = "0" + octet;
			}
			formattedNumber += groupSeparator + octet;
		}
		n = n%(Math.pow(1000,i));
	}

	if (isCurrency) {
		formattedNumber = currencySymbol + formattedNumber;
	}

	// add in decimals
	if (decimalPortion != '' || isCurrency) {
		numberString = decimalPortion.toString();
		formattedNumber = formattedNumber + decimalPortion;
	}

	// restore negativeness
	if (isNegative) {
		formattedNumber = "-" + formattedNumber;
	}

	return formattedNumber;
}

// from quirksmode.org
// using this only to detect Safari for purposes of working around lack of event.preventDefault() support
var BrowserDetect = {
	init: function () {
		this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
		this.version = this.searchVersion(navigator.userAgent)
			|| this.searchVersion(navigator.appVersion)
			|| "an unknown version";
		this.OS = this.searchString(this.dataOS) || "an unknown OS";
	},
	searchString: function (data) {
		for (var i=0;i<data.length;i++)	{
			var dataString = data[i].string;
			var dataProp = data[i].prop;
			this.versionSearchString = data[i].versionSearch || data[i].identity;
			if (dataString) {
				if (dataString.indexOf(data[i].subString) != -1)
					return data[i].identity;
			}
			else if (dataProp)
				return data[i].identity;
		}
	},
	searchVersion: function (dataString) {
		var index = dataString.indexOf(this.versionSearchString);
		if (index == -1) return;
		return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
	},
	dataBrowser: [
		{
			string: navigator.vendor,
			subString: "Apple",
			identity: "Safari"
		},
		{
			prop: window.opera,
			identity: "Opera"
		},
		{
			string: navigator.vendor,
			subString: "iCab",
			identity: "iCab"
		},
		{
			string: navigator.vendor,
			subString: "KDE",
			identity: "Konqueror"
		},
		{
			string: navigator.userAgent,
			subString: "Firefox",
			identity: "Firefox"
		},
		{	// for newer Netscapes (6+)
			string: navigator.userAgent,
			subString: "Netscape",
			identity: "Netscape"
		},
		{
			string: navigator.userAgent,
			subString: "MSIE",
			identity: "Explorer",
			versionSearch: "MSIE"
		},
		{
			string: navigator.userAgent,
			subString: "Gecko",
			identity: "Mozilla",
			versionSearch: "rv"
		},
		{ 	// for older Netscapes (4-)
			string: navigator.userAgent,
			subString: "Mozilla",
			identity: "Netscape",
			versionSearch: "Mozilla"
		}
	],
	dataOS : [
		{
			string: navigator.platform,
			subString: "Win",
			identity: "Windows"
		},
		{
			string: navigator.platform,
			subString: "Mac",
			identity: "Mac"
		},
		{
			string: navigator.platform,
			subString: "Linux",
			identity: "Linux"
		}
	]

};
BrowserDetect.init();

// https://github.com/kangax/protolicious/blob/5b56fdafcd7d7662c9d648534225039b2e78e371/event.simulate.js
/**
 * Event.simulate(@element, eventName[, options]) -> Element
 * 
 * - @element: element to fire event on
 * - eventName: name of event to fire (only MouseEvents and HTMLEvents interfaces are supported)
 * - options: optional object to fine-tune event properties - pointerX, pointerY, ctrlKey, etc.
 *
 *    $('foo').simulate('click'); // => fires "click" event on an element with id=foo
 *
 **/
 try {
	(function(){
	  
	  var eventMatchers = {
	    'HTMLEvents': /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
	    'MouseEvents': /^(?:click|mouse(?:down|up|over|move|out))$/
	  }
	  var defaultOptions = {
	    pointerX: 0,
	    pointerY: 0,
	    button: 0,
	    ctrlKey: false,
	    altKey: false,
	    shiftKey: false,
	    metaKey: false,
	    bubbles: true,
	    cancelable: true
	  }
	  
	  Event.simulate = function(element, eventName) {
			warnLibjsUsage();
	    var options = Object.extend(defaultOptions, arguments[2] || { });
	    var oEvent, eventType = null;
	    
	    element = $(element);
	    
	    for (var name in eventMatchers) {
	      if (eventMatchers[name].test(eventName)) { eventType = name; break; }
	    }

	    if (!eventType)
	      throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

	    if (document.createEvent) {
	      oEvent = document.createEvent(eventType);
	      if (eventType == 'HTMLEvents') {
	        oEvent.initEvent(eventName, options.bubbles, options.cancelable);
	      }
	      else {
	        oEvent.initMouseEvent(eventName, options.bubbles, options.cancelable, document.defaultView, 
	          options.button, options.pointerX, options.pointerY, options.pointerX, options.pointerY,
	          options.ctrlKey, options.altKey, options.shiftKey, options.metaKey, options.button, element);
	      }
	      element.dispatchEvent(oEvent);
	    }
	    else {
	      options.clientX = options.pointerX;
	      options.clientY = options.pointerY;
	      oEvent = Object.extend(document.createEventObject(), options);
	      element.fireEvent('on' + eventName, oEvent);
	    }
	    return element;
	  }
	  
	  Element.addMethods({ simulate: Event.simulate });
	})()
} catch (e) {
	// Assume prototype not available
}

/*
Returns the byte count for JS strings.
http://stackoverflow.com/a/12205668
QA-2507
*/
function byteCount(s) {
	warnLibjsUsage();
	return encodeURI(s).split(/%(?:u[0-9A-F]{2})?[0-9A-F]{2}|./).length - 1;
}


/*
Used in procedures with flexible parameter parsing to support input of name/value pairs where the value is longer than 32K.
formName/field are IDs corresponding to the form and the input to splice
Function will chunk the field into pieces and add them to the form as hidden input fields

Used in mail template saving and template application.
 */
var spliceIt = function(formName, field) {
	warnLibjsUsage();
	var fieldVal = jQuery("#" + field).val();
	var byteSize = byteCount(fieldVal);
	var length = fieldVal.length;
	var chunkLengthSize = 7500; // (32000/4) - 500; (MaxByteSize/WorstCaseScenario) - Overhead 
	var buf;

	if (byteSize > 30000) {
		// create hidden parameters to hold the chunks.
		// when the bytes size exceedes the chunk size we
		// assume the worst case scenario that each char is
		// 4 bytes. This reduces the risk and complication
		// of accidently cutting of the character early.
		for (var i = 0; i <= (length/chunkLengthSize); i++) {
		    buf = fieldVal.substring((i*chunkLengthSize), ((i*chunkLengthSize) + chunkLengthSize));

			var input = document.createElement("input");
			input.setAttribute("type", "hidden");
			input.setAttribute("name", field);
			input.setAttribute("value", buf);
			document.getElementById(formName).appendChild(input);
		}

		// and blank out the original field
		jQuery("#" + field).val("");
	}
}
