/*
	Updated function for summable lists (used internally and externally) -- NO PROTOTYPE.JS
*/
function updateTotal(currentInput, inputClass, divId, isCurrency, currencySymbol, groupSeparator, decimalSeparator, numPrecision) {
  var wrapperElement = jQuery(currentInput).closest('.js-form-row, .row, .formRow');
  // Can't use normal ID selector because jQuery doesn't like IDs with pipe characters, which can happen in nested summables.
  var totalDiv = wrapperElement.find('[id="' + divId + '"]')[0];

  // make sure div containing total exists before doing all the calculations
  if (totalDiv) {
    // for detecting entry issues, match anything that is not digits
    var initialReg = new RegExp(/[^0-9]/gi);
    var reg;      // define regular expression to be used
    currencyRegex = (currencySymbol == null || currencySymbol === '$') ? '\\$' : currencySymbol;
    decimalSeparator = decimalSeparator || '.';
    isCurrency = isCurrency || false;
    groupSeparator = groupSeparator || ',';
    // create variable for precision because we'll need to determine precision below if non-currency
    // New Note: nothing ever limits precision on currency fields so this has nothing to do with currency
    var precision = parseInt(numPrecision) || 0;

    if (!inputClass) {
      console.log('no input class... will not select any inputs');
      return false;
    } else if (isNaN(currentInput.value.replace(initialReg, ''))) {
      alert(CyberGrants.l10n.invalidNumber(CyberGrants.l10n.language(), currentInput.value));
      currentInput.value = '';
      currentInput.focus();
    }

    reg = new RegExp(currencyRegex + "|" + groupSeparator, "gi");
    var total = 0;
    // get values for each text box, convert to number
    wrapperElement.find('.' + jQuery.escapeSelector(inputClass)).each(function() {
      var val = this.value && this.value.replace(reg, '');
      var decLength;
      if (val) {
      	// check there IS a decimal, first
        decLength = val.indexOf(decimalSeparator) > -1 && val.substring(val.indexOf(decimalSeparator) + 1).length;
        // this means there's no limit to precision. Is that really wanted?
        // Even for currency? (though, currency of WHICH country...?)
        precision = decLength > precision ? decLength : precision;
        total += val*1;
      }
    });

    // format the total as needed; need to set precision due to the conversion of numbers from base 2 and then back to base 10
    var formattedTotal = formatNumber((total.toFixed(precision)), groupSeparator,
                                      isCurrency, currencySymbol, decimalSeparator);

    totalDiv.textContent = formattedTotal; // vanilla js

    // Check for and show a wrapper parent that is currently hidden
    // Will do nothing if there is no such wrapper
    jQuery('#' + jQuery.escapeSelector(divId) + 'Wrapper').show();
  }
}
