function CreditCardFunctions() {
  var that = this;
  var invalidDateRefresh = false;
  this.translations = {};
  this.currencyInfo = {};
  this.ccFieldRefs = {}; // commonly used fields
  this.provider = '';

  this.init = function() {
    if (document.getElementById('ggeForm1')) {
        that.provider = 'ggpay';
    } else {
        that.provider = 'sphere';
    }

    that.ccFieldRefs.frequency = jQuery(that.provider === 'ggpay' ?'#rccDropdown': '#CG\\.cc_frequency');
    that.ccFieldRefs.startDate = that.provider === 'ggpay' ? jQuery('#_kimbia.postDate__ggid1') : jQuery('#CG\\.cc_start_date');
    that.ccFieldRefs.endDate = jQuery('#CG\\.cc_end_date');
    that.ccFieldRefs.accountNumber = jQuery('#CG\\.acct_number');
    that.ccFieldRefs.endDateToggle = jQuery('#cc_end_date_toggle');
    that.ccFieldRefs.ccSubmitBtn = jQuery('#ccForm #primaryAction');


    that.currencyInfo.donationAmount = jQuery('#cgegreq').data('donationAmount');
    that.currencyInfo.donationFxc = jQuery('#cgegreq').data('donationFxc');

    if (jQuery('body').data('langVersion')) {
      var url = 'wwwuser.l10n_data.locale_ee';

      jQuery.when(
        jQuery.ajax(url),
        window.cgTranslations.getTranslations()
      ).done(function (res1, res2) {
        that.currencyInfo = jQuery.extend({}, that.currencyInfo, JSON.parse(res1[0]));

        that.translations.immediate = window.CyberGrants.l10n.constants.I_CC_CHARGE_IMMEDIATE;
        that.translations.future = window.CyberGrants.l10n.constants.I_CC_CHARGE_STARTDATE;
        that.translations.statement = window.CyberGrants.l10n.constants.I_CC_CHARGE_MERCHANT_NAME;
        that.translations.I_X_AMOUNT_FOR_Y_TIME = window.CyberGrants.l10n.constants.I_X_AMOUNT_FOR_Y_TIME;
        that.translations.I_AMOUNT_ESTIMATED_DONATION = window.CyberGrants.l10n.constants.I_AMOUNT_ESTIMATED_DONATION;
        that.translations.I_AMOUNT_FREQUENCY = window.CyberGrants.l10n.constants.I_AMOUNT_FREQUENCY;
        that.translations.I_AMOUNT_EST_FREQ_DONATION = window.CyberGrants.l10n.constants.I_AMOUNT_EST_FREQ_DONATION;
        that.translations.I_YEAR = window.CyberGrants.l10n.constants.I_YEAR;
        that.translations.I_YEAR_PLURAL = window.CyberGrants.l10n.constants.I_YEAR_PLURAL;
        that.translations.I_YEAR_ADVERB = window.CyberGrants.l10n.constants.I_YEAR_ADVERB;
        that.translations.I_QUARTER = window.CyberGrants.l10n.constants.I_QUARTER;
        that.translations.I_QUARTER_PLURAL = window.CyberGrants.l10n.constants.I_QUARTER_PLURAL;
        that.translations.I_QUARTER_ADVERB = window.CyberGrants.l10n.constants.I_QUARTER_ADVERB;
        that.translations.I_MONTH = window.CyberGrants.l10n.constants.I_MONTH;
        that.translations.I_MONTH_PLURAL = window.CyberGrants.l10n.constants.I_MONTH_PLURAL;
        that.translations.I_MONTH_ADVERB = window.CyberGrants.l10n.constants.I_MONTH_ADVERB;
        that.translations.I_PAYMENT = window.CyberGrants.l10n.constants.I_PAYMENT;
        that.translations.I_PAYMENT_PLURAL = window.CyberGrants.l10n.constants.I_PAYMENT_PLURAL;
        that.translations.I_YOU_HAVE_CHOSEN_TO_DONATE = window.CyberGrants.l10n.constants.I_YOU_HAVE_CHOSEN_TO_DONATE;
        that.translations.E_OOPS_REFRESH = window.CyberGrants.l10n.constants.E_OOPS_REFRESH;

        // the page that recurring credit cards uses is sometimes used for non credit card things.
        var shouldHaveMerchantAccountMessage = !(jQuery('#cgegreq').attr('data-merchant-name') == undefined);
        shouldHaveMerchantAccountMessage && that.addMerchantAccountMessageToDOM(); //This must be initialized before setupRecurringCreditCardArea() is called.
        that.setupRecurringCreditCardArea();

        that.ccFieldRefs.frequency.on('change', that.frequencyHandleChange);

        if (that.provider === 'sphere') {
          that.ccFieldRefs.startDate.on('change', that.updateAllTheThings);
        } else if (that.provider === 'ggpay') {
          document.getElementById('_kimbia.postDate__ggid1').on('change', that.updateAllTheThings);
        }
        // the end date field is replaced when the start date is changed b/c Pikaday, so this event needs to be delegated
        jQuery('.formRowCG\\.cc_end_date').on('change', 'input#CG\\.cc_end_date', that.updateSummaryCalculation);
        that.ccFieldRefs.endDateToggle.on('change', that.updateNoEndDateCheckbox);
        that.ccFieldRefs.accountNumber.on('keyup', that.accountNumberChange);
        jQuery('#ccForm').on('submit', that.alwaysDisableEndDateToggle);
        that.ccFieldRefs.ccSubmitBtn.attr('disabled', false);
      });
    }
  }

  this.getQueryParam = function (targetQueryParam) {
    var returnValue = null;
    var queryParamArray = window.location.search.substring(1).split('&');

    for (i = 0; i < queryParamArray.length; i++) {
      if (queryParamArray[i].indexOf(targetQueryParam) > -1) {
        returnValue = queryParamArray[i].split('=')[1];
        break;
      }
    }
    return returnValue;
  };

  this.greenEggsAndHam = function () {
    jQuery('#cc-token').val('');
    jQuery('#ccForm input, #ccForm select').attr('disabled', true);
    that.messageZoneAlert();
    that.ccFieldRefs.frequency.attr('disabled', true);
    that.ccFieldRefs.ccSubmitBtn.attr('disabled', true);
    if (that.ccFieldRefs.frequency.val()) {
      that.ccFieldRefs.startDate.attr('disabled', true);
    }
  };

  this.addMerchantAccountMessageToDOM = function () {
    const lastRow = that.provider === 'ggpay' ? jQuery('.ggeSection.ggeSection--payment') : jQuery('#finalFormRow');

    // hr
    var hr = jQuery('<hr>');

    // wrapper
    var wrapperDiv = jQuery('<div id="credit-card-summary-area">');

    // header
    var header = jQuery('<h4>');
    header.text(that.translations.I_YOU_HAVE_CHOSEN_TO_DONATE);
    header.addClass('text-center');

    // summary
    var summaryCalculation = jQuery('<p id="summary-calculation" class="text-center"></p>');

    // estimated total
    var estimatedTotal = jQuery('<p id="estimated-total" class="text-center"></p>');

    // message
    var newMessage = jQuery('<div>');
    newMessage.addClass('text-center')

    var newSentence = jQuery('<p>');
    newSentence.attr('id', 'merchant-account-message');
    var messageText = this.createMerchantAccountMessageText();
    newSentence.text(messageText);

    newMessage.append(newSentence);
    lastRow.after(newMessage);

    wrapperDiv.prepend(hr);
    wrapperDiv.append(header,[summaryCalculation, estimatedTotal]);
    lastRow.after(wrapperDiv);

    newSentence.parent().after('<hr style="margin-top: 4em;">')

    this.updateSummaryCalculation();
  }

  this.assembleUrl = function (endpoint, primaryKey, tableName, frequency, startDate, timeStamp) {
    return (
      endpoint + '?' +
      'p_primary_key=' + primaryKey + '&' +
      'p_table_name=' + tableName + '&' +
      'p_frequency=' + frequency + '&' +
      'p_start_date=' + startDate + '&' +
      'p_ts=' + timeStamp
    );
  }

  this.createMerchantAccountMessageText = function () {
    var merchantName = jQuery('#cgegreq').data('merchant-name');
    var dateFormat = that.currencyInfo.dateFormat;
    var isStartDateOnForm;
    if (that.provider === 'sphere') {
      isStartDateOnForm = !!that.ccFieldRefs.startDate.length;
    } else if (that.provider === 'ggpay') {
      isStartDateOnForm = that.ccFieldRefs.frequency.val() !== 'oneTimeOption';
    }
    const startDateFieldValue = that.provider === 'ggpay' ? document.getElementById('_kimbia.postDate__ggid1').value : that.ccFieldRefs.startDate.val();
    var startDate = moment(startDateFieldValue, dateFormat).format(dateFormat);

    if (startDate === 'Invalid date' && startDateFieldValue && !invalidDateRefresh) {
      invalidDateRefresh = true;
      setTimeout( function() {
        that.updateAllTheThings();
      }, 500);
      return '';
    }

    invalidDateRefresh = false;

    var isStartDateDisabled = jQuery('.formRowCG\\.cc_start_date').find('input').attr('disabled') == 'disabled';
    var today = moment();

    var sentences = {
      // template literals aren't supported in IE11.
      immediately: this.translations.immediate,
      futureDate: (this.translations.future.replace(/{Date Format}/gi, startDate)),
      statement: (this.translations.statement.replace(/{Input}/gi, merchantName)),
    }

    var text = '';
    if (merchantName) {
      if (moment(startDate, dateFormat).isAfter(today, 'days') && !isStartDateDisabled) {
        text = sentences.futureDate + '  ' + sentences.statement;
      } else if (moment(startDate, dateFormat).isSame(today, 'days') || isStartDateDisabled || !isStartDateOnForm) {
        text = sentences.immediately + ' ' + sentences.statement;
      }
    } else {
      if (!isStartDateOnForm || moment(startDate, dateFormat).isSame(today, 'days')) {
        text = sentences.immediately;
      } else {
        text = sentences.futureDate;
      }
    }

    return text;
  }

  this.formatCurrency = function(number) {
    var numberWithCorrectPrecision = number.toFixed(this.currencyInfo.numPrecision);
    var numberAsString = '' + numberWithCorrectPrecision;
    var containsDecimals = numberAsString.indexOf(this.currencyInfo.decimalSeparator) > -1
    var split = containsDecimals ? numberAsString.split(this.currencyInfo.decimalSeparator) : numberAsString;
    var dollars = containsDecimals ? split[0] : numberAsString;
    var cents = containsDecimals ? split[1] : null;

    dollars = dollars.split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x) {
        return x.split('').reverse().join('')
      })
      .reverse()
      .join(this.currencyInfo.groupSeparator);

    // var formattedCurrency = this.currencyInfo.currencySymbol + dollars
    var formattedCurrency = dollars;

    if (cents) {
      formattedCurrency += (this.currencyInfo.decimalSeparator + cents);
    }

    formattedCurrency = this.currencyInfo.donationFxc ?
        (formattedCurrency + ' ' + this.currencyInfo.donationFxc) :
        (this.currencyInfo.currencySymbol + formattedCurrency);

    return formattedCurrency;
  }

  this.frequencyHandleChange = function() {
    var startDateField = jQuery('.formRowCG\\.cc_start_date');
    var endDateField = jQuery('.formRowCG\\.cc_end_date');

    if (+that.ccFieldRefs.frequency.val()) {
      startDateField.show();
      endDateField.show();
    } else {
      startDateField.hide();
      startDateField.find('input').attr('disabled', true);
      endDateField.hide();
      endDateField.find('input').attr('disabled', true);
    }

    that.updateAllTheThings(); // we always need to get a new token.
  }

  this.accountNumberChange = function(e) {
    var currentNumber = jQuery("#CG\\.acct_number").val();
    jQuery("#CG\\.acct_number").val(currentNumber.replace(/[^0-9]+/g, '')); // only allow numbers
  }

  this.getNewSphereToken = function() {
    that.ccFieldRefs.frequency.attr('disabled', true);
    that.ccFieldRefs.startDate.attr('disabled', true);
    that.ccFieldRefs.ccSubmitBtn.attr('disabled', true);

    var primaryKey = jQuery('#cc-primaryKey').val();
    var tableName = jQuery('#cc-primaryKeyTable').val();
    var frequency = that.ccFieldRefs.frequency.val();

    var startDate = that.ccFieldRefs.startDate.val();
    var timeStamp = Date.now();

    var urlForNewToken = that.assembleUrl(
      'cc_application.refresh_token',
      primaryKey,
      tableName,
      frequency,
      startDate,
      timeStamp
    );

    jQuery.ajax(urlForNewToken)
      .then(function (res) {
        var updatedTokenData = JSON.parse(res);
        // always update the formData with the new token information
        isRecurringCreditCardsEnabled = !!that.ccFieldRefs.frequency.length;
        jQuery('#cc-token').val(updatedTokenData[0].value);
        jQuery('#cc-action').val(updatedTokenData[1].value);
        jQuery('#cc-store').val(updatedTokenData[2].value);

        // toggle fields based on the new token data, so we can use one form and our existing post logic
        if (updatedTokenData[1].value == 'store') {
          // FYI: response action is 'store' if frequency and startDate > today
          jQuery('#cc-amount').attr('disabled', true);
          jQuery('#cc-store').attr('disabled', true);
          if (isRecurringCreditCardsEnabled) {
            jQuery('#cc-verify').attr('disabled', false);
          }
        } else if (updatedTokenData[1].value == 'sale') {
          jQuery('#cc-amount').attr('disabled', false);
          jQuery('#cc-store').attr('disabled', false);
          if (isRecurringCreditCardsEnabled) {
            jQuery('#cc-verify').attr('disabled', true);
          }
        }
        return true;
      })
      .then(function () {

        that.ccFieldRefs.frequency.attr('disabled', false);
        that.ccFieldRefs.ccSubmitBtn.attr('disabled', false);

        if (that.ccFieldRefs.frequency.val()) {
          that.ccFieldRefs.startDate.attr('disabled', false);
        }
        return true;
      })
      .fail(function (err) {
        that.greenEggsAndHam();
      });
  };

  this.messageZoneAlert = function () {
    var messageEvent = new CustomEvent('updateMessageZoneText', {
      detail: [],
    });
    document.dispatchEvent(messageEvent);

    var typeEvent = new CustomEvent('updateMessageZoneType', {
      detail: 'alert',
    });
    document.dispatchEvent(typeEvent);

    var titleEvent = new CustomEvent('updateMessageZoneTitle', {
      detail: that.translations.E_OOPS_REFRESH,
    });
    document.dispatchEvent(titleEvent);

    var iconEvent = new CustomEvent('updateMessageZoneIcon', {
      detail: 'warning',
    });
    document.dispatchEvent(iconEvent);
  };

  this.setupRecurringCreditCardArea = function() {
    var startDateField = jQuery('.formRowCG\\.cc_start_date');
    var endDateField = jQuery('.formRowCG\\.cc_end_date');

    if (!that.ccFieldRefs.frequency.val()) {
      startDateField.hide();
      startDateField.find('input').attr('disabled', true);
      endDateField.hide();
      endDateField.find('input').attr('disabled', true);
    }

    if (!that.ccFieldRefs.frequency.length) {
      // this field cannot be included in a sale.  When the page loads, changes to
      // the field value should change this attribute correctly.
      jQuery('#cc-verify').attr('disabled', true);
    } else if (that.ccFieldRefs.frequency.val() > 0) {
      jQuery('#cc-verify').attr('disabled', true);
      jQuery('#cc-store').val("y");
      that.updateAllTheThings();
    }

    if (!startDateField.find('input').val()) {
      endDateField.hide();
      endDateField.find('input').attr('disabled', true);
    }

    that.updateEndDateField();
  }

  this.updateAllTheThings = function () {
    var isStartDateVisibleAndDisabled = jQuery('.formRowCG\\.cc_start_date:visible').find('input').attr('disabled') == 'disabled';
    var shouldHaveMerchantAccountMessage = !(jQuery('#cgegreq').attr('data-merchant-name') == undefined);
    shouldHaveMerchantAccountMessage && !isStartDateVisibleAndDisabled && that.updateMerchantAccountMessage();
    if (that.provider === 'sphere') {
      that.getNewSphereToken();
    }
    that.updateSummaryCalculation();
    that.updateEndDateField();
  }

  this.updateEndDateField = function () {
    var startDateField = jQuery('.formRowCG\\.cc_start_date');
    var endDateField = jQuery('.formRowCG\\.cc_end_date');

    if (+that.ccFieldRefs.frequency.val() && startDateField.find('input').val()) {
      endDateField.show();
      endDateField.find('input').attr('disabled', false);
      if (endDateField.find('#cc_end_date_toggle').prop('checked')) {
        endDateField.find('#CG\\.cc_end_date').attr('disabled', true);
      } else {
        endDateField.find('#CG\\.cc_end_date').attr('disabled', false);
      }
    }
  }

  this.updateNoEndDateCheckbox = function () {
    var endDateCheckbox = jQuery('#cc_end_date_toggle');
    // that.ccFieldRefs.endDate was losing track of the DOM element, probably
    // because it's hidden when the page is loaded and it's cloned on change
    if (that.ccFieldRefs.endDate.html() == '') {
      that.ccFieldRefs.endDate = jQuery('#CG\\.cc_end_date');
    }

    if (endDateCheckbox.prop('checked')) {
      that.ccFieldRefs.endDate.attr('disabled', true);
      that.ccFieldRefs.endDate.val('');
    } else {
      that.ccFieldRefs.endDate.attr('disabled', false);
    }

    that.updateSummaryCalculation();
  }

  this.updateSummaryCalculation = function () {
    var text = {
      '1': [that.translations.I_YEAR, that.translations.I_YEAR_PLURAL, that.translations.I_YEAR_ADVERB],
      '4': [that.translations.I_QUARTER, that.translations.I_QUARTER_PLURAL, that.translations.I_QUARTER_ADVERB],
      '12': [that.translations.I_MONTH, that.translations.I_MONTH_PLURAL, that.translations.I_MONTH_ADVERB],
      'payment': [that.translations.I_PAYMENT, that.translations.I_PAYMENT_PLURAL],
    };

    var transactionAmount = that.currencyInfo.donationAmount;
    var frequencyField = that.ccFieldRefs.frequency;
    var frequencyFieldValue;
    if (that.provider === 'sphere') {
      frequencyFieldValue = frequencyField.val();
    } else if (that.provider === 'ggpay') {
      const textTranslation = {
        'oneTimeOption': '0',
        'ANNUALLY': '1',
        'QUARTERLY': '4',
        'MONTHLY': '12',
      }
      frequencyFieldValue = textTranslation[frequencyField.val()];
    }
    var isRecurringFrequency = frequencyFieldValue > 0;
    var frequencyFieldSelectedTextAdverb = null; // default; when only "one time" is available
    if (frequencyField.is('select')) {
      // when there are multiple recurring options available (IE: one time, monthly and quarterly)
      frequencyFieldSelectedTextAdverb = frequencyField.find('option:selected').text().toLowerCase()
    } else if (frequencyField.is('input')) {
      // when there is only one recurring option (IE: monthly)
      frequencyFieldSelectedTextAdverb = text[frequencyField.val()][2];
    }
    var frequencyFieldSelectedText = null;
    var momentCalcDuration = null;
    var totalSpend = null;
    var paymentText = text.payment[1];

    switch (frequencyFieldValue) {
      case '1':
        momentCalcDuration = 'years'; // for MomentJS and shouldn't be translated
        frequencyFieldSelectedText = text[frequencyFieldValue][1];
        break;
      case '4':
        momentCalcDuration = 'months'; // for MomentJS and shouldn't be translated
        frequencyFieldSelectedText = text[frequencyFieldValue][1];
        break;
      case '12':
        momentCalcDuration = 'months'; // for MomentJS and shouldn't be translated
        frequencyFieldSelectedText = text[frequencyFieldValue][1];
        break;
      case '0':
      default:
        momentCalcDuration = null;
        frequencyFieldSelectedText = null;
        break;
    }

    const startDateFieldValue = that.provider === 'ggpay' ? document.getElementById('_kimbia.postDate__ggid1').value : jQuery('.formRowCG\\.cc_start_date').find('input').val();
    var endDateFieldValue = jQuery('.formRowCG\\.cc_end_date').find('input').val();
    var dateFormat = that.currencyInfo.dateFormat;

    var durationInDateFormatUnits = null;
    var durationAndText = null
    if (startDateFieldValue && endDateFieldValue) {
      if (frequencyFieldValue == 1) {
        durationInDateFormatUnits = Math.ceil(Math.floor(moment(endDateFieldValue, dateFormat).diff(moment(startDateFieldValue, dateFormat), 'years', true) + 1));
      } else if (frequencyFieldValue == 4) {
        // Our version of MomentJS doesn't convert to quarters, so this does fills in the gap.
        durationInDateFormatUnits = Math.ceil(Math.floor(moment(endDateFieldValue, dateFormat).diff(moment(startDateFieldValue, dateFormat), 'months', true) + 1) / 3);
      } else if (frequencyFieldValue == 12) {
        durationInDateFormatUnits = Math.ceil(Math.floor(moment(endDateFieldValue, dateFormat).diff(moment(startDateFieldValue, dateFormat), 'months', true) + 1));
      }

      // fix pluralization of word
      if (durationInDateFormatUnits == 1) {
        frequencyFieldSelectedText = text[frequencyFieldValue][0];
        paymentText = text.payment[0];
      }

      durationAndText = durationInDateFormatUnits + ' ' + frequencyFieldSelectedText;
    }

    if (endDateFieldValue) {
      // if there's an endDateFieldValue, we can calculate the actual spend
      totalSpend = durationInDateFormatUnits * transactionAmount;
    } else {
      // otherwise, show an estimate annual spend
      totalSpend = transactionAmount * frequencyFieldValue;
    }

    if (isRecurringFrequency && endDateFieldValue) {
      // $50.00 monthly for 1 month
      // $50.00 monthly for 3 months
      // {Input} {Input2} for {Input3} {Input 4}
      // I_X_AMOUNT_FOR_Y_TIME
      var input = '<span class="single-transaction-amount emphasis">' + that.formatCurrency(transactionAmount) + '</span> ';
      var input2 = '<span class="summary-interval emphasis">' + frequencyFieldSelectedTextAdverb + '</span> ';
      var input3 = '<span class="summary-duration emphasis">' + durationAndText + '</span>';
      var summaryCalculation = that.translations.I_X_AMOUNT_FOR_Y_TIME
          .replace('{Input}', input)
          .replace('{Input2}', input2)
          .replace('{Input3}', input3)
          .replace('{Input 4}', '');

      var estimatedTotalInput = '<span class="estimated-donation">' + that.formatCurrency(totalSpend) + '</span>';
      var estimatedTotalInput2 = '<span class="payment-count">' + durationInDateFormatUnits + '</span>';
      var estimatedTotalInput3 = paymentText;
      var estimatedTotal = '<span class="emphasis gray">' + that.translations.I_AMOUNT_ESTIMATED_DONATION
        .replace('{Input}', estimatedTotalInput)
        .replace('{Input2}', estimatedTotalInput2)
        .replace('{Input3}', estimatedTotalInput3) + '</span>';

      jQuery('#summary-calculation').html(summaryCalculation);
      jQuery('#estimated-total').html(estimatedTotal);
    } else if (isRecurringFrequency && !endDateFieldValue) {
      // $50 monthly
      // {Input} {Input2}
      // I_AMOUNT_FREQUENCY
      var summaryCalculation = that.translations.I_AMOUNT_FREQUENCY
        .replace(
          '{Input}',
          '<span class="single-transaction-amount emphasis">' +
            that.formatCurrency(transactionAmount) +
          '</span> ')
        .replace(
          '{Input2}',
          '<span class="summary-interval emphasis">' +
          frequencyFieldSelectedTextAdverb +
          '</span>'
        );

      // $600 - estimated yearly donation
      // {Input} - estimated yearly donation
      // I_AMOUNT_EST_FREQ_DONATION
      var estimatedTotal = '<span class="emphasis gray">' +
        that.translations.I_AMOUNT_EST_FREQ_DONATION
          .replace(
            '{Input}',
            '<span class="estimated-donation">' +
              that.formatCurrency(totalSpend) +
            '</span>'
          ) +
        '</span>';

      jQuery('#summary-calculation').html(summaryCalculation);
      jQuery('#estimated-total').html(estimatedTotal);
    } else {
      var summaryCalculation = jQuery(
        '<span class="single-transaction-amount emphasis large">' +
          that.formatCurrency(transactionAmount) +
        '</span>'
      );
      jQuery('#summary-calculation').html(summaryCalculation);
      jQuery('#estimated-total').html('');
    }
  }

  this.updateMerchantAccountMessage = function () {
    var newText = this.createMerchantAccountMessageText();
    jQuery('#merchant-account-message').text(newText);
    this.updateSummaryCalculation();
  }

  this.alwaysDisableEndDateToggle = function () {
    jQuery('#cc_end_date_toggle').attr('disabled', true);
  }
};

jQuery(document).ready(function () {
  var creditCards = new CreditCardFunctions();
  if (jQuery('#ccForm').length) {
    // #ccForm denotes a credit card form for which this code is applicable
    creditCards.init();
  } else if (document.getElementById('GGPayFormURL')) {
    const callback = creditCards.init;
    const observer = new MutationObserver(callback);
    observer.observe(
      document.getElementById('external-form'),
      { childList: true }
    );
  }
});
