/*! tell-a-friend e2  */
jQuery(document).ready(function() {

  jQuery('body').on('click', '.TAFbutton', TAFFunction);

  //Load content from TAF page into hidden container, click the modaal link
  function TAFFunction(event) {
    event.preventDefault();
    jQuery('body').off('click', '.TAFbutton', TAFFunction);
    jQuery('body').on('click', '.TAFbutton', preventTAFbuttonClick);
    var tellAFriendURL = this.href;

    //Check if the container that will hold the loaded content from the tell a friend email page exists, if not create it and the modaal link to open it
    if (document.getElementById('hiddenTellAFriendForm') === null) {
      jQuery('body').append( jQuery( '<a href="#hiddenTellAFriendForm" class="hiddenTellAFriendLink" id="openTAFmodalLink"></a><div id="hiddenTellAFriendForm"></div>' ) );
      //activate modaal on the hidden div
      jQuery('.hiddenTellAFriendLink').modaal({
        after_open: ajaxifyFormPost,
        after_close: reactivateTAFbutton
      });
    }

    // This is not the deprecated .load function
    jQuery( '#hiddenTellAFriendForm' ).load( tellAFriendURL + ' #cgcontent', function (responseText, textStatus, XMLHttpRequest) {
      if (textStatus == 'success') {
        jQuery('#openTAFmodalLink').click();
      }
    });
  }

  function ajaxifyFormPost() {
//    toggle off in order to keep animation from breaking due to focus
    jQuery('.social-links').hide();
    jQuery('form.tell-a-friend-form').on('submit', function(event) {
      event.preventDefault();
      var form = jQuery(this);
      var formAction = form.attr('action');
      var formData = form.serialize() + '&x_send=Send';
      jQuery.ajax(formAction, {
        data: formData,
        dataType: 'html',
        success: handleFormResponse
      });
    });
    jQuery('#TAFcloseButton')[0].onclick = null;
    jQuery('#TAFcloseButton').click(function() {
      jQuery('#openTAFmodalLink').modaal('close');
    });
  }

  function preventTAFbuttonClick(event) {
    event.preventDefault();
  }
  function reactivateTAFbutton() {
    //    toggle on in order to keep animation from breaking due to focus
    jQuery('.social-links').show();
    jQuery('body').off('click', '.TAFbutton', preventTAFbuttonClick);
    jQuery('body').on('click', '.TAFbutton', TAFFunction);
  }

  function handleFormResponse(response) {
    var fullContent = jQuery(response);
    var modalContent = undefined;

    jQuery.each(fullContent, function(index, element) {
      if (jQuery(element).attr('id') === 'cgcontent') {
        modalContent = element;
      }
    });

    jQuery('.modaal-content-container').html(modalContent);
    jQuery('.modaal-wrapper').animate({ scrollTop: 0 }, 'slow');
    ajaxifyFormPost();
  }
});