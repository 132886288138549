
/*!
    Prevent form double-submit
*/

jQuery(function() {
  var $ = jQuery;

  $('body').on('submit', 'form', function(e) {
    if (e.isDefaultPrevented() === false) {
      var form = $(this);

      if (form.data('submitted') === true) {
        // Previously submitted - don't submit again
        e.preventDefault();
      } else {
        // Mark it so that the next submit can be ignored
        form.data('submitted', true);
        setFormTimeout(form);
      }
    }
  });

  setFormTimeout = function(form) {
    setTimeout(function() {
      // reset after 10 seconds if not a new page, so form can submit again
      form.data('submitted', false);
    }, 10000);
  };
});

