/*
Assuming a structure of: 
AOL:
<tr><td>label</td><td><input type="hidden" /></td></tr>
Internal Request Screen
<div class="formRow" id="formRowCG164255">
  <h3  class="formLabel">get org legal name <script language="JavaScript" type="text/javascript">getAOValue(726,164255,'request',264488);</script></h3>
  <div class="formReadOnly"></div>
  <input type="hidden" name="x_custom_request_value" value="" />
  <div class="clear"></div>
</div>
displays the value and sets the value of the hidden
*/

function updateAOField(labelId, value) {
  var reqForm = document.getElementsByName("reqForm")[0] || document.getElementsByName("impact_rpt")[0]; //SS--14206
  var sectionForm = jQuery('#cgcontent');
  if (reqForm) {
    // internal    
		var rDiv = jQuery("#formRow" + labelId + " .p--background-styled");
    if (rDiv[0]) rDiv[0].innerHTML = value;
  } else {
//  } else if (sectionForm.className = "donorUi") {
    // AOL
    var labelTd = jQuery('label[for="' + labelId + '"]').first().parents('td'); // td containing the label indicated (based on FOR attribute)
    var valueTd = labelTd.next(); 

    // the next td (should contain the value)

    // check to see if this cell contains an input element
    var currInput = valueTd.find('input');
    
      valueTd.html(value); 

      if (currInput.length) {
      // create a hidden input field with the specified value
      var hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("name", "x_custom_field_value");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("value", value);
       
        valueTd.append(hiddenInput);  // add in the hidden input (for save purposes)
      } /* Not needed since the value is stamped in above 
      else {
        valueTd.append(value);  // add in the literal value
        
      }*/
  }
}
function getAOValue(x_target_custom_field_id, x_section_custom_field_id, x_section_table_name, x_section_key) {
  jQuery.ajax({
    url:'ao_json.get_value',
    method: 'get', 
    data: { x_target_custom_field_id: x_target_custom_field_id, x_section_custom_field_id: x_section_custom_field_id, x_section_table_name: x_section_table_name, x_section_key: x_section_key, x_ts:  new Date().getTime()},
    success: function(resp) {
      var json = jQuery.parseJSON(resp);
      if ((json.xCustomFieldId != null) && (json.xValue != '')) { 
        updateAOField('CG' + json.xCustomFieldId, json.xValue);
      }
       }
    });
}
function getEGValue(x_target_custom_field_id, x_section_custom_field_id, x_section_table_name, x_section_key) {
  jQuery.ajax({
    url: 'eg_json.get_value', 
    method: 'get', 
    data: { x_target_custom_field_id: x_target_custom_field_id, x_section_custom_field_id: x_section_custom_field_id, x_section_table_name: x_section_table_name, x_section_key: x_section_key, x_ts:  new Date().getTime()},
    success: function(resp) {
      var json = jQuery.parseJSON(resp);
      if ((json.xCustomFieldId != null) && (json.xValue != '')) { 
        updateAOField('CG' + json.xCustomFieldId, json.xValue);
      }
       }
    });
}

function refreshAOList(obj, fieldId, selectedValue) {
  jQuery.ajax({
    url: 'ao_json.get_list_options',
    data: {x_field_id: fieldId, x_parent_value: selectedValue},
    success: function(response) {
      jQuery(obj).html(response);
    }
  });
}
function refreshEGList(obj, fieldId, selectedValue) {
  jQuery.ajax({
    url: 'eg_json.get_list_options',
    data: {x_field_id: fieldId, x_parent_value: selectedValue},
    success: function(response) {
      jQuery(obj).html(response);
    }
  });
}
