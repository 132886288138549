(function($) {
    $(document).ready(function() {

        var instafeedElement = $('#instafeed');
        var userId = instafeedElement.data('instagramUser');
        var hashtag = instafeedElement.data('instagramHashtag');
        var sort = instafeedElement.data('instagramSort');
        var options;
        var feed;
        if (userId) {
            options = {
                clientId: 'af917d10a4dd4a2a9762397ee2efe13c',
                limit: '12',
                get: 'user',
                userId: userId,
                accessToken: '2089965757.467ede5.8b116a31358d40cd8e1033114dcd31fb',
                template: '<div class="instafeedresult"> <a href="{{link}}" target="_blank"> <img src="{{image}}" /> </a> </div>'
            }
            if (hashtag) {
                options.filter = function(image) {
                    return image.tags.indexOf(hashtag) >= 0;
                };
            }
            if (sort) {
                options.sortBy = sort;
            }
            feed = new Instafeed(options);
            feed.run();
        }

    });
})(jQuery);