/*
    Dear Future Dev,
    `trackPortlet` is referenced by the backend.  It contains a data object that
    contains attributes that make Product smile.

    `pendoTrack` is the vendor-specific function, stolen from their documentation.

    If you're ever in a situation where we're swapping vendors, update the return
    statement in trackPortlet so we can keep the shenanigans to a single file.
*/
function trackPortlet(eventName, portletName, version) {
  const data = {
    portlet_name: portletName,
    portlet_version: version,
  };
  return pendoTrack(eventName, data);
}

/*
  Tracks search through its four steps:
  1) Organization Search  // see eg_search.result_page
  2) Gift Details         // see eg_application.display_timeline
  3) Credit Card Form     // see eg_application.display_timeline
  4) Confirmation Page    // see eg_application.display_timeline
*/
function trackSearch(
  grantmakerId,
  proposalTypeId,
  isPtEnabledForCAF,
  isCAFOrg,
  label,
) {
  const data = {
    label,
    grantmakerId,
    proposalTypeId,
    isPtEnabledForCAF,
    isCAFOrg,
  };
  return pendoTrack('search', data);
}

// https://support.pendo.io/hc/en-us/articles/360032294291-Track-Events-Configuration
function pendoTrack(name, data) {
  if (pendo && pendo.isReady && pendo.isReady()) {
    return pendo.track(name, data);
  }
  setTimeout(function () {
    pendoTrack(name, data);
  }, 500);
}
