// Focus alert box for accessibility
jQuery(document).ready(function() {
  jQuery('.alert-box').first().focus();
});


// Fix skip-to-content link so that it goes to first h1 on the page if we are
// in AO and there is a timeline present. This helps by bypassing the timeline,
// which is considered navigation and should not be part of the main content.
var setSkipToContentToH1 = function() {
  if (jQuery('#cgcontent.gsUi').length > 0 &&
      jQuery('#timelineTable').length > 0 &&
      jQuery('h1').length > 0) {
    var h1Id = jQuery('h1').first().attr('id');
    if (!h1Id) {
      h1Id = 'first-content-heading';
      jQuery('h1').first().attr('id', h1Id);
    }
    jQuery('#skip-to-content a').attr('href', '#' + h1Id);
  }
}
jQuery(document).ready(setSkipToContentToH1);


function initAjaxModaal() {
  jQuery('.modaal-ajax-cg-generic').each(function(index, element) {
    var options = {
      type: 'ajax',
    };
    var customClass = jQuery(element).attr('data-modaal-custom-class-name');
    if (customClass) {
      options.custom_class = customClass;
    }
    jQuery(element).modaal(options);
  });
}
// Activate generic modaal links
jQuery(document).ready(function() {
  if (jQuery('body').modaal) { // Make sure Modaal is loaded
    // ajax modals with optional custom class
    initAjaxModaal();
    // image gallery modal, which is simple
    jQuery('.modaal-image-cg-gallery').modaal({
      type: 'image'
    });
    // close button
    jQuery('body').on('click', '.modaal-close-cg-generic, .js-modal-close', function() {
      jQuery('.modaal, .modaal-ajax-cg-generic, .modaal-image-cg-gallery').modaal('close');
      jQuery('.modaal-close').click();
    });
  }
  // ajax modaal links are sometimes generated after the page loads.
  jQuery('body').on('click', '.modaal-ajax-cg-generic', function(e) {
    e.preventDefault();
    if (!jQuery(e.currentTarget).attr('data-modaal-scope')) {
      initAjaxModaal();
      jQuery(e.currentTarget).click();
    }
  });
});

// highlight current fields
jQuery(document).ready(formatScreen);

function formatScreen() {
  initLinks();  // popup windows and print windows
  formatTables();   // stripe rows and align table cells
  initButtons();
}

// warn about unsaved changes
function alert_unsaved() {
  if (formChanged)
    return confirm("Note: You have unsaved changes.  If you would like to proceed without saving, click OK.  To return to save your changes, click Cancel.");
  else
    return true;
}

// redefining default features
var _POPUP_FEATURES = 'location=0,scrollbars=1,resizable=1,statusbar=0,menubar=0,width=800,height=600';

function raw_popup(url, target, features) {
    // pops up a window containing url optionally named target, optionally having features
    if (isUndefined(features)) features = _POPUP_FEATURES;
    if (isUndefined(target  )) target   = '_blank';
    var theWindow = window.open(url, target, features);

    if (theWindow != null) {
      if (theWindow.opener == null) {
        theWindow.opener = self;
      }
      theWindow.focus();
    }
  // tool bar = yes?

    theWindow.focus();
    return theWindow;
}

function link_popup(src, features) {
    // to be used in an html event handler as in: <a href="..." onclick="link_popup(this,...)" ...
    // pops up a window grabbing the url from the event source's href
    return raw_popup(src.getAttribute('href'), src.getAttribute('target') || '_blank', features);
}
/*
function event_popup(e) {
    // to be passed as an event listener
    // pops up a window grabbing the url from the event source's href
    link_popup(e.currentTarget);
    e.preventDefault();
}
*/
function initLinks() {
  jQuery('body').on('click', 'a.popUp', function(e) {
    e.preventDefault();
    link_popup(e.currentTarget, 'location=0,scrollbars=1,resizable=1,statusbar=0,menubar=1,width=800,height=600');
  });
  jQuery('body').on('click', 'a.littlePop', function(e) {
    e.preventDefault();
    link_popup(e.currentTarget, 'location=0,scrollbars=1,resizable=1,statusbar=0,menubar=0,width=600,height=400');
  });
  jQuery('body').on('click', 'a.printLink', function(e) {
    e.preventDefault();
    printScreen();
  });

  // for backwards compatibility with frame-based nav pages
  var legacyNavLinks = jQuery('a.rightFrame');
  if (legacyNavLinks.length) {
    for (var i = 0; i < legacyNavLinks.length; i++) {
      legacyNavLinks[i].setAttribute("target", "rightFrame");
    }
  }
  legacyNavLinks = jQuery('a.workarea');
  if (legacyNavLinks.length) {
    for (var i = 0; i < legacyNavLinks.length; i++) {
      legacyNavLinks[i].setAttribute("target", "workarea");
    }
  }
}

function initButtons() {
  jQuery('body').on('click', 'input[type="button"].js-close-window', function(e) {
    window.close();
  });
}

function printScreen() {
  window.print();
}

function formatTables() {
  var tables = document.getElementsByTagName('table');
  if (tables) {
      // loop through all tables
      for (var i = 0; i < tables.length; i++) {
        var header = tables[i].tHead;
        // only if there is a thead in the table
        if (header) {
          var headerCells = header.rows[0].cells;
          var rightAlignIndexes = new Array();
          var centerAlignIndexes = new Array();

          if (headerCells) {
            var numHeaderCells = headerCells.length;
            for (var a = 0; a < numHeaderCells; a++) {
              if ( jQuery(headerCells[a]).hasClass("tdRight") ) {
                rightAlignIndexes[rightAlignIndexes.length] = a;
              } else if ( jQuery(headerCells[a]).hasClass("tdCenter") ) {
                centerAlignIndexes[centerAlignIndexes.length] = a;
              }
            }
          }

          // loop through all TBODYs in the current table
          var bodies = tables[i].tBodies;
          if (bodies) {
            for (var j = 0; j < bodies.length; j++) {
              var rows = bodies[j].rows;
              if (rows) {
                for (var k = 0; k < rows.length; k++) {
                  // stripe every other row
                  if (tables[i].className == "striped") {
                    if ((k % 2) == 1) {
                      jQuery(rows[k]).addClass("odd");
                    }
                  }

                  // only proceed with alignment if the number of cells in the row matches the header, otherwise, colspanning is at play, let them format themselves
                  if (rows[k].cells.length == numHeaderCells) {
                    var currentIndex;
                    // right align cells in the current row
                    for (var l = 0; l < rightAlignIndexes.length; l++) {
                      currentIndex = rightAlignIndexes[l];
                      jQuery(rows[k].cells[currentIndex]).addClass("tdRight");
                    }

                    // center align cells in the current row
                    for (var l = 0; l < centerAlignIndexes.length; l++) {
                      currentIndex = centerAlignIndexes[l];
                      jQuery(rows[k].cells[currentIndex]).addClass("tdCenter");
                    }
                  }
                }
              }
            }
          }
        }
    }

  }
}

// var to prevent double click
var submitClicked = false;

/*
Receives:
ID of UL to toggle visibility of (should be of format sub + x)

Gets all other toolSubs and turns them off


*/
function toggleNav(anchorId, menuId) {
  // change the class for the link
  var controlLink = document.getElementById(anchorId);
  var subMenu = document.getElementById(menuId);

  if ( jQuery('#' + anchorId).hasClass("toolOpened") ) { // CG-5353 replace lib.js with jQuery
  // if (hasClass(anchorId, "toolOpened")) {
    // currently expanded, so close
    jQuery(controlLink).removeClass("toolOpened");
    jQuery(controlLink).addClass("toolClosed");

    subMenu.style.display = 'none';
  } else {
    // currently closed, so open the submenu
    jQuery(controlLink).removeClass("toolClosed");
    jQuery(controlLink).addClass("toolOpened");

    subMenu.style.display = '';

    // collapse any other open submenus (only if this is a level one item)
    if ((subMenu.parentNode).parentNode.className == "toolBox") {
      var otherMenus = jQuery('ul.toolSub');
      if (otherMenus.length) {
        for (var i = 0; i < otherMenus.length; i++) {
          if ((otherMenus[i].parentNode).parentNode.className == "toolBox") {
            if (otherMenus[i].id != menuId) {
              // change the class for the corresponding links
              var currControllerLinkId = (otherMenus[i].id).substring(3);
              var currControllerLink = document.getElementById(currControllerLinkId); // hack off the "sub" at the beginning
              if (currControllerLink) {
                if ( jQuery('#' + currControllerLinkId).hasClass("toolOpened") ) {
                  jQuery(currControllerLink).removeClass("toolOpened");
                  jQuery(currControllerLink).addClass("toolClosed");
                }
              }
              otherMenus[i].style.display = 'none';
            }
          }
        }
      }
    }
  }
}

var cgTooltipClickCancel = function(evt) {
  // Prevent tooltip in label from focusing input on click - for mobile tooltip
  evt.preventDefault();
  evt.stopPropagation();
};

function cgBuildTooltips() {
  jQuery('body')
    .on('mouseenter focus', '.cg-tooltip-icon a', function (event) {
      var tooltip = jQuery(this);
      var content = tooltip.attr('title');
      tooltip.attr('oldtitle', content).removeAttr('title');

      tooltip.qtip({
        content: content,
        show: {
          event: event.type,
          ready: true,
        },
        hide: {
          event: 'mouseleave blur'
        },
        overwrite: false,
        position: {
          my: 'bottom left',
          at: 'top center'
        },
        style: {
          classes: 'qtip-rounded qtip-shadow cg-qtip-style'
        }
      }, event);
    })
    .off('click', '.cg-tooltip-icon', cgTooltipClickCancel)
    .on('click', '.cg-tooltip-icon', cgTooltipClickCancel);
  jQuery('.cg-tooltip-icon').on('click', function(evt) {
        // Prevent tooltip in label from focusing input on click - for mobile tooltip
    evt.preventDefault();
    evt.stopPropagation();
  });
}

jQuery(document).ready( function () {
  cgBuildTooltips();
});

// toggling logic for visibility blocks based on radios
// used in links creation in teaser portlets
jQuery(function($) {
  $('.toggler[data-toggler-destination]').on('click', function(){
    var destination = "#" + $(this).attr('data-toggler-destination');
    $(destination).siblings('.toggler-section')
      .removeClass('toggler-section')
      .addClass('toggler-section__unselected');
    $(destination).removeClass('toggler-section__unselected')
      .addClass('toggler-section');
  });
  $('.toggler[data-toggler-destination]:checked').trigger('click');
});

/* Function to submit language toggle form on language selection */
jQuery(function () {
  jQuery('body').on('click', '#languageForm #x_language_code', function () {
    jQuery(this).data('prev', jQuery(this).val());
  })
    .on('change', '#languageForm #x_language_code', function () {
      var element = this;

      if (window.Promise) {
        var promiseResolver;
        var promise = new Promise(function(resolve, reject) {
          promiseResolver = resolve;
        });

        promise.then(function(go) {
          if (go) {
            jQuery('#languageForm').submit();
          } else {
            jQuery(element).val(jQuery(element).data('prev'));
            jQuery(element).data('prev', jQuery(element).val());
          }
        });
        check_unsaved(null, null, promiseResolver);
      } else {
        console.log('Error: check_unsaved requires a Promise, which is not supported here.');
        jQuery('#languageForm').submit();
      }
    });
});


// // Override to stop Ajax requests from getting an extra "_" parameter.
// // Needs to be removed after all other prototype.js is removed.
// function cgrequest(url) {
// this.url = url;
// this.method = this.options.method;
// //var params = Object.clone(this.options.parameters);
// var params = (this.options.parameters) ? Object.clone(this.options.parameters) : "";

// if (!['get', 'post'].include(this.method)) {
//   // simulate other verbs over post
//   params['_method'] = this.method;
//   this.method = 'post';
// }

// this.parameters = params;

// //if (params = Object.toQueryString(params)) {
// if (params = (params) ? Object.toQueryString(params) : "") {

//   // when GET, append parameters to URL
//   if (this.method == 'get')
//     this.url += (this.url.include('?') ? '&' : '?') + params;
// // WL: take out these two lines because PL/SQL doesn't like extra random parameters
// //      else if (/Konqueror|Safari|KHTML/.test(navigator.userAgent))
// //        params += '&_=';
// }

// try {
//   var response = new Ajax.Response(this);
//   if (this.options.onCreate) this.options.onCreate(response);
//   Ajax.Responders.dispatch('onCreate', this, response);

//   this.transport.open(this.method.toUpperCase(), this.url,
//     this.options.asynchronous);

//   if (this.options.asynchronous) this.respondToReadyState.bind(this).defer(1);

//   this.transport.onreadystatechange = this.onStateChange.bind(this);
//   this.setRequestHeaders();

//   this.body = this.method == 'post' ? (this.options.postBody || params) : null;
//   this.transport.send(this.body);

//   /* Force Firefox to handle ready state 4 for synchronous requests */
//   if (!this.options.asynchronous && this.transport.overrideMimeType)
//     this.onStateChange();

// }
// catch (e) {
//   this.dispatchException(e);
// }
// }

// Ajax.Request.prototype.request = cgrequest;

// Find classes of .form-wrapper on page to add classnames for styles
// CG-10789 and CG-9452
jQuery(document).ready(function() {
  var findFormWrappers = jQuery(".form-wrapper");
  if (findFormWrappers[1]) {
    var findFirstFormWrapper = jQuery(findFormWrappers).first();
    var findLastFormWrapper = jQuery(findFormWrappers).last();

    jQuery(findFirstFormWrapper).addClass("js-formWrapperFirst");
    jQuery(findLastFormWrapper).addClass("js-formWrapperLast");
    jQuery(findFormWrappers)
      .not(findFirstFormWrapper)
      .not(findLastFormWrapper)
      .addClass("js-formWrapperSibling");
    if (jQuery(findFirstFormWrapper).has(findLastFormWrapper).length) {
      jQuery(findFirstFormWrapper).addClass("js-formWrapperLastNested");
    }
  } else {
    jQuery(findFormWrappers).addClass("js-formWrapper");
  }
});

jQuery(document).ready(function() {
  jQuery('body').on('click', 'a.js-check-unsaved', function(e) { 
  if (!alert_unsaved())
    e.preventDefault(); 
  }); 
});

// initialize various custom field behaviors
jQuery(document).ready(function() {
  initFileUpload();
  initUserDefined();					
});

// register the onclick for the file and image upload buttons
function initFileUpload() {
  jQuery('input[type="button"].js-file-upload-button').on('click', function() {
    var target = jQuery(this).attr('data-target-url');
    const newWindow = window.open(target, 'uploadWindow', 'toolbar=no,menubar=yes,status=yes,scrollbars=yes,resizable=yes,width=600,height=275');

    // Ensure the new window is focused
    if (newWindow) {
      newWindow.focus();
    }
  });

  // internally, button tag may be used as well
  jQuery('button.js-file-upload-button').on('click', function() {
    var target = jQuery(this).attr('data-target-url');
    const newWindow = window.open(target, 'uploadWindow', 'toolbar=no,menubar=yes,status=yes,scrollbars=yes,resizable=yes,width=600,height=275');

    // Ensure the new window is focused
    if (newWindow) {
      newWindow.focus();
    }
  });  

  jQuery('a.formLinkFileDelete').on('click', function(e) {
    e.preventDefault();
    var target = jQuery(this).attr('href');
    const newWindow = window.open(target, 'uploadWindow', 'toolbar=no,menubar=yes,status=yes,scrollbars=yes,resizable=yes,width=600,height=275');

    // Ensure the new window is focused
    if (newWindow) {
      newWindow.focus();
    }
  });

  // refresh the file list in the parent upon closure of the file upload window
  if (jQuery('body').attr('data-upload-refresh-cf-id')) {
    jQuery(window).on('beforeunload', function() {
        var cfId = jQuery('body').attr('data-upload-refresh-cf-id');
        refresh_uploads(cfId);
    });
  }
}

// register the onclick events for the add/remove buttons for user-defined fields
function initUserDefined() {
  jQuery('input[type="button"].js-user-defined-add').on('click', function() {
    var attributeValue = jQuery(this).attr('data-user-defined-add');
        addToList(attributeValue);
  });
  jQuery('input[type="button"].js-user-defined-remove').on('click', function() {
    var attributeValue = jQuery(this).attr('data-user-defined-remove');
        removeFromList(attributeValue);
  });
}
