// callback function invoked after user requests download of chart
function saveExportProperties(objRtn){
	if (objRtn.statusCode=="1") { // upon success, add iframewhich links to content
	var iframe = '<iframe src="' + objRtn.fileName + '" style="display:none"></iframe>';
	jQuery("body").append(iframe);
	} else {
	 alert("Error while retrieving image. Please contact your Account Coordinator for assistance.");
	}
}

function cgFusionExportCallback(response) {
	var currentPortletId;
	var height = parseInt(response.height);
	var width = parseInt(response.width);
	if (response.DOMId.indexOf('fusionchart-cgChart') === 0) {
		currentPortletId = response.DOMId.substring(19);
	} else if (response.DOMId.indexOf('fusionmap-map') === 0) {
		currentPortletId = response.DOMId.substring(13);
	}
	thisPage._portletList[currentPortletId]._content = (response.statusCode == "1") ? ('<img src="http://localhost' + response.fileName + '" width="' +
			(thisPage._portletList[currentPortletId]._width * 0.9) + 'in" height="' + ((height/width) * (thisPage._portletList[currentPortletId]._width * 0.9)) + 'in" />') :
			'Error saving chart: ' + response.statusMessage;
	thisPage.increaseNumExportedCharts.apply(thisPage);
	thisPage.retrieveOutput.apply(thisPage);
}

var thisPage;

/*

generated by portal page

thisPage.setTitle();
thisPage.setUser();
thisPage.setDate();
*/

var Portal = function() {
	this._portletList = {};
	this._css = '';
	this._title = '';
	this._user = '';
	this._date = '';
	this._numPortlets = 0;
	this._numCharts = 0;
	this._numExportedCharts = 0;
	this._numReadyForExport = 0;
	this._modaalInitialized = false;

	this.initialize();
};
Portal.prototype.initialize = function() {
	//this._portletList = new Object();
};

// Lightbox has been migrated to "Modaal" http://www.humaan.com/modaal/
// Portal.prototype.initializeLightbox = function() {
// 	if (!this._lightboxInitialized) {
// 		// prepare the modal lightbox for showing status
// 		addLightboxMarkup();
// 		jQuery('#lightbox').css('height','100px');
// 		jQuery('#lightbox').html('<div style="text-align: center;"><div style="padding-top: 2em; padding-bottom: 0.5em;">Generating output</div><img src="/includes/images/loading_24x24.gif" alt="Generating output" /></div>');
// 		this._lightboxInitialized = true;
// 	}
// };

Portal.prototype.initializeModaal = function() {
	if (!this._modaalInitialized) {
		// prepare the modal lightbox for showing status
		jQuery('body').append('<div style="display: none;"><a href="#dashboard-modaal" class="dashboard-modaal-trigger">Show Modaal</a><div id="dashboard-modaal">Generating output <img src="/includes/images/loading_24x24.gif" alt="Generating output" /></div>');
		this._modaalInitialized = jQuery('.dashboard-modaal-trigger').modaal();
	}
	jQuery('.dashboard-modaal-trigger').click();
};

Portal.prototype.increaseNumExportedCharts = function() {
	this._numExportedCharts++;
};

Portal.prototype.increaseNumPortlets = function() {
	this._numPortlets++;
};


Portal.prototype.retrieveOutput = function() {
	// if we have the right number of charts, then redirect
	var component = this;
	if (this._numPortlets == this._numReadyForExport) {
		var xml = '<?xml version="1.0" encoding="UTF-8"?>' +
		'<!DOCTYPE pdf PUBLIC "-//big.faceless.org//report" "report-1.1.dtd">' +
		'<pdf>' +
		'<head>' +
		'<meta name="creator" value="CyberGrants"/>' +
		'<meta name="author" value="CyberGrants"/>' +
		'<meta name="title" value="' + this._title + '"/>' +
		((this._css == "") ? "" : '<style type="text/css">' + this._css + '</style>');

		xml = xml + '<macrolist><macro id="rptPageHeader"><div class="header">' +
					'<table width="100%"><tr><td width="75%"><h1 class="title">' + this._title + '</h1></td>' +
					'<td width="25%" align="right"><p class="user">Run By: ' + this._user + '</p>' +
					'<p class="date">Run Date: ' + this._date + '</p></td></tr>'+
					'</table></div></macro></macrolist>' +
					'</head>' +
					'<body size="letter-landscape" header="rptPageHeader" header-height="40">';

		var columns = jQuery('.column');

		// create a "two dimensional" array to hold the portlet contents
		var portletArray = [];
		// make note of the most number of portlets we'll have to support per column
		var maxPortletsPerColumn = 0;
		for (var columnIndex = 0; columnIndex < columns.length; columnIndex++) {
			// create an array of the portlets in this column
			portletArray[columnIndex] = [];
			// loop through portlets in this column
			var portlets = jQuery('.portlet', columns[columnIndex]);
			maxPortletsPerColumn = (portlets.length > maxPortletsPerColumn)? portlets.length : maxPortletsPerColumn;
			for (var portletIndex = 0; portletIndex < portlets.length; portletIndex++) {
				if (this._portletList[portlets[portletIndex].id.substring(2)]) {
					portletArray[columnIndex][portletIndex] = '<div id="' + portlets[portletIndex].id + '" class="column' + (columnIndex + 1) + ' portlet">' +
							this._portletList[portlets[portletIndex].id.substring(2)].getContent() +
							'</div>';
				}
			}
		}

		// now generate the xml by iterating through each "row" of portlets
		for (var i = 0; i < maxPortletsPerColumn; i++) {  // i = row counter
			xml = xml + '<p>';
			for (var j = 0; j < columns.length; j++) {    // j = column counter
				xml = xml + ((portletArray[j][i] != undefined) ? portletArray[j][i] : '');
			}
			xml = xml + '</p>';
		}

		// close doc
		xml = xml + '</body></pdf>';

		xmlhttp = new XMLHttpRequest();
		xmlhttp.open("POST", CyberGrants.constants.RELATIVE_DASHBOARD_PATH + "/PdfStream",true);
		xmlhttp.onreadystatechange = function() {
			if (xmlhttp.readyState == 4) {
				var json = JSON.parse(xmlhttp.responseText);
				// create the iframe that houses the filename in the response
				if (json.statusCode == "1") {
					// http://stackoverflow.com/questions/3499597/javascript-jquery-to-download-file-via-post-with-json-data
					var iframe = '<iframe src="' + CyberGrants.constants.RELATIVE_DASHBOARD_PATH + '/ExportedImage/' + json.fileName + '" style="display:none"></iframe>';
					jQuery("body").append(iframe);
				} else {
					alert("Export failed; please contact your Account Coordinator for assistance");
				}
				// remove modal
				component._modaalInitialized.modaal('close');
			}
		};
		xmlhttp.setRequestHeader("Content-Type", "text/xml");
		try {
			// console.log(xml);
		} catch(e) {}

		xmlhttp.send(xml);

	}
};

Portal.prototype.setCss = function(css) {
	this._css = css;
};

Portal.prototype.setTitle = function(title) {
	this._title = title;
};

Portal.prototype.setUser = function(user) {
	this._user = user;
};

Portal.prototype.setDate = function(dateString) {
	this._date = dateString;
};

Portal.prototype.exportAll = function() {
	try {
		var allReady = true;
		for (var portlet in this._portletList) {
			allReady = allReady&&this._portletList[portlet].isReady();
		}

		if (allReady) {
			for (var portletReady in this._portletList) {
				this._portletList[portletReady].captureContent();
			}

			// put up modal screen
			this.initializeModaal();
		} else {
			alert("Please wait until all charts on the page have finished rendering before attempting export");
		}
	} catch (e) {
		// error occurred, so take down modal, show error message
		// remove modal
		if (this._modaalInitialized) {
			this._modaalInitialized.modaal('close');
		}
		alert(e);
	}
};


/*
Expand/collapse behavior for internal portlets
 */
var Portlet = function(containerId, index) {
	this._portletId = null;
	this._index = null;
	this._containerId = null;
	this._title = "";
	this._width = ""; // width of portlet in inches
	this._content = "";
	this._currentState = "open";
	this._toggleLink = null;
	this._excelLink = null;
	this._exportable = true;

	this.initialize(containerId, index);
};

Portlet.prototype.initialize = function(containerId, index) {
	this._portletId = containerId.substring(2); // assume containerID = "cg" + portlet ID
	this._index = index;

	this._containerId = containerId;
	// initialize the portlet actions
	try {
		this._toggleLink = jQuery('#' + this._containerId).find('.js-toggle__trigger')[0];
		this._currentState = (jQuery(this._toggleLink).hasClass('js-toggle-state--collapsed')) ? 'closed' : 'open';
		this.initToggle();
	} catch (ignore) {}
	try {
		this._title = jQuery('#' + this._containerId).find('h2.section__title')[0].innerHTML;
	} catch (ignore) {}
	try {
		this._excelLink = jQuery('#' + this._containerId).find('.section__title .formImgExcel')[0];

		if (this._excelLink != undefined) {
			// add the onclick events to the expand/collapse link
			jQuery(this._excelLink).on('click', this.exportToExcel.bind(this));
		}
	} catch (ignore) {}
	this.self = this;

	thisPage.increaseNumPortlets();
	// this.getContent.bind(this);
};

Portlet.prototype.initToggle = function(response) {
	// add the onclick events to the expand/collapse link
	jQuery(this._toggleLink).on('click', this.toggleView.bind(this));
};

Portlet.prototype.collapse = function() {      // collapse portlet contents
	// save the collapse state for this portlet
	jQuery.ajax({
		url: 'int_portlet_view.collapse_portlet',
		method: 'GET',
		data: { x_portlet_id: this._portletId, x_ts: new Date().getTime() }
	});

	this._currentState = 'closed';
};

Portlet.prototype.expand = function() {      // expand portlet contents
	// save the expanded state for this portlet
	jQuery.ajax({
		url: 'int_portlet_view.expand_portlet',
		method: 'GET',
		data: { x_portlet_id: this._portletId, x_ts: new Date().getTime() }
	});

	this._currentState = 'open';
};

Portlet.prototype.setWidth = function(width) {
	this._width = width;
};

Portlet.prototype.setExportable = function(exportable) {
	this._exportable = exportable;
};

Portlet.prototype.toggleView = function(event) {
	// if currently open, then close, else expand
	if (this._currentState == 'open') {
		this.collapse();
	} else {
		this.expand();
	}
};

// REWRITTEN AS cgFusionExportCallback
// Portlet.prototype.saveAjaxResponse = function(response) {  // used as a callback for chart capture process completion
// 	// resize the image to occupy 90% of the portlet width in inches
// 	this._content = (response.statusCode == "1") ? ('<img src="http://localhost' + response.fileName + '" width="' +
// 		(this._width * 0.9) + 'in" height="' + ((response.height/response.width) * (this._width * 0.9)) + 'in" />') :
// 		'Error saving chart: ' + response.statusMessage;

// 							//            thisPage.increaseNumReadyForExport();
// 								//          thisPage.retrieveOutput();

// 	thisPage.increaseNumExportedCharts.apply(thisPage); // TODO: is this apply needed?
// 	thisPage.increaseNumReadyForExport.apply(thisPage);
// 	thisPage.retrieveOutput.apply(thisPage);
// };

Portlet.prototype.captureContent = function() {
	if (!this._exportable || this._currentState == 'closed') {
		thisPage.retrieveOutput();
	} else {
		// check to see if this portlet contains a chart
		var cgChart;
		cgChart = getChartFromId('fusionchart-cgChart' + this._portletId);
		if (cgChart == undefined) {
			cgChart = FusionCharts('fusionwidget-widget' + this._portletId);
		}
		if (cgChart == undefined) {
			cgChart = getMapFromId('fusionmap-map' + this._portletId);
		}
		if (cgChart != undefined) {
			if (cgChart.hasRendered && cgChart.hasRendered()) {
				if (cgChart.exportChart) {
					cgChart.exportChart({"exportFormat" : "png", "exportCallback" : "cgFusionExportCallback"});
				}
			} else {
				// throw an exception if the charts aren't ready
				throw "Please wait until all charts on the page have finished rendering before attempting export";
			}
		} else {
			// retrieve contents of table with class .data-table in the portlet
			thisPage.retrieveOutput();
		}
	}
};

Portlet.prototype.exportToExcel = function() {
	var filter = "";
	try {
		filter = '<p class="filter">' + jQuery('#' + this._containerId).find('.filter')[0].innerHTML + '</p>';
	} catch (ignored) {}

	var xml = '<?xml version="1.0" encoding="UTF-8"?><div>' +
						'<table class="data-table">' + jQuery('#' + this._containerId).find('.portlet__content table.data-table')[0].innerHTML + '</table>' +
						'</div>' +
						filter;
	try {
		console.log(xml);
	} catch (e) {}
	xmlhttp = new XMLHttpRequest();
	xmlhttp.open("POST", CyberGrants.constants.RELATIVE_DASHBOARD_PATH + "/ExcelStream", true);
	xmlhttp.onreadystatechange = function() {
		if (xmlhttp.readyState == 4) {
			var json = JSON.parse(xmlhttp.responseText);
			// create the iframe that houses the filename in the response
			if (json.statusCode == "1") {
				var iframe = '<iframe src="' + CyberGrants.constants.RELATIVE_DASHBOARD_PATH + '/ExportedImage/' + json.fileName + '" style="display:none"></iframe>';
				jQuery("body").append(iframe);
			} else {
				alert("Export failed; please contact your Account Coordinator for assistance");
			}
		}
	};
	xmlhttp.setRequestHeader("Content-Type", "text/xml");

	xmlhttp.send(xml);
};

Portlet.prototype.getContent = function() {
	if (!this._exportable || this._currentState == 'closed') {
		return "";
	} else {
		return (this._title.length == 0 ? '' : '<h2 class="portlet-title">' + this._title + '</h2>') +
				'<div class="content">' +
				this._content +
				'</div>' +
				filter;
	}
};

Portlet.prototype.isReady = function() {
	if (!this._exportable) {
		return true;
	} else {
		if (this._currentState == 'closed') {
			return true;
		} else {
			// check to see if this portlet contains a chart
			var cgChart;
			cgChart = getChartFromId('fusionchart-cgChart' + this._portletId);
			if (cgChart == undefined) {
				cgChart = FusionCharts('fusionwidget-widget' + this._portletId); // TODO: fix widgets
			}
			if (cgChart == undefined) {
				cgChart = cgChart = getMapFromId('fusionmap-map' + this._portletId);
			}
			if (cgChart != undefined) {
				if (cgChart.hasRendered && cgChart.hasRendered()) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		}
	}
};
