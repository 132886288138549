/*
  One of the challenging bits of our tech stack is that we would have to
  re-architect a ton of stuff to get the page title to render on load.

  This JS attempts to mitigate that using two techniques:
  1) Make an educated guess on what the page title is.
  2) Update the title for both sighted users and those who use assistive tech.

  This is not perfect, but it is MUCH better than rendering every donor page with
  one common title.  It should pass an a11y audit for WCAG 2.4.2.

  The original spike experimented with an aria-live region.  We decided not to
  implement it because it was unreliable.

  Friendly reminder:
  Screen readers have a keystroke for reading the current window's title
  which is the document.title in a browser's active tab:
  [screen reader key] + T in JAWS and NVDA,
  [screen reader key] + F2 in VoiceOver.
*/

function fixTheTitle() {
  var applicationName = document.title;

  // The timeline is a breadcrumb trail that appears in our wizard-ish pages,
  // such as when someone makes a credit card donation.  This is guaranteed to be
  // in the DOM on page load b/c it's loaded by PL/SQL.
  var currentTimelineStepText =
    document.querySelector('.timeline .timeline-steps.current') &&
    document.querySelector('.timeline .timeline-steps.current').textContent;

  // The React Nav conditionally hides some links in the More button/dropdown,
  // so if one of those links is the "active" link, we have to get it by looking
  // at the data instead of the DOM.  As of this writing, the navJson is
  // guaranteed to be in the DOM on page load b/c the data is loaded by PL/SQL.
  var reactNavJson = document.getElementById('react-nav').dataset.navJson;
  var parsedTabText = JSON.parse(reactNavJson);
  var reactNavActiveLinks = parsedTabText.navigation.filter(function (link) {
    return link.active;
  });

  if (reactNavActiveLinks.length) {
    var activeTabText = reactNavActiveLinks[0].name;
  }

  // This is pulled in async, so there's a race condition here.  Product is cool
  // with this for now because it's better than what we have today.
  // As of this writing, the activeNav text is the same as the <h1> text that
  // will eventually load.
  var myProfileActiveNav =
    document.querySelector('li.activeNav a') &&
    document.querySelector('li.activeNav a').textContent;

  // Final fallback just in case.  Probable race condition, which Product is
  // ok with.
  var h1Text =
    document.querySelector('h1') && document.querySelector('h1').textContent;

  var newTitle = [
    currentTimelineStepText || activeTabText || myProfileActiveNav || h1Text,
    applicationName,
  ]
    .filter(Boolean)
    .join(' | ');

  document.title = newTitle;
}

function e2TitleGuessFunc(event, count) {
  // This part of the application doesn't (yet) support arg defaults.
  if (typeof count === 'undefined') {
    count = 100;
  }
  // To prevent a memory leak if an image never loads.
  // Five seconds felt like "long enough" before giving up seeing as how
  // best practice is to have a page load in under two.
  if (count > 5000) return;

  if (document.readyState === 'complete') {
    // There is a race condition with jQuery.ready.
    // This helps tame it a bit.
    setTimeout(function () {
      return fixTheTitle();
    }, 100);
  } else {
    count += 100;
    setTimeout(function () {
      return e2TitleGuessFunc(event, count);
    }, 100);
  }
}

window.addEventListener('DOMContentLoaded', e2TitleGuessFunc);
