jQuery(document).ready(function() {

  // Take all table headings and add them to the top of each cell in the tbody.
  // Display new cell headings only on mobile, via css.
  
  jQuery('.js-table-collapse').each(function(tableIndex, tableEl) {
    var headings = [];
    jQuery(tableEl).find('thead th').each(function(thIndex, thEl) {
      headings.push(jQuery(thEl).text());
    });
    jQuery(tableEl).find('tbody tr').each(function(trIndex, trEl) {
      jQuery(trEl).find('td').each(function(tdIndex, tdEl) {
        jQuery(tdEl).prepend('<h4 class="mobile-table-heading">' + headings[tdIndex] + '</h4>');
      });
    });
  });

});
