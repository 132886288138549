jQuery(document).ready(function () {
  var yearText = '';
  var virtualLabel = '';

  if (jQuery('[data-portlet-container="upcomingEvents"]').length >= 1) {
    window.cgTranslations.getTranslations().done(function () {
      yearText = window.CyberGrants.l10n.constants.L_YEAR;
      virtualLabel = window.CyberGrants.l10n.constants.L_VIRTUAL;

      renderPortlets();
    });
  }

  function renderPortlets() {
    jQuery('[data-portlet-container="upcomingEvents"]').each(function () {
      var elementId = this.id;
      jQuery.ajax({
        url: jQuery(this).data('portletUrl'),
        // data: { x_ts: new Date().getTime()},
        dataType: 'json',
        type: 'GET',
        success: function (data) {
          var modifiedData = data;
          modifiedData.yearText = yearText;
          modifiedData.virtualLabel = virtualLabel;
          initPortlet(modifiedData, elementId);
        },
      });
    });
  }

  function initPortlet(
    {primaryImages, transactions, virtualLabel, years, yearText},
    portletId,
  ) {
    // parent div used as a temporary container to hold the portlet
    const wrappingDivParent = jQuery('<div>');
    const wrappingDiv = jQuery('<div class="enrolled-events-portlet">');

    if (years.length != 1) {
      // yearText is a translation and should always be there
      const yearSelector = jQuery('<div class="year-selector">').text(
        `${yearText}: `,
      );
      const formSelect = jQuery('<select class="formSelect">');
      years.forEach((year) => {
        const option = jQuery('<option>').text(year).val(year);
        formSelect.append(option);
      });
      yearSelector.append(formSelect);
      wrappingDiv.append(yearSelector);
    }

    const recordSummaryList = jQuery('<div class="record-summary-list">');
    transactions.forEach(
      ({
        ctaLink,
        ctaText,
        description,
        endDate,
        location,
        orgProfileDataUrl,
        organization,
        primaryImageId,
        primaryImageText,
        shareLinks,
        startDate,
        status,
        statusClass,
        time,
        title,
        titleLink,
        virtualFlag,
        year,
      }) => {
        const recordSummary = jQuery(
          '<div class="record-summary record-summary-event" style="display: none;">',
        );
        recordSummary.attr('data-year', year);
        const recordTimeIcon = jQuery('<div class="record-type-icon">')
          .attr('aria-label', primaryImageText)
          .attr('title', primaryImageText)
          .html(primaryImages[primaryImageId]);

        const recordSummaryInnerWrapper = jQuery(
          '<div class="record-summary-inner-wrapper">',
        );
        const recordSummaryMain = jQuery('<div class="record-summary-main">');
        const recordHeader = jQuery('<div class="record-header">');
        const randomDiv = jQuery('<div>');
        const flexDiv = jQuery(
          '<div style="display:flex;flex-direction:row;justify-content: flex-start;align-items: center;">',
        );
        const hyperlink = jQuery('<a>').attr('href', titleLink);
        const heading = jQuery('<h4>').text(title);

        hyperlink.append(heading);

        let virtualDiv = null;
        if (virtualFlag === 'Y') {
          virtualDiv = jQuery(
            '<div style="display: flex;flex-direction: row;justify-content: center;align-items: center; margin-left: 1em; padding: 2px 0;">',
          );
          const svg = jQuery(
            '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16" style="margin: 0 4px;" aria-hidden="true"> <path fill="#666666" d="M0 1v10h16v-10h-16zM15 10h-14v-8h14v8zM10.5 12h-5l-0.5 2-1 1h8l-1-1z"></path></svg>',
          );
          virtualDiv.text(virtualLabel).prepend(svg);
          hyperlink.append(virtualDiv);
        }

        flexDiv.append(hyperlink, virtualDiv);

        const eventHost = jQuery('<div class="event-host">');
        const eventOrganization = jQuery(
          '<span class="event-organization">',
        ).text(organization);

        const locationText = location ? ` - ${location}` : '';

        if (orgProfileDataUrl) {
          eventOrganization.attr('data-url', orgProfileDataUrl);
        } else {
          eventOrganization.addClass('partner');
        }

        eventHost.append(eventOrganization, locationText);

        const enrollmentStatus = jQuery(
          `<div class="enrollment-status enrollment-status-${statusClass}">`,
        ).text(status);

        randomDiv.append(flexDiv, eventHost, enrollmentStatus);

        const cgActionMenu = jQuery('<div class="cg-action-menu">').html(
          shareLinks,
        );

        recordHeader.append(randomDiv, cgActionMenu);

        recordSummaryMain.append(recordHeader);
        if (description) {
          const descriptionDiv = jQuery('<div class="record-body">').text(
            description,
          );
          recordSummaryMain.append(descriptionDiv);
        }

        const recordSummaryExtra = jQuery('<div class="record-summary-extra">');
        const eventDateDiv = jQuery('<div class="event-date">');

        const startDateDiv = jQuery('<div class="start-date">').text(startDate);

        eventDateDiv.append(startDateDiv);

        if (endDate && startDate !== endDate) {
          const endDateDiv = jQuery('<div class="end-date">').text(endDate);
          eventDateDiv.append(endDateDiv);
        }

        const timeDiv = time
          ? jQuery('<div class="event-time">').text(time)
          : null;

        const eventAction = jQuery('<div class="event-action">');
        const eventActionLink = jQuery('<a class="button record-summary-cta">')
          .attr('href', ctaLink)
          .text(ctaText);

        eventAction.append(eventActionLink);

        recordSummaryExtra.append(eventDateDiv, timeDiv, eventAction);

        recordSummaryInnerWrapper.append(recordSummaryMain, recordSummaryExtra);
        recordSummary.append(recordTimeIcon, recordSummaryInnerWrapper);
        recordSummaryList.append(recordSummary);
      },
    );
    wrappingDiv.append(recordSummaryList);
    wrappingDivParent.append(wrappingDiv);

    // html() removes the wrapping div added above.
    const html = wrappingDivParent.html();

    var portletWrapper = jQuery('#' + portletId);
    jQuery('.loading-spinner', '#' + portletId).remove();
    portletWrapper.append(html);

    filterByYear(years[0], portletWrapper);

    if (years.length > 1) {
      jQuery('.year-selector select', portletWrapper).on(
        'change',
        function (event) {
          filterByYear(event.target.value);
        },
      );
    } else {
      jQuery('.year-selector select', portletWrapper).hide();
    }

    /*
      Dear Future Dev,
      The following code lets OrgProfile, a React component, know that
      Handlebars is done compiling.  These lines _must_ remain at bottom of this
      file and be the last thing that's called here.
    */
    var enrolledEventsPortletIsDone = document.createEvent('Event');
    enrolledEventsPortletIsDone.initEvent(
      'enrolledEventsPortletIsDone',
      true,
      true,
    );
    document.dispatchEvent(enrolledEventsPortletIsDone);
  }

  function filterByYear(year, wrapper) {
    jQuery('.record-summary-event', wrapper).hide();
    jQuery('[data-year=' + year + ']', wrapper).show();
  }
});
