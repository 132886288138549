function readScanFrame(parentForm)

{
	// check for result pane
	if (window.frames['scan_results']) {
		// now check for acceptance fields
		if (window.frames['scan_results'].document.scanForm) {
			parentForm.x_scan_summary_id.value = window.frames['scan_results'].document.scanForm.x_scan_summary_id.value;
			parentForm.x_accept_initials.value = window.frames['scan_results'].document.scanForm.x_accept_initials.value;
		}

	}

}

function postScanParentFrame(scanForm) 
{
alert("in postScanParentFrame");
	var parentForm;
	var saveButton;
	if (top.window.frames['workarea']) {
		if (top.window.frames['workarea'].document.reqForm) {
			parentForm = top.window.frames['workarea'].document.reqForm;
			for (i = 0 ; i < parentForm.x_action.length ; i++) {
				if (parentForm.x_action[i].value == 'Save All') {
					saveButton = parentForm.x_action[i];
					break;
				}
			}
		} else if (top.window.frames['workarea'].document.giftForm) {
			parentForm = top.window.frames['workarea'].document.giftForm;
			if (parentForm.x_button.length) {
				for (i = 0 ; i < parentForm.x_button.length ; i++) {
					if (parentForm.x_button[i].value == 'Save') {
						saveButton = parentForm.x_button[i];
						break;
					}
				}
			} else {
				saveButton = parentForm.x_button;
			}
		} else if (top.window.frames['workarea'].document.nomForm) {
			parentForm = top.window.frames['workarea'].document.nomForm;
			if (parentForm.x_button.length) {
				for (i = 0 ; i < parentForm.x_button.length ; i++) {
					if (parentForm.x_button[i].value == 'Save') {
						saveButton = parentForm.x_button[i];
						break;
					}
				}
			} else {
				saveButton = parentForm.x_button;
			}
		}
		parentForm.x_scan_summary_id.value = scanForm.x_scan_summary_id.value;
		parentForm.x_accept_initials.value = scanForm.x_accept_initials.value;
		saveButton.click();
	}
	return false; // make sure this child form does not post
}

