jQuery(document).ready(function() {
	jQuery("textarea.js-textarea-spec-char-replace").change(function(e) {
		var specCharFunc = jQuery(e.currentTarget).data('spec-char-replace'); 
		window[specCharFunc](e.currentTarget); 
	});
});
document.addEventListener("DOMContentLoaded", function() {
    // Ensure the DOM is fully loaded

    // Select all textareas with the class js-textarea-word-counter
    var textareas = document.querySelectorAll("textarea.js-textarea-word-counter");
    
    textareas.forEach(function(textarea) {
        textarea.addEventListener("change", function() {
            var maxlength = textarea.getAttribute("data-maxlength");
            if (maxlength && !checkLength(textarea, maxlength)) {
                textarea.focus();
            }
        });
    });

  document
    .querySelectorAll('.js-selectAllCancelForm')
    .forEach((selectAllButton) => {
      selectAllButton.addEventListener('click', function (e) {
        e.preventDefault();
        selectAll(document.cancelForm.x_cancel_eg_request_id);
      });
    });

  document
    .querySelectorAll('.js-selectNoneCancelForm')
    .forEach((selectNoneButton) => {
      selectNoneButton.addEventListener('click', function (e) {
        e.preventDefault();
        selectNone(document.cancelForm.x_cancel_eg_request_id);
      });
    });

  document.querySelectorAll('#newfriendfamily').forEach((newFriendFamily) => {
    newFriendFamily.addEventListener('click', function (e) {
      const element = document.getElementById('ffformdiv');

      if (element) {
        element.remove();
      }
    });
  });
});
