// Read more or less toggler taken from cg-ao2.js, with modifications.
var CGInitViewMoreLess = function() {
	window.cgTranslations.getTranslations().done(function() {
		var moreTxt = window.CyberGrants.l10n.constants.I_VIEW_MORE,
				lessTxt = window.CyberGrants.l10n.constants.I_VIEW_LESS,
				resizeId,
				moreLessLineLimit,
				moreLessSelectors = '.table__comments, .rewards__copy--container'; // comma-separated classes this will operate upon

			jQuery(window).resize(function() {
				clearTimeout(resizeId);
				resizeId = setTimeout(function() {
					setTimeout(function() {
						resizeToggleExpandCollapseClass() //runs second after 1100ms
					},100)
					updateOverflow() //runs first, after 1000ms
				},200)
			});
			updateOverflow();
			jQuery('body').on('click', '.js-more-less', moreLess);

		function updateOverflow() {
			/* Default to 2 for non-mobile, 4 for mobile */
			moreLessLineLimit = jQuery(window).width() / parseFloat(jQuery("html").css("font-size")) < 40 ? 4 : 2;
			checkOverflow(moreLessSelectors);
		}

		/* returns the desired height of the more/less-enabled element */
		function getCommentHeight(el, current) {
			var lineHeight = parseInt(el.css('line-height'), 10);
			var limit = el.attr('data-show-lines') || moreLessLineLimit; // allow per-element override

			if (current === moreTxt) {
				var max = el.css('max-height');
				return max === 'none' ? el.get(0).scrollHeight + 'px' : max;
			} else {
				return lineHeight * limit + 'px';
			}
		}

		/*
				Check whether the element is tall enough to be given more/less functionality
				The selector element may be given a data-show-lines value to override default 2/4 line limit
		*/
		function checkOverflow(selector) {
			var moreLink = '<span class="js-more-less"><span class="dots">...</span><a class="more-less-link" href="#">' + moreTxt + '</a></span>',
					lineHeight = parseInt(jQuery(selector).css('line-height'), 10);

			jQuery(selector).each(function() {
				var lines = Math.floor(this.scrollHeight / lineHeight),
						limit = jQuery(this).attr('data-text-lines') || moreLessLineLimit;

				if (lines > limit) {
					if (!jQuery(this).find('.js-more-less').length) {
						jQuery(this).append(moreLink);
					}
					jQuery(this).css({'height' : lineHeight * limit + 'px'});
				}
				var passThis = jQuery(this).find('.js-more-less');
				toggleExpandCollapseClass(passThis);
			});
		}

		/*
			1) animate the height of the overflowing element to either full height or limited height
				NOTE: full height may not be what's wanted. Perhaps container's max height || scroll height?
			2) toggle the more/less link text
			3) show/hide dots span inside it, depending.
		*/
		function moreLess(e) {
			e.preventDefault();
			var switchText = jQuery(this).find('a.more-less-link').text() === moreTxt ? lessTxt : moreTxt,
					el = jQuery(this).closest(moreLessSelectors),
					h = getCommentHeight(jQuery(el), jQuery(this).find('a.more-less-link').text());
			el.animate({
				height: h
			}, 500, function() {
				// after the height changes
				jQuery(this).find('a.more-less-link').text(switchText)
							.end()
							.find('.dots').toggle(switchText === moreTxt);
							toggleExpandCollapseClass(jQuery(this));
			});
		}

		function toggleExpandCollapseClass() {
			jQuery(moreLessSelectors).each(function() {
				switchText = jQuery(this).find('a.more-less-link').text();
				if (switchText === moreTxt) {
					jQuery(this).closest(moreLessSelectors).addClass('js-more-less--collapsed').removeClass('js-more-less--expanded');
				} else if (switchText === lessTxt) {
					jQuery(this).closest(moreLessSelectors).addClass('js-more-less--expanded').removeClass('js-more-less--collapsed');
				}
			});
		}
		function resizeToggleExpandCollapseClass() {
			jQuery(moreLessSelectors).each(function() {
				switchText = jQuery(this).find('a.more-less-link').text();
				if (switchText === lessTxt) {
					jQuery(this).find('a.more-less-link').text(moreTxt);
					jQuery(this).find('.dots').toggle();
					jQuery(this).closest(moreLessSelectors).addClass('js-more-less--collapsed').removeClass('js-more-less--expanded');
				} else if (switchText === lessTxt) {
					jQuery(this).closest(moreLessSelectors).removeClass('js-more-less--expanded').addClass('js-more-less--collapsed');
				}
			});
		}
	});
}

jQuery(document).ready(function() {
	CGInitViewMoreLess();
});
