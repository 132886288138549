﻿jQuery(document).ready(function() {
  // Get language to use.
  var lang = jQuery('body').attr('lang');

  // Get all fields that should use the datepicker.
  var datePickerInputs = jQuery('.js-datepicker-input');

  // Set up basic configuration.
  var i18nConfig = {
    previousMonth : '<',
    nextMonth     : '>',
    months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
    weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
    weekdaysShort : ['Su','M','Tu','W','Th','F','Sa']
  };

  // Modify configuration based on language.
  switch(lang) {
    case 'en':
      i18nConfig.previousMonth = 'Previous Month';
      i18nConfig.nextMonth = 'Next Month';
      break;
    case 'tr':
      i18nConfig.months = [ 'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık' ],
      i18nConfig.weekdays = [ 'Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi' ];
      i18nConfig.weekdaysShort = [ 'Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cts' ];
      break;
    case 'zh':
      i18nConfig.months = [ '一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月' ],
      i18nConfig.weekdays = [ '日', '一', '二', '三', '四', '五', '六' ];
      i18nConfig.weekdaysShort = [ '日', '一', '二', '三', '四', '五', '六' ];
      break;
    // case 'zh':
    //   i18nConfig.months = [ '1&#32;&#26376;', '2&#32;&#26376;', '3&#32;&#26376;', '4&#32;&#26376;', '5&#32;&#26376;', '6&#32;&#26376;', '7&#32;&#26376;', '8&#32;&#26376;', '9&#32;&#26376;', '10&#26376;', '11&#26376;', '12&#26376;'];
    //   i18nConfig.weekdays = [ '&#21608;&#26085;', '&#21608;&#19968;', '&#21608;&#20108;', '&#21608;&#19977;', '&#21608;&#22235;', '&#21608;&#20116;', '&#21608;&#20845;' ];
    //   i18nConfig.weekdaysShort = [ '&#21608;&#26085;', '&#21608;&#19968;', '&#21608;&#20108;', '&#21608;&#19977;', '&#21608;&#22235;', '&#21608;&#20116;', '&#21608;&#20845;' ];
    //   break;
    case 'fr':
      i18nConfig.previousMonth = 'Préc.';
      i18nConfig.nextMonth = 'Suivant';
      i18nConfig.months = [ 'Janvier', 'F&#233;vrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'D&#233;cembre' ];
      i18nConfig.weekdays = [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ];
      i18nConfig.weekdaysShort = [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ];
      break;
    case 'es':
      i18nConfig.months = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
      i18nConfig.weekdays = [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'S&#224;b' ];
      i18nConfig.weekdaysShort = [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'S&#224;b' ];
      break;
    case 'it':
      i18nConfig.months = [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ];
      i18nConfig.weekdays = [ 'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab' ];
      i18nConfig.weekdaysShort = [ 'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab' ];
      break;
    case 'de':
      i18nConfig.months = [ 'Januar', 'Februar', 'M&#228;rz', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ];
      i18nConfig.weekdays = [ 'Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam' ];
      i18nConfig.weekdaysShort = [ 'Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam' ];
      break;
    case 'pt':
      i18nConfig.months = [ 'Janeiro', 'Fevereiro', 'Mar&#231;o', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro' ];
      i18nConfig.weekdays = [ 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'S&#225;' ];
      i18nConfig.weekdaysShort = [ 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'S&#225;' ];
      break;
    case 'hu':
      i18nConfig.months = [ 'Janu&#225;r', 'Febru&#225;r', 'M&#225;rcius', '&#193;prilis', 'M&#225;jus', 'J&#250;nius', 'J&#250;lius', 'Augusztus', 'Szeptember', 'Okt&#243;ber', 'November', 'December' ];
      i18nConfig.weekdays = [ 'Vas', 'H&#233;', 'Ke', 'Sze', 'Cs&#252;', 'P&#233;', 'Szo' ];
      i18nConfig.weekdaysShort = [ 'Vas', 'H&#233;', 'Ke', 'Sze', 'Cs&#252;', 'P&#233;', 'Szo' ];
      break;
    case 'lt':
      i18nConfig.months = [ 'Sausis', 'Vasaris', 'Kovas', 'Balandis', 'Gegu&#382;&#279;', 'Bir&#382;elis', 'Liepa', 'Rugj&#363;tis', 'Rus&#279;jis', 'Spalis', 'Lapkritis', 'Gruodis' ];
      i18nConfig.weekdays = [ 'Sek', 'Pir', 'Ant', 'Tre', 'Ket', 'Pen', '&Scaron;e&scaron;' ];
      i18nConfig.weekdaysShort = [ 'Sek', 'Pir', 'Ant', 'Tre', 'Ket', 'Pen', '&Scaron;e&scaron;' ];
      break;
    case 'nl':
      i18nConfig.months = [ 'januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december' ];
      i18nConfig.weekdays = [ 'zo', 'ma', 'di', 'wo', 'do', 'vr', 'za' ];
      i18nConfig.weekdaysShort = [ 'zo', 'ma', 'di', 'wo', 'do', 'vr', 'za' ];
      break;
    case 'dk':
      i18nConfig.months = [ 'Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December' ];
      i18nConfig.weekdays = [ 'S&#248;n', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L&#248;r' ];
      i18nConfig.weekdaysShort = [ 'S&#248;n', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L&#248;r' ];
      break;
    case 'no':
      i18nConfig.months = [ 'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember' ];
      i18nConfig.weekdays = [ 'Sun', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L&#248;r' ];
      i18nConfig.weekdaysShort = [ 'Sun', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L&#248;r' ];
      break;
    case 'lv':
      i18nConfig.months = [ 'Janv&#257;ris', 'Febru&#257;ris', 'Marts', 'Apr&#299;lis', 'Maijs', 'J&#363;nijs', 'J&#363;lijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decemberis' ];
      i18nConfig.weekdays = [ 'Sv', 'P', 'O', 'T', 'C', 'Pk', 'S' ];
      i18nConfig.weekdaysShort = [ 'Sv', 'P', 'O', 'T', 'C', 'Pk', 'S' ];
      break;
    case 'ja':
      i18nConfig.months = [ '1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月' ];
      i18nConfig.weekdays = [ '日','月','火','水','木','金','土'];
      i18nConfig.weekdaysShort = [ '日','月','火','水','木','金','土'];
      break;
    case 'fi':
      i18nConfig.months = [ 'Tammikuu', 'Helmikuu', 'Maalisokuu', 'Huhtikuu', 'Toukokuu', 'Kes&#228;kuu', 'Hein&#228;kuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu' ];
      i18nConfig.weekdays = [ 'Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La' ];
      i18nConfig.weekdaysShort = [ 'Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La' ];
      break;
    case 'ro':
      i18nConfig.months = [ 'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Junie', 'Julie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie' ];
      i18nConfig.weekdays = [ 'Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă' ];
      i18nConfig.weekdaysShort = [ 'Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa' ];
      break;
    case 'sv':
      i18nConfig.months = [ 'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December' ];
      i18nConfig.weekdays = [ 'S&#246;n', 'M&#229;n', 'Tis', 'Ons', 'Tor', 'Fre', 'L&#246;r' ];
      i18nConfig.weekdaysShort = [ 'S&#246;n', 'M&#229;n', 'Tis', 'Ons', 'Tor', 'Fre', 'L&#246;r' ];
      break;
    case 'pl':
      i18nConfig.months = [ 'Stycze\u0144', 'Luty', 'Marzec', 'Kwiecie\u0144', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpie\u0144', 'Wrzesie\u0144', 'Pa\u017adziernik', 'Listopad', 'Grudzie\u0144'];
      i18nConfig.weekdays = ['Nie', 'Pon', 'Wt', '\u015ar', 'Czw', 'Pt', 'Sob'];
      i18nConfig.weekdaysShort = ['Nie', 'Pon', 'Wt', '\u015ar', 'Czw', 'Pt', 'Sob'];
      break;
    case 'ru':
      i18nConfig.months = [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь' ];
      i18nConfig.weekdays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб' ];
      i18nConfig.weekdaysShort = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб' ];
      break;
    case 'ko':
      i18nConfig.previousMonth = '이전';
      i18nConfig.nextMonth = '다음';
      i18nConfig.months = [ '1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'];
      i18nConfig.weekdays = ['토','일','월','화','수','목','금'];
      i18nConfig.weekdaysShort = ['토','일','월','화','수','목','금'];
      break;
    case 'da':
      i18nConfig.months = ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'];
      i18nConfig.weekdays = ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag','lørdag'];
      i18nConfig.weekdaysShort = ['s&#248;n', 'man', 'tir', 'ons', 'tor', 'fre','l&#248;r'];
      break;
		case 'pl':
			i18nConfig.months =['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj' ,'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
			i18nConfig.weekdays = ['Niedziela','Poniedziałek','Wtorek','Środa','Czwartek','Piątek','Sobota'];
			i18nConfig.weekdaysShort = ['Nd', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt','Sob'];
			break;
		case 'uk':
			i18nConfig.months =['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень' ,'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
			i18nConfig.weekdays = ['Неділя','Понеділок','Вівторок','Середа','Четвер','П’‎ятниця','Субота'];
			i18nConfig.weekdaysShort = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт','Сб'];
			break;
		case 'hi':
			i18nConfig.months =['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई' ,'जून', 'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'];
			i18nConfig.weekdays = ['रविवार','सोमवार','मंगलवार','बुधवार','गुरुवार','शुक्रवार','शनिवार'];
			i18nConfig.weekdaysShort = ['रवि', 'सोम', 'मंगल', 'बुध', 'वृहस्पति', 'शुक्र','शनि'];
			break;
		case 'th':
			i18nConfig.months =['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม' ,'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
			i18nConfig.weekdays = ['อาทิตย์','จันทร์','อังคาร','พุธ','พฤหัสบดี','ศุกร์','เสาร์'];
			i18nConfig.weekdaysShort = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.','ส.'];
			break;
		case 'vi':
			i18nConfig.months =['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5' ,'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
			i18nConfig.weekdays = ['Chủ Nhật','Thứ Hai','Thứ Ba','Thứ Tư','Thứ Năm','Thứ Sáu','Thứ Bảy'];
			i18nConfig.weekdaysShort = ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
			break;
    default:
      break;
  }

  // Initalize datepicker on each input field.
  jQuery.each(datePickerInputs, function(index, input) {
    initDatepicker(input);
  });

  jQuery('body').on('focus touchend', '.js-datepicker-input', function(event) {
    /*
    Dear Future Dev,
    The next few lines attempt to prevent the calendar from drawing offscreen
    Pikaday anchors its calendar to the field top/bottom and left/right
    It has a known issue of inaccurate anchoring when the calendar is in a
    scrollable container, like we have.  :)
    */
    var $thisInput = jQuery(this);
    var $parentTD = $thisInput.parent();
    var $allTDs = $thisInput.closest('tr').children();

    var isFirstHalf = ($allTDs.index($parentTD) / $allTDs.length) <= 0.5;
    if (isFirstHalf) {
      jQuery('.table-wrapper').scrollLeft(70);
    } else {
      jQuery('.table-wrapper').scrollLeft(10);
    }

    if (! event.currentTarget.cgDatepickerInitalized ) {
      initDatepicker(event.currentTarget);
    }
  });

  function initDatepicker(input) {
    // don't let initalizing datepickers change the "formChanged" status.
    var previousFormChanged = window.formChanged;

    var myFormat = jQuery(input).data('dateFormat');
    var options = {
      field: input,
      format: myFormat,
      i18n: i18nConfig
    };

    if (jQuery('#ccForm #CG\\.cc_frequency').length) {
      // this field is used only for recurring credit cards.  We want to limit
      // users to selecting start dates in the future for this feature.
      // the start date field is conditionally hidden, so we need to look for a sibling field
      options.minDate = new Date();
    }

    if (jQuery('#CG\\.min_start_date').length && input.id === 'CG.cc_start_date') {
      if (jQuery('#CG\\.min_start_date').data('minStartDate')) {
        options.minDate = new Date(jQuery('#CG\\.min_start_date').data('minStartDate'));
      }
    }

    if (jQuery('#CG\\.cc_end_date').length && input.id == 'CG.cc_end_date') {
      // the end date field is added and removed often, so it's safe to apply settings here
      var startDateValue = jQuery('#CG\\.cc_start_date').val();
      var minEndDateValue = moment(startDateValue, myFormat).add(1, 'days').format(myFormat);
      options.minDate = new Date(minEndDateValue);
      options.defaultDate = new Date(minEndDateValue);
    }

    if (jQuery('#CG\\.max_end_date').length) {
      // if there is a configured maximum end date, then neither the start nor end date should be allowed
      // to pick a date beyond that date
      var maxEndDate = jQuery('#CG\\.max_end_date').data('maxEndDate');

      // start date field
      if (input.id == 'CG.cc_start_date') {
        var startDateFieldMax = moment(maxEndDate, myFormat).subtract(1, 'days').format(myFormat);
        options.maxDate = new Date(startDateFieldMax);
      }
      // end date field
      if (input.id == 'CG.cc_end_date') {
        options.maxDate = new Date(maxEndDate);
      }
    }

    var picker = new Pikaday(options);
    input.cgDatepickerInitalized = true;

    if (previousFormChanged === false) {
      window.formChanged = false;
    }
  }

  jQuery('#CG\\.cc_start_date').on('blur', function () {
    var endDateField = jQuery('#CG\\.cc_end_date');
    var endDateClone = endDateField.clone();
    var endDateParent = endDateField.parent();

    // Dear Future Dev - if you don't remove the whole element, then you get a stack overflow
    endDateField.remove();
    endDateParent.prepend(endDateClone);
  });
});
