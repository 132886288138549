jQuery(document).ready(function() {
  // load dynamic subnav + status of each section on the profile page
  if (jQuery('.donorUi .EPcontainer').length >= 1) {
    var $secondaryNav = jQuery('#profileSecondaryNav');
    $secondaryNav.append('<div class="loading-spinner"></div>');

    function updateSubNav() {
      if (jQuery('#profileSecondaryNav').length >= 1) {
        jQuery.getJSON($secondaryNav.attr('data-profile-nav-url'),
        function(data) {
          var subnavMarkup = '<ul>';
          jQuery.each(data.sections, function(index, value) {
            data.sections[index].status += (data.sections[index].url.indexOf($secondaryNav.attr('data-section')) > 0)? ' activeNav' : '';
            subnavMarkup += '<li class="' + data.sections[index].status + '"><a href="' + data.sections[index].url + '">' + data.sections[index].title + '</a></li>';
          });
          subnavMarkup += '</ul>';
          $secondaryNav.html(subnavMarkup);          
        });
      }
    }
    updateSubNav();

    function scrollToError() {
      jQuery('html, body').animate({
        scrollTop: jQuery('.alert-box').offset().top
      }, 500);
    }

    jQuery('.EPcontent > form').on('submit', function(event) {
      event.preventDefault();
      var $form = jQuery(this);
      $form.find('.alert-box').remove();
      $form.find(':input[type=submit]').prop('disabled', true);
      jQuery.ajax({
        type: 'POST',
        url: $form.attr('action'),
        dataType: 'json',
        data: $form.serialize()
      }).fail(function() {
        window.cgTranslations.getTranslations().done(function() {
          var errorMsg = window.CyberGrants.l10n.constants.E_AN_UNEXPECTED_ERROR_OCCURRED;
          $form.find('h1').after('<div class="alert-box alert" role="alert"><h5>' + errorMsg + '<h5></div>');
        });
      }).done(function (data) {
        if (!data.errors) {
          if (data.goto_url.length > 0) {
            window.location.href = data.goto_url;
          } else {
            window.cgTranslations.getTranslations().done(function() {
              var successTitle = window.CyberGrants.l10n.constants.L_CONGRATULATIONS,
                  successMarkup = '<h5>' + successTitle + '</h5><p>' + data.success + '</p>';
              $form.find('h1').after('<div class="alert-box success">' + successMarkup + '</div>');
              scrollToError();
            });
            formChanged = false;
          }
        } else {
          window.cgTranslations.getTranslations().done(function() {
            var errorTitle = window.CyberGrants.l10n.constants.L_ERROR_PLEASE_CORRECT,
                errorMarkup = '<h5>' + errorTitle + '</h5><ul class="errorTextList no-bullet">';
            jQuery.each(data.errors, function(index, errorMsg) {
              errorMarkup += errorMsg;
            });
            errorMarkup += '</ul>';
            $form.find('h1').after('<div class="alert-box alert" role="alert">' + errorMarkup + '</div>');
            scrollToError();
          });
        }
        updateSubNav();
      }).always(function(data) {
        $form.find(':input[type=submit]').prop('disabled', false);
      });
    });
  }  
});
