
// USAGE:
// ========================
// window.cgTranslations.getTranslations().done(function() {
//   var featuredEventText = window.CyberGrants.l10n.constants.L_FEATURED_EVENT;
//   // etc...
// });
//

function CyberGrantsTranslations() {
  this.promise = null;
}

CyberGrantsTranslations.prototype.getTranslations = function() {
  if (this.promise === null) {
    var langVersion = jQuery('body').data('langVersion');
    this.promise = jQuery.get(
        window.CyberGrants.constants.STATIC_CONTENT_URL + '/includes/translations/' + langVersion + '.json',
        function(l10nData) {
          // Dear Future Dev,
          // As we set up multiple environments, it's pretty common to send the
          // translations over the wire with a MIME type of application/json.
          // Because reasons, we currently send them as text in some environments.
          // This quick check may save hours of stress when time is short.
          // If the MIME type has changed, you can ditch this first IF condition.
          if (typeof l10nData === 'string') {
            // CG-9067 newline characters are escaped by the json parser and need
            // to be returned to their original form
            var transformedData = l10nData.split('\\\\n').join('\\n');
            window.CyberGrants.l10n.constants = JSON.parse(transformedData);
          } else {
            window.CyberGrants.l10n.constants = l10nData;
          }
        });
  }
  return this.promise;
};

window.cgTranslations = new CyberGrantsTranslations();
