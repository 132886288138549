jQuery(document).ready(function() {
  
  jQuery('body').on('keyup', 'textarea[class*="max"]', function(event) {
    cgCountCharacters(jQuery(event.currentTarget));
  });

  cgInitCharacterCounters();

});

function cgCountCharacters(element) {
  var maxes = element.attr('class').match(/(?:\bmax)(\d+\b)/);
  var max = maxes ? maxes[1] : null;
  var msg_wrapper = jQuery('#formRow' + element.attr('id') + ', .formItem' + element.attr('id')); // #formRow is internal admin, .formItem is E2
  var msg_elem = msg_wrapper.find('.maxLength').get(0);
  var newlineFixedString = element.val().replace(/(?:\r\n|\r|\n)/g, '\r\n');
  var len = newlineFixedString.length;
  var chars_remaining = max - len;
  var new_msg;
  if (len == 0) {
    new_msg = CyberGrants.l10n.characterMaximum(CyberGrants.l10n.language(), max.toString());
  } else if (len <= max) {
    new_msg = CyberGrants.l10n.charactersRemaining(CyberGrants.l10n.language(), chars_remaining.toString());
  } else if (len > max) {
    new_msg = CyberGrants.l10n.charactersOver(CyberGrants.l10n.language(), chars_remaining.toString().substr(1));
  };

  // when the length is zero the message already contains the paren.
  new_msg = len ? '(' + new_msg + ')' : new_msg;
  jQuery(msg_elem).text(new_msg);
}

function cgInitCharacterCounters() {
  jQuery('textarea[class*="max"]').each(function() {
    cgCountCharacters(jQuery(this));
  });
}