/*
Takes all form elements in the form indicated by sourceForm, creates them in the form indicated by destForm before submitting destForm.

Note that destForm's onsubmit handlers will NOT be fired! (change this?)

Only special case is that if the form element already exists in the destForm as a hidden field, and only one exists, 
the below will update the existing value.
*/
function transferForm(sourceForm, destForm)
{
	if (destForm) {
		var sourceElements;
		var currElement;
		var currName;

		sourceElements = sourceForm.getElementsByTagName("SELECT");

		if (sourceElements) {
			// loop through all the input elements
			for (var i = 0; i < sourceElements.length; i++) {
				currElement = sourceElements[i];
				currName = currElement.getAttribute("name");
				currValue = currElement.options[currElement.selectedIndex].value;

				// check for a hidden input for which we might need to re-assign value	
				var formField;
				var existingFields = getElementsByName(currName, "INPUT", destForm);
				if (existingFields && existingFields.length == 1 && existingFields[0].type == "hidden") {
//					alert("field already exists; re-using");
					formField = existingFields[0];
				} else {
//					alert("field doesn't already exist; creating new");
					// create the select value as a hidden form element
					formField = document.createElement("input");
					formField.setAttribute("type", "hidden");
				}

				// assign attributes for hidden input field
				if (document.getElementById) {
					formField.setAttribute("name", currName);
					formField.setAttribute("value", currValue);
					destForm.appendChild(formField);
//					alert("transferred " + currName + "(" + currValue + ")");
				}
			}

		}

		sourceElements = sourceForm.getElementsByTagName("TEXTAREA");

		if (sourceElements) {
			// loop through all the textarea elements
			for (var i = 0; i < sourceElements.length; i++) {
				currElement = sourceElements[i];
				currName = currElement.getAttribute("name");

				// check for a hidden input for which we might need to re-assign value	
				var formField;
				var existingFields = getElementsByName(currName, "INPUT", destForm);

				if (existingFields && existingFields.length == 1 && existingFields[0].type == "hidden") {
//					alert("field already exists; re-using");
					formField = existingFields[0];
				} else {
//					alert("field doesn't already exist; creating new");
					// create the textarea value as a hidden form element
					formField = document.createElement("input");
				}

				// assign attributes for hidden input field
				if (document.getElementById) {
					formField.setAttribute("type", "hidden");
					formField.setAttribute("name", currName);
					formField.setAttribute("value", currElement.value);
					destForm.appendChild(formField);
//					alert("transferred " + currName + "(" + currElement.value + ")");
				}
			}

		}

		sourceElements = sourceForm.getElementsByTagName("INPUT");

		if (sourceElements) {
			// loop through all the input elements
			for (var i = 0; i < sourceElements.length; i++) {
				currElement = sourceElements[i];
				currName = currElement.getAttribute("name");
//				alert("name is " + currName + "; type is " + currElement.getAttribute("type"));

				// create the input value as a hidden form element (need special handling for submit?)
				var formField = document.createElement("input");

				// assign attributes for hidden input field
				if (document.getElementById) {
					formField.setAttribute("type", "hidden");
					formField.setAttribute("name", currName);
					formField.setAttribute("value", currElement.getAttribute("value"));
					destForm.appendChild(formField);
//					alert("transferred " + currName + "(" + currElement.getAttribute("value") + ")");
				}
			}
		}

		// submit the parent frame; note that event handlers won't fire
		destForm.submit();
//		alert(destForm.action);
	}
}
