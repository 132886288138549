(function ($) { // IIFE (immediately invoked function expression)
  $(function() { // On document.ready (replaced  deprecated load function)
    // timeout is added because even after everything has "loaded" the rendering speed
    // is slightly inconsistent and sometimes the appearance of the scrollbar throws things off.
    setTimeout(function() {
      initMobileMenu();
      adjustNavigationSpacing();
    }, 100);
  });

  // Check if the navigation is wrapping to multiple lines.
  // If only one item wraps, increase item margin to 2em.
  // If two wrap, increase item margin to 1em.
  function adjustNavigationSpacing() {
    var wrapCount = 0;
    var navList = $("#cgnav ul");
    var nav = navList.find("li");
    var firstTop;
    if (nav.length) {
      firstTop = nav.first().position().top;
      nav.each(function(index, element) {
        if ($(element).position().top != firstTop) {
          wrapCount++;
        }
      });
      if (wrapCount === 2) {
        nav.css({ "margin-left": "1em", "margin-right": "1em" });
      }
      if (wrapCount === 1) {
        nav.css({ "margin-left": "2em", "margin-right": "2em" });
      }
    }
  }

  // On mobile, collapse the menu and display a "Menu" icon.
  function initMobileMenu() {
    var navWrapper = $("#cgnav");
    navWrapper.before('<div class="menu-toggler">Menu</div>');
    var menuToggler = $(".menu-toggler");
    menuToggler.click(function() {
      navWrapper.slideToggle();
    });
  }
})(jQuery);

jQuery( document ).ready(function() {
	// Function will set a timeout, and listen for a beforeunload. If one is fired, browser will follow that history URL. If none are fired, window will close.
	var goBackOrClose = function goBackOrClose() {
		var noBackHistoryFound = true;
		window.addEventListener("beforeunload", function() {
			noBackHistoryFound = false;
		});
		window.history.back();
		setTimeout(function() {
			if (noBackHistoryFound) {
				window.close();
			}
		}, 100);
	};
	jQuery('.helpBackButton').click(goBackOrClose);
});
